import { Row, Col, Card, Nav, Button, Spinner } from 'react-bootstrap';
import React, { Fragment, useState, useEffect } from 'react';
import ProductCard from '@components/product-card/ProductCard';
import { MYNFTLIST } from '@utilities/Constants';
import Modal from 'react-modal';
import { CgCloseO } from 'react-icons/cg';
import PopUp from '@components/popup/PopUp';
import PopUpPrice from '@components/popup/PopUpPrice';
const sdk = require('dragonchain-sdk');
//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');
const Owner = () => {
  const [list, setlist] = useState([]);
  const [loader, setLoader] = useState(false);

  const [modal, setmodal] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [editType, seteditType] = useState('');

  function openModal() {
    setmodalIsOpen(true);
  }
  function closeModal() {
    setmodalIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '52%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: '1px solid rgb(204 204 204 / 22%)',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 0px 20px 0px #dee2e669',
      borderRadius: '30px',
    },
  };

  useEffect(async () => {
    setLoader(true);
    await getPortfolio();
    setLoader(false);
  }, []);

  const getPortfolio = async () => {
    console.log('called func');
    setLoader(true);
    const token = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    await axios
      .get(
        `${URL.API}/nft/mynft/${
          user && user.uuid
        }?nfttype=owner&skip=0&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        // localStorage.setItem('my_nft', JSON.stringify(response.data.finalList));
        setlist(response.data.finalList);
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoader(false);
  };
  return (
    <Fragment>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div onClick={closeModal} style={{ textAlign: 'right' }}>
          <CgCloseO size={'20px'} className={'absolutecross'} />
        </div>
        <PopUpPrice
          editType={editType}
          onclose={() => {
            setmodalIsOpen(false);
          }}
          getPortfolio={getPortfolio}
        />
      </Modal>
      <Col style={{ textAlign: 'center' }}>
        {loader && <Spinner animation='border' variant='primary' />}
      </Col>
      <Row>
        {list.map((item, index) => (
          <Col lg={4} sm={6}>
            <div>
              <ProductCard
                key={index}
                productInfo={item}
                myitems={true}
                myprofile={true}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default Owner;
