import { call, put } from "redux-saga/effects";
import {
  addProductSuccess,
  addProductTrigger,
  addProductFail,
  getProductTrigger,
  getProductSuccess,
  getProductFail,
  getCategorySuccess,
  getmyproductSuccess,
  deleteproductTrigger,
  deleteproductSucess,
  deleteProductFail,
  editProductSuccess,
  addtocartTrigger,
  addtocartSuccess,
  addtocartFail,
  getcartproductSuccess,
  removecartproductSucess,
  addAddressFail,
  addAddressTrigger,
  addAddressSuccess,
  getAddressSuccess,
  editAddressSuccess,
  orderRequestTrigger,
  orderRequestSuccess,
  orderRequestFail,
  updatecartTrigger,
  myorderSuccess,
  myorderRequestSuccess,
} from "./productredux";
import Api from "./../services/ApiCaller";
import { getAccessToken } from "./utilities";

export function* addProducts(api, { payload = {} }) {
  yield put(addProductTrigger(true));
  try {
    const {
      amount,
      quantity,
      title,
      imageserver,
      navigation,
      category,
      description,
      type,
      discount,
    } = payload;
    let featuredId = 0;
    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.addProduct,
      {
        amount,
        quantity,
        title,
        imageserver,
        category,
        description,
        type,
        token,
        featuredId,
        discount,
      },
      true
    );
    yield put(addProductSuccess(result));
  
    navigation.push("/marketplace");
  } catch (e) {
   

    yield put(addProductFail(e));
  } finally {
    yield put(addProductTrigger(false));
  }
}

export function* getProducts(api, { payload = {} }) {
  yield put(getProductTrigger(true));
  try {
  const {search, filtercategory} = payload || {}

    const token = yield getAccessToken();
    if(search.length!=0 && filtercategory.length==0){
      const result = yield call(
        Api.callServer,
        api.searchproductservices,
        { token,search },
        true
      );
      yield put(getProductSuccess(result));
     
    }if(search.length==0 && filtercategory.length!=0){
      const result = yield call(
        Api.callServer,
        api.filterbycategory,
        { token,filtercategory },
        true
      );
      yield put(getProductSuccess(result));
   
    }if(search.length!=0 && filtercategory.length!=0){
      const result = yield call(
        Api.callServer,
        api.filterandsearch,
        { token,search,filtercategory },
        true
      );
      yield put(getProductSuccess(result));
      
    }if(search.length==0 && filtercategory.length==0){
    const result = yield call(
      Api.callServer,
      api.getproductservices,
      { token },
      true
    );
    yield put(getProductSuccess(result));
   
  }
   
   
  } catch (e) {
  

    yield put(getProductFail(e));
  } finally {
    yield put(getProductTrigger(false));
  }
}

export function* getCategories(api, { payload = {} }) {
  try {
   
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getCategories,
      { token },
      true
    );
    yield put(getCategorySuccess(result));
   
  } catch (e) {

  } finally {
  }
}

export function* getMyProducts(api, { payload = {} }) {
  yield put(getProductTrigger(true));
  try {

    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getmyproductservices,
      { token },
      true
    );
    yield put(getmyproductSuccess(result));
 
  } catch (e) {


    yield put(getProductFail(e));
  } finally {
    yield put(getProductTrigger(false));
  }
}

export function* deleteProduct(api, { payload = {} }) {
  yield put(deleteproductTrigger(true));
  try {
    const { navigation, id } = payload;

   
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.deleteProduct,
      {
        token,
        id,
      },
      true
    );
 
    yield put(deleteproductSucess(id));
  } catch (e) {
  
    //yield put(deleteProductFail(e));
  } finally {
    yield put(deleteproductTrigger(false));
  }
}

export function* editProductServices(api, { payload = {} }) {
  yield put(addProductTrigger(true));
  try {
    const {
      amount,
      quantity,
      title,
      banner,
      navigation,
      category,
      description,
      type,
      deleteids,
      id,
      discount,
    } = payload;
    let featuredId = 0;
    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.editProductServices,
      {
        amount,
        quantity,
        title,
        description,
        token,
        banner,
        id,
        deleteids,
        category,
        type,
        featuredId,
        discount,
      },
      true
    );
    yield put(editProductSuccess(result));
    const result2 = yield call(
      Api.callServer,
      api.getmyproductservices,
      { token },
      true
    );
    yield put(getmyproductSuccess(result2));
    navigation.push("/marketplace");
  } catch (e) {
    
    yield put(addProductFail(e));
  } finally {
    yield put(addProductTrigger(false));
  }
}

export function* addtocart(api, { payload = {} }) {
  yield put(orderRequestFail(""));
  yield put(addtocartTrigger(true));
  try {
    const { id, quantity } = payload;

    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.addtocart,
      {
        quantity,
        id,
        token,
      },
      true
    );
    yield put(addtocartSuccess(result));

    const result2 = yield call(
      Api.callServer,
      api.getcartproduct,
      { token },
      true
    );
    yield put(getcartproductSuccess(result2));

  
  } catch (e) {
  

    yield put(addtocartFail(e));
  } finally {
    yield put(addtocartTrigger(false));
  }
}

export function* getCartProducts(api, { payload = {} }) {
  yield put(addtocartTrigger(true));
  try {

    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getcartproduct,
      { token },
      true
    );
    yield put(getcartproductSuccess(result));

  } catch (e) {


    yield put(addtocartFail(e));
  } finally {
    yield put(addtocartTrigger(false));
  }
}

export function* updateCartProducts(api, { payload = {} }) {
  yield put(updatecartTrigger(true));
  yield put(orderRequestFail(""));
  try {
    let { id, quantity } = payload;
 
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.updatecart,
      { token, id, quantity },
      true
    );

    const result2 = yield call(
      Api.callServer,
      api.getcartproduct,
      { token },
      true
    );
    yield put(getcartproductSuccess(result2));
  } catch (e) {


    yield put(addtocartFail(e));
  } finally {
    yield put(updatecartTrigger(false));
  }
}

export function* removecartProduct(api, { payload = {} }) {
  yield put(deleteproductTrigger(true));
  try {
    const { Id } = payload;

    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.removecartproduct,
      {
        token,
        Id,
      },
      true
    );

    yield put(removecartproductSucess(Id));
  } catch (e) {
 

    //yield put(deleteProductFail(e));
  } finally {
    yield put(deleteproductTrigger(false));
  }
}

export function* addAddress(api, { payload = {} }) {
  yield put(addAddressTrigger(true));
  try {
    const { name, email, pincode, city, state, address, navigation } = payload;

    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.addaddress,
      {
        name,
        email,
        pincode,
        city,
        state,
        address,
        token,
      },
      true
    );
    yield put(addAddressSuccess(result));
    const result2 = yield call(Api.callServer, api.getAddress, { token }, true);
    yield put(getAddressSuccess(result2));
  } catch (e) {
  

    yield put(addAddressFail(e));
  } finally {
    yield put(addAddressTrigger(false));
  }
}

export function* getAddress(api, { payload = {} }) {
  yield put(addAddressTrigger(true));
  try {
 
    const token = yield getAccessToken();
    const result = yield call(Api.callServer, api.getAddress, { token }, true);
    yield put(getAddressSuccess(result));

  } catch (e) {


    yield put(addAddressFail(e));
  } finally {
    yield put(addAddressTrigger(false));
  }
}

export function* editAddress(api, { payload = {} }) {
  yield put(addAddressTrigger(true));
  try {
    const {
      name,
      email,
      pincode,
      city,
      state,
      address,
      navigation,
      Id,
    } = payload;

    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.editAddress,
      {
        name,
        email,
        pincode,
        city,
        state,
        address,
        Id,
        token,
      },
      true
    );
    yield put(editAddressSuccess(result));
    const result2 = yield call(Api.callServer, api.getAddress, { token }, true);
    yield put(getAddressSuccess(result2));
  } catch (e) {


    yield put(addAddressFail(e));
  } finally {
    yield put(addAddressTrigger(false));
  }
}

export function* orderRequest(api, { payload = {} }) {
  yield put(orderRequestTrigger(true));
  try {
    const { shippingaddressId, paymentType, orderitems, navigation } = payload;

    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.createorder,
      {
        shippingaddressId,
        paymentType,
        orderitems,
        token,
      },
      true
    );
    yield put(orderRequestSuccess(result));
    navigation.push("/success");

  } catch (e) {


    yield put(orderRequestFail(e.message));
  } finally {
    yield put(orderRequestTrigger(false));
  }
}

export function* getMyOrder(api, { payload = {} }) {
  yield put(orderRequestTrigger(true));
  try {

    const token = yield getAccessToken();
    const result = yield call(Api.callServer, api.myorder, { token }, true);
    yield put(myorderSuccess(result));

  } catch (e) {


    yield put(orderRequestFail(e));
  } finally {
    yield put(orderRequestTrigger(false));
  }
}

export function* getMyOrderRequest(api, { payload = {} }) {
  yield put(orderRequestTrigger(true));
  try {

    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.myOrderRequest,
      { token },
      true
    );
    yield put(myorderRequestSuccess(result));

  } catch (e) {


    yield put(orderRequestFail(e));
  } finally {
    yield put(orderRequestTrigger(false));
  }
}
