import { createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash";

const productslice = createSlice({
  name: "product",
  initialState: {
    productInfo: null,
    addProductLoading: false,
    addProductError: "",
    productList: [],
    productLoading: false,
    getproductError: "",
    getallcategories: [],
    myproductList: [],
    deleteproductloading: false,
    deleteproductError: "",
    addtocartloading: false,
    addtocarterror: "",
    cart: [],
    cartInfo: null,
    addressInfo: null,
    addressLoading: false,
    addressError: "",
    addressList: [],
    orderRequestList: [],
    oderRequestLoading: false,
    orderRequestError: "",
    updatecartloading:false,
    myorderlist:[],
    orderequestlist:[],
  },
  reducers: {
    addProductSuccess: (state, action) => {
      return {
        ...state,
        productInfo: action.payload,
      };
    },

    addProductTrigger: (state, action) => {
      console.log(action.payload,'addProductLoading')
      return {
        ...state,
        addProductLoading: action.payload,
      };
    },
    addProductFail: (state, action) => {
      return {
        ...state,
        addProductError: action.payload,
      };
    },
    getProductSuccess: (state, action) => {
      return {
        ...state,
        productList: action.payload,
      };
    },
    getProductTrigger: (state, action) => {
      return {
        ...state,
        productLoading: action.payload,
      };
    },
    getProductFail: (state, action) => {
      return {
        ...state,
        getproductError: action.payload,
      };
    },
    getCategorySuccess: (state, action) => {
      return {
        ...state,
        getallcategories: action.payload,
      };
    },
    getmyproductSuccess: (state, action) => {
      return {
        ...state,
        myproductList: action.payload,
      };
    },
    deleteproductTrigger: (state, action) => {
      return {
        ...state,
        deleteproductloading: action.payload,
      };
    },
    deleteproductSucess: (state, action) => {
      let newids = _.reject(state.myproductList, { id: action.payload });
      return {
        ...state,
        myproductList: newids,
      };
    },
    deleteProductFail: (state, action) => {
      return {
        ...state,
        deleteproductError: action.payload,
      };
    },

    editProductSuccess: (state, action) => {
      return {
        ...state,
        productInfo: { ...state.myproductList, ...action.payload },
      };
    },
    addtocartTrigger: (state, action) => {
      return {
        ...state,
        addtocartloading: action.payload,
      };
    },
    updatecartTrigger: (state, action) => {
      return {
        ...state,
        updatecartloading: action.payload,
      };
    },
    addtocartSuccess: (state, action) => {
      return {
        ...state,
        cart: action.payload,
      };
    },
    addtocartFail: (state, action) => {
      return {
        ...state,
        addtocarterror: action.payload,
      };
    },
    getcartproductSuccess: (state, action) => {
      return {
        ...state,
        cartInfo: action.payload,
      };
    },

    removecartproductSucess: (state, action) => {
      let newids = _.reject(state.cartInfo, { id: action.payload });
      return {
        ...state,
        cartInfo: newids,
      };
    },
    addAddressTrigger: (state, action) => {
      return {
        ...state,
        addressLoading: action.payload,
      };
    },

    addAddressSuccess: (state, action) => {
      return {
        ...state,
        addressList: action.payload,
      };
    },

    addAddressFail: (state, action) => {
      return {
        ...state,
        addressError: action.payload,
      };
    },

    getAddressSuccess: (state, action) => {
      return {
        ...state,
        addressInfo: action.payload,
      };
    },

    editAddressSuccess: (state, action) => {
      console.log();
      return {
        ...state,
        addressList: { ...state.addressInfo, ...action.payload },
      };
    },
    orderRequestTrigger: (state, action) => {
      return {
        ...state,
        oderRequestLoading: action.payload,
      };
    },
    orderRequestSuccess: (state, action) => {
      return {
        ...state,
        orderRequestList: action.payload,
      };
    },

    orderRequestFail: (state, action) => {
      return {
        ...state,
        orderRequestError: action.payload,
      };
    },

    myorderSuccess: (state, action) => {
      return {
        ...state,
        myorderlist: action.payload,
      };
    },
    myorderRequestSuccess: (state, action) => {
      return {
        ...state,
        orderequestlist: action.payload,
      };
    },
  },
});
export default productslice.reducer;

export const {
  addProductSuccess,
  addProductTrigger,
  addProductFail,
  getProductSuccess,
  getProductTrigger,
  getProductFail,
  getCategorySuccess,
  getmyproductSuccess,
  deleteproductTrigger,
  deleteproductSucess,
  deleteProductFail,
  editProductSuccess,
  addtocartTrigger,
  addtocartSuccess,
  addtocartFail,
  getcartproductSuccess,
  removecartproductSucess,
  addAddressTrigger,
  addAddressSuccess,
  addAddressFail,
  getAddressSuccess,
  editAddressSuccess,
  orderRequestTrigger,
  orderRequestSuccess,
  orderRequestFail,
  updatecartTrigger,
  myorderSuccess,
  myorderRequestSuccess,
} = productslice.actions;
