import CustomButton from '@components/custom-button/CustomButton';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputField from '@components/input-field/InputField';
import cryptoRandomString from 'crypto-random-string';
import '@features/create-post/CreatePostStyles.css';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import '@themes/CommonApplicationStyles.css';
import { Row, Col, Spinner, Form } from 'react-bootstrap';
import NavBar from '@navigation/NavBar';
import { connect } from 'react-redux';
import pdficon from '@images/file.png';
import * as _ from 'lodash';

//
import { CgCloseO } from 'react-icons/cg';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

const sdk = require('dragonchain-sdk');

export default function Createnft({}) {
  const history = useHistory();
  const [validated, setValidate] = useState(false);
  const [activate, setactivate] = useState(false);
  const [nftname, setNftname] = useState('');
  const [note, setNote] = useState('');
  const [type, setType] = useState('');
  const [errortxt, seterrortxt] = useState('');
  const [desc, setDesc] = useState('');
  const [price, setPrice] = useState('');
  const [fileName, setFileName] = useState([]);
  const [files, setfiles] = useState([]);
  const [imagePreview, setimagePreview] = useState([]);
  const [discountPerc, setDiscount] = useState('');

  const [loader, setLoader] = useState(false);
  const [method, setmethod] = useState('Createnft');

  const onSubmit = async (event) => {
    seterrortxt('');
    setactivate(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      const token = localStorage.getItem('accessToken');
      const formData = new FormData();
      for (let i = 0; i < fileName.length; i++) {
        formData.append('images', fileName[i]);
      }
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
      }
      console.log('note:', note);
      formData.append('name', nftname);
      formData.append('description', desc);
      formData.append('type', type);
      formData.append('note', note);
      formData.append('amount', price);
      await axios
        .post(`${URL.API}/nfts/createnft`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          history.push({ pathname: '/portfolio' });
        })
        .catch((error) => {
          console.log(error);
          seterrortxt(
            error &&
              error.response &&
              error.response.data &&
              error.response.data.messagee
          );
        });
      setLoader(false);
    }
  };

  //
  const ImageRemove = (id) => {
    let newbanner = [];
    let newPreview = [];
    if (fileName.length <= 5) {
      fileName.map((item, index) => {
        if (index != id) {
          newbanner = [...newbanner, item];
        }
      });
      setFileName(newbanner);
    }
    if (fileName.length <= 5) {
      imagePreview.map((item, index) => {
        if (index != id) {
          newPreview = [...newPreview, item];
        }
      });

      setimagePreview(newPreview);
      setFileName(newbanner);
    }
  };
  const ImageRemove2 = (id) => {
    let newbanner = [];
    let newPreview = [];
    if (files.length <= 2) {
      files.map((item, index) => {
        if (index != id) {
          newbanner = [...newbanner, item];
        }
      });
      setfiles(newbanner);
    }
  };
  const onSelectFile = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        console.log('Reader:', reader.result);
        return reader.result;
      }
    };
    reader.readAsDataURL(file);
  };

  console.log('image', fileName);
  console.log('pdf', files);
  return (
    <div id='createpost'>
      <NavBar location={useLocation()} />
      <Container style={{ marginTop: '50px' }}>
        <Row className='justify-content-md-center'>
          <Col lg={8}>
            <h3 className='mb-4 text-center'>Create NFT</h3>
            <Form noValidate validated={validated} onSubmit={onSubmit}>
              <InputField
                type='text'
                value={nftname}
                placeholder='Enter NFT Name'
                label='NFT Name'
                onChange={(e) => setNftname(e.target.value)}
                required
                error='Please Enter NFT Name'
              />
              <label>Note</label>
              <textarea
                style={{
                  width: '100%',
                  height: '100px',
                  fontSize: '12px',
                  fontWeight: '600px',
                  borderWidth: '0px 0px 2px 0px',
                  borderColor: '#f1f1f1',
                  color: '#999999',
                  outline: 'none',
                  textIndent: '8px',
                }}
                type='text'
                value={note}
                placeholder='Enter Note'
                onChange={(e) => setNote(e.target.value)}
                required
                error='Please Enter Note'
              />
              {/* <InputField
                type='text'
                value={note}
                placeholder='Enter Note'
                label='Note'
                onChange={(e) => setNote(e.target.value)}
                required
                error='Plaese Enter Note'
              /> */}
              <label>Type</label>
              <br></br>
              <div>
                <select
                  style={{
                    width: '100%',
                    height: '49px',
                    fontSize: '12px',
                    fontWeight: '600px',
                    borderWidth: '0px 0px 2px 0px',
                    borderColor: '#f1f1f1',
                    color: '#999999',
                    outline: 'none',
                    textIndent: '8px',
                  }}
                  type='select'
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  required
                  error='Please Select Type'
                >
                  <option
                    style={{
                      marginLeft: '7px',
                      fontSize: '12px',
                      fontWeight: '600px',
                      color: '#999999',
                    }}
                  >
                    Select Type
                  </option>
                  <option
                    style={{
                      marginLeft: '7px',
                      fontSize: '12px',
                      fontWeight: '600px',
                      color: '#999999',
                    }}
                  >
                    Art
                  </option>
                  <option
                    style={{
                      marginLeft: '7px',
                      fontSize: '12px',
                      fontWeight: '600px',
                      color: '#999999',
                    }}
                  >
                    Photography
                  </option>
                  <option
                    style={{
                      marginLeft: '7px',
                      fontSize: '12px',
                      fontWeight: '600px',
                      color: '#999999',
                    }}
                  >
                    Portrait
                  </option>
                  <option
                    style={{
                      marginLeft: '7px',
                      fontSize: '12px',
                      fontWeight: '600px',
                      color: '#999999',
                    }}
                  >
                    Music
                  </option>
                </select>
              </div>
              <br></br>
              <label>Description</label>
              <textarea
                style={{
                  width: '100%',
                  height: '100px',
                  fontSize: '12px',
                  fontWeight: '600px',
                  borderWidth: '0px 0px 2px 0px',
                  borderColor: '#f1f1f1',
                  color: '#999999',
                  outline: 'none',
                  textIndent: '8px',
                }}
                type='text'
                value={desc}
                placeholder='Enter Description'
                onChange={(e) => setDesc(e.target.value)}
                required
                error='Please Enter Description'
              />
              <InputField
                type='number'
                value={price}
                placeholder='Enter Price'
                label='Price'
                onChange={(e) => setPrice(e.target.value)}
                required
                error='Please Enter Price'
              />
              {/* <InputField
                type="number"
                value={discountPerc}
                placeholder="Enter Discount in %"
                label="Discount"
                onChange={(e) => setDiscount(e.target.value)}
                required
                error="Please Enter Discount "
              /> */}
              <Form.Group>
                <Form.File
                  id='ControlFile1'
                  label='Upload Images'
                  onChange={(e) => {
                    setFileName([...fileName, e.target.files[0]]);
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.onloadend = () => {
                      setimagePreview([...imagePreview, reader.result]);
                    };
                    reader.readAsDataURL(file);
                  }}
                  disabled={fileName.length < 5 ? false : true}

                  // required
                  // error="Please Upload Certificate File"
                />
                <Form.Control.Feedback type='invalid'>
                  Please choose an Image
                </Form.Control.Feedback>
                {/* <img
                  style={{ marginTop: 20 }}
                  height={100}
                  width={100}
                  src={fileName}
                /> */}
              </Form.Group>
              {activate && fileName.length == 0 && (
                <Form.Control.Feedback
                  type='invalid'
                  style={{ color: 'red', fontSize: 16 }}
                  className='my-3'
                >
                  Please choose an Image
                </Form.Control.Feedback>
              )}
              <Col style={{ textAlign: 'center' }}>
                {loader && <Spinner animation='border' variant='primary' />}
              </Col>{' '}
              {errortxt != '' && (
                <h5
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  {errortxt}
                </h5>
              )}
              <Col>
                <div className='d-flex' style={{ width: 300 }}>
                  {imagePreview &&
                    imagePreview.map((im, id) => {
                      return (
                        <div className='attachimage'>
                          <img
                            src={im}
                            alt='image'
                            style={{ marginTop: 20 }}
                            height={50}
                            width={50}
                            className='attachment'
                          />
                          <CgCloseO
                            size={'20px'}
                            onClick={() => {
                              ImageRemove(id);
                            }}
                            // style={{ position: 'absolute', top: 20 }}
                            className='imageabsolute'
                          />
                        </div>
                      );
                    })}
                </div>
              </Col>
              <Form.Group>
                <Form.File
                  id='ControlFile1'
                  label='Upload PDFs'
                  onChange={(e) => {
                    setfiles([...files, e.target.files[0]]);
                  }}
                  disabled={files.length < 2 ? false : true}
                />
              </Form.Group>
              <Col>
                <div className='d-flex' style={{ width: 300 }}>
                  {files &&
                    files.map((im, id) => {
                      console.log('file', im.name);
                      return (
                        <div className='attachimage'>
                          <img
                            src={pdficon}
                            alt='image'
                            style={{ marginTop: 20 }}
                            height={50}
                            width={50}
                            className='attachment'
                          />
                          <CgCloseO
                            size={'20px'}
                            onClick={() => {
                              ImageRemove2(id);
                            }}
                            // style={{ position: 'absolute', top: 20 }}
                            className='imageabsolute'
                          />
                          <h6>{im && im.name}</h6>
                        </div>
                      );
                    })}
                </div>
              </Col>
              <Row className='justify-content-md-center'>
                <Col lg={6} className='mt-2 '>
                  <CustomButton
                    type='Post'
                    filled={true}
                    primary={true}
                    // disabled={loader || fileName.length == 0}
                    disable={loader || fileName.length == 0}
                    name='Create NFT'
                    // onClick={() => {
                    //   createnft();
                    // }}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// const mapStateToProps = ({ tracker = {}, post = {} }) => {
//   const createpostLoading = _.get(post, 'createpostLoading', false);
//   const editpostloading = _.get(post, 'editpostloading', false);
//   return { createpostLoading, editpostloading };
// };

// const mapDispatchToProps = (dispatch) => ({
//   createPost: (data) => dispatch({ type: 'CreatePostCalled', payload: data }),
//   editPost: (data) => dispatch({ type: 'EditPostCalled', payload: data }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Createnft);
