 import {createSlice} from '@reduxjs/toolkit';
 import {FORM_KEYS, SCREEN_KEYS} from '@utilities/Constants';

const userslice = createSlice({
  name: 'tracker',
  initialState: {
   startupscreen: SCREEN_KEYS.SIGN_IN,
   formkey: FORM_KEYS.SIGN_UP
  },
  reducers: {
    changeScreenSuccess: (state, action) => {
      return{
        startupscreen : action.payload
      }
    },

    changeFormSuccess: (state, action) => {
      return{
        formkey: action.payload
      }
    }
  },
});
export default userslice.reducer;
 
 export const { changeScreenSuccess, changeFormSuccess } = userslice.actions;
 
