import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, Nav, Button, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import Tab from '@components/tab/Tab.js';
import Loader from '@components/loader/Loader';
import ProductCard from '@components/product-card/ProductCard';
import NavBar from '@navigation/NavBar';
import '@themes/CommonApplicationStyles.css';
import '@features/explore/ExploreStyles.css';
import Allnft from '@components/nfttypes/Allnft';
import Photography from '@components/nfttypes/Photography';

import Art from '@components/nfttypes/Art';

import Portrait from '@components/nfttypes/Portrait';
import Music from '@components/nfttypes/Music';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

const sdk = require('dragonchain-sdk');
function Explore({}) {
  const [key, setkey] = useState('1');
  const [list, setlist] = useState([]);
  const [recent, setRecent] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(async () => {
    setLoader(true);
    const token = localStorage.getItem('accessToken');
    await axios
      .get(`${URL.API}/nft/recent?skip=0&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        localStorage.setItem('recent_nft', response.data.finalList);
        setRecent(response.data.finalList);
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoader(false);
    await axios
      .get(`${URL.API}/user/myprofile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem(
          'my-profile-image',
          JSON.stringify(response.data.profileImage)
        );
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoader(false);
  }, []);
  return (
    <div>
      <NavBar location={useLocation()} />

      <Container style={{ marginTop: '20px' }}>
        <div className='content'>
          <div className=' row'>
            <h3>Recently added</h3>
          </div>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              {loader && <Spinner animation='border' variant='primary' />}
            </Col>
          </Row>
          <Row>
            {recent
              .filter((rec, i) => {
                if (i < 3) {
                  return rec;
                }
              })
              .map((item, index) => (
                <Col lg={4} sm={6}>
                  <ProductCard
                    key={index}
                    productInfo={item}
                    myprofile={false}
                  />
                </Col>
              ))}
          </Row>
          <div className='mt-4 mb-3 row'>
            <h3 className='mr-2'>Explore</h3>
            <Tab
              page='explorer'
              onSelect={(res) => setkey(res)}
              history={history}
            />
          </div>
        </div>

        {key == 1 && <Allnft list={recent} />}
        {key == 2 && <Art list={recent} />}
        {key == 3 && <Photography list={recent} />}
        {key == 4 && <Portrait list={recent} />}
        {key == 5 && <Music list={recent} />}
      </Container>
    </div>
  );
}

export default Explore;

// var nftDetails = nftResult.response.results.reduce(
//   (latest, current) => {
//     return latest > current ? latest : current;
//   },
//   nftResult.response.results[0].payload.timestamp
// );
