import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, Nav, Button } from 'react-bootstrap';
import '@themes/CommonApplicationStyles.css';
import '@features/nftdetail/NftdetailStyles.css';
import '@components/detailstab/DetailstabStyles.css';

import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import DPround from '@components/dpround/DPround';

function Owner({ historydetails, nft, creator, owner }) {
  const history = useHistory();
  const [key, setkey] = useState('1');
  const { productInfo } =
    (history && history.location && history.location.state) || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container>
        {owner &&
          owner.map((b) => {
            let profilePic;
            b &&
              b.owner &&
              b.owner.profileImage &&
              b.owner.profileImage.profileimage &&
              b.owner.profileImage.profileimage.map((pic) => {
                if (pic.size == 'thumbnail') {
                  profilePic = pic.link;
                }
              });
            return (
              <div className='content'>
                <Row
                  lg={12}
                  style={{
                    marginLeft: '20px',
                    marginBottom: '20px',
                    marginTop: '10px',
                  }}
                >
                  <DPround profileImage={profilePic} page='small' />

                  <Col>
                    {/* <div className="destitle">Not for Sale</div> */}
                    <div
                      // className='description'
                      className='linkhover'
                      onClick={() => {
                        localStorage.setItem(
                          'other-user-id',
                          b && b.owner && b.owner.uuid
                        );
                        localStorage.setItem(
                          'other-user-name',
                          b && b.owner && b.owner.userName
                        );
                        history.push('/othersprofile');
                      }}
                    >
                      <span>
                        {b && b.owner && b.owner.uuid} (
                        {b && b.owner && b.owner.userName})
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        {/* <div className='content'>
          <Row
            lg={12}
            style={{
              marginLeft: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            <Dpcreator />

            <Col>
              <div className='description'>
                <span>{nft.ownerid} ({owner})</span>
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>
    </div>
  );
}
export default Owner;
