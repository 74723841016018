import { SALENFTLIST } from '@utilities/Constants';
import { Row, Col, Card, Nav, Button, Spinner } from 'react-bootstrap';
import React, { Fragment, useState, useEffect } from 'react';
import ProductCard from '@components/product-card/ProductCard';
import { result } from 'lodash';
const sdk = require('dragonchain-sdk');
//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');
const OnSale = () => {
  const [list, setlist] = useState([]);
  const [loader, setLoader] = useState(false);

  const [modal, setmodal] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [editType, seteditType] = useState('');

  function openModal() {
    setmodalIsOpen(true);
  }
  function closeModal() {
    setmodalIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '52%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: '1px solid rgb(204 204 204 / 22%)',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 0px 20px 0px #dee2e669',
      borderRadius: '30px',
    },
  };
  useEffect(async () => {
    setLoader(true);
    const token = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    await axios
      .get(
        `${URL.API}/nft/mynft/${
          user && user.uuid
        }?nfttype=onsale&skip=0&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setlist(response.data.finalList);
      })
      .catch(function (error) {
        console.log(error.response);
      });
    setLoader(false);
  }, []);
  return (
    <Fragment>
      <Col style={{ textAlign: 'center' }}>
        {loader && <Spinner animation='border' variant='primary' />}
      </Col>
      <Row>
        {list.map((item, index) => (
          <Col lg={4} sm={6}>
            <ProductCard key={index} productInfo={item} myprofile />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default OnSale;
