import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';

const postslice = createSlice({
  name: 'post',
  initialState: {
    mypostInfo:[],
    mypostLoading: false,
    mypostError: '',
    createPostInfo:null,
    createpostLoading:false,
    createpostError:'',
    editpostloading:false,
    editpostfail:'',
    singlepostinfo:null,
    singleposterror:'',
    singlepostloading:false,
    singleProfilePostsInfo: null,
    reportpostloading:false,
    draftPostInfo: null,
    mydraftpostInfo:[],
    draftpostLoading: false,
    poststatus:'initial',
    editpoststatus:'initial',
    commentInfo:[],
    commentloading:false,
    commenterror:'',
    commentlist:[],
    replyInfo:[],
    replyLoading:false,
    replyerror:'',
    replylist:[],
    commentcount:'',
    replycount:'',
    deletecommentLoading:false,
    deletecommenterror:'',
    editcommentLoading:false,
    editcommenterror:'',
    newindex2:'',
    sharePostLoading:false,
    sharePostError:'',
    sharePostInfo:[],
    singlepost:[],
    singlepostbyidLoading:false,
    singlepostbyidError:'',
  },
  reducers: {
    mypostSuccess: (state, action) => {
        return{
          ...state,
          mypostInfo: action.payload
        }
      },
      mypostTrigger: (state,action) => {
      return{
        ...state,
        mypostLoading: action.payload
      }
      },
      myPostFail: (state,action) => {
        return{
          ...state,
          mypostError: action.payload
        }
      },
      createPostSuccess: (state, action) => {
        return{
          ...state,
          createPostInfo: action.payload,
          poststatus:'successful'
        }
      },
      createPostTrigger: (state, action) => {
        return{
          ...state,
          createpostLoading: action.payload
        }
      },
      createPostFail: (state, action) => {
        return{
          ...state,
          createpostError: action.payload
        }
      }, 

      editPostSuccess: (state, action) => {
        return{
          ...state,
          createPostInfo: {...state.createPostInfo, ...action.payload},
          editpoststatus:'successful',
        }
      },
      uploadStatusSuccess:(state, action)=>{
return{
  ...state,
  poststatus:action.payload
}
      },
      edituploadStatusSuccess:(state, action)=>{
        return{
          ...state,
          editpoststatus:action.payload
        }
              },
      
      editPostTrigger: (state, action) => {
        return{
          ...state,
          editpostloading: action.payload
        }
      },
      editPostFail: (state, action) => {
        return{
          ...state,
          editpostfail: action.payload
        }
      },
      SinglepostSuccess: (state, action) => {
        return{
          ...state,
          singlepostinfo: action.payload
        }
      },
      SinglepostFail: (state, action) => {
        return{
          ...state,
          singleposterror: action.payload
        }
      },
      SinglepostTrigger:(state, action) => {
        return{
          ...state,
          singlepostloading: action.payload
        }
      },
      singleProfilePostsSuccess: (state, action) => {
        return{
          ...state,
          singleProfilePostsInfo: action.payload
        }
      },
      // otherUserPostsTrigger: (state, action) => {
      //   return{
      //     ...state,
      //     otherUserPostsLoading: action.payload
      //   }
      // },
      
      deletepostTrigger:(state, action) => {
        return{
          ...state,
          deletepostloading: action.payload
        }
      },
      deletepostSuccess: (state, action) => {
        return{
          ...state,
          createPostInfo: {...state.createPostInfo, ...action.payload}
        }
      },
      deletepostFail: (state, action) => {
        return{
          ...state,
          deleteposterror: action.payload
        }
      },

      reportpostTrigger: (state, action) => {
        return {
          ...state,
         reportpostloading: action.payload,
        };
      },
      postsLikesSuccess: (state, action) => {
        return {
          ...state,
          postsLikes: action.payload,
        };
      },
  
      likeProfilePostTrigger: (state, action) => {
        let updatedList=[]
        if(action.payload.type=="like"){
        updatedList = _.map(state.singleProfilePostsInfo, (item) => {
          return item.id === action.payload.id? { ...item, likeCount: item.likeCount +1 } : item
        })
      }else{
        updatedList = _.map(state.singleProfilePostsInfo, (item) => {
          return item.id === action.payload.id? { ...item, likeCount: item.likeCount -1 } : item
        })
      }
        return{
          ...state,
          singleProfilePostsInfo: updatedList
        }
      },

      mydraftpostTrigger: (state, action) => {
        return {
          ...state,
          draftpostLoading:  action.payload
        }
      },
      mydraftpostSuccess: (state, action) => {
        return {
          ...state,
          mydraftpostInfo: action.payload,
        };
      },
      createDraftSuccess: (state, action) => {
        return {
          ...state,
          draftPostInfo: action.payload,
        };
      },
      editDraftSuccess: (state, action) => {
      
        return {
          ...state,
          draftPostInfo: {...state.draftPostInfo, ...action.payload},
        };
      },
      deletepostdraftSuccess: (state, action) => {
        let newids = _.reject(state.mydraftpostInfo, {id: action.payload});
        return {
          ...state,
          mydraftpostInfo: newids,
        };
      },
  
      likeMyPostTrigger: (state, action) => {
  
        let updatedList=[]
        
        if(action.payload.type=="like"){
        updatedList = _.map(state.mypostInfo, (item) => {
          return item.id === action.payload.id? { ...item, likeCount: item.likeCount +1 } : item
        })
      }else{
        updatedList = _.map(state.mypostInfo, (item) => {
          return item.id === action.payload.id? { ...item, likeCount: item.likeCount -1 } : item
        })
      }
        return{
          ...state,
          mypostInfo: updatedList
        }
      },


      postcommentSuccess: (state, action) => {
        
        return{
          ...state,
          commentInfo: action.payload,
        
        }
      },
      postcommentTrigger: (state, action) => {
        return{
          ...state,
          commentloading: action.payload
        }
      },
      postcommentCollapseTrigger: (state, action) => {
        return{
          ...state,
          newindex2: action.payload
        }
      },
      postcommentFail: (state, action) => {
        return{
          ...state,
          commenterror: action.payload
        }
      }, 
      getcommentSuccess: (state, action) => {
        return{
          ...state,
          commentlist: action.payload,
        
        }
      },


      replyPostcommentSuccess: (state, action) => {
        return{
          ...state,
          replyInfo: action.payload,
        
        }
      },
      replyPostcommentTrigger: (state, action) => {
        return{
          ...state,
          replyLoading: action.payload
        }
      },
      replyPostcommentFail: (state, action) => {
        return{
          ...state,
          replyerror: action.payload
        }
      }, 

      getCommentReplySuccess: (state, action) => {
        return{
          ...state,
          replylist: action.payload,
        
        }
      },

      getCommentcount: (state, action) => {
        return{
          ...state,
          commentcount: action.payload,
        
        }
      },

      getreplycount: (state, action) => {
        return{
          ...state,
          replycount: action.payload,
        
        }
      },



      deletecommentTrigger:(state, action) => {
        return{
          ...state,
          deletecommentLoading: action.payload
        }
      },
      deletecommentSuccess: (state, action) => {
        return{
          ...state,
          commentInfo: {...state.commentInfo, ...action.payload}
        }
      },
      deletecommentFail: (state, action) => {
        return{
          ...state,
          deletecommenterror: action.payload
        }
      },

      editcommentSuccess: (state, action) => {
        return{
          ...state,
          commentInfo: {...state.commentInfo, ...action.payload}
        }
      },


      sharePostTrigger: (state, action) => {
        return{
          ...state,
          sharePostLoading: action.payload
        }
      },
      sharePostFail: (state, action) => {
        return{
          ...state,
          sharePostError: action.payload
        }
      }, 

      sharePostSuccess: (state, action) => {
        return{
          ...state,
          sharePostInfo: action.payload,
        
        }
      },

      singlePostbyidTrigger: (state, action) => {
        return{
          ...state,
          singlepostbyidLoading: action.payload
        }
      },
      singlePostbyidFail: (state, action) => {
        return{
          ...state,
          singlepostbyidError: action.payload
        }
      }, 

      singlePostbyidSuccess: (state, action) => {
        return{
          ...state,
          singlepost: action.payload,
        
        }
      },

  },
});
export default postslice.reducer;
 
export const {  
    mypostSuccess,
    mypostTrigger,
    myPostFail,
    createPostSuccess,
    createPostTrigger,
    createPostFail,
    editPostSuccess,
    editPostFail,
    editPostTrigger,
    SinglepostSuccess,
    SinglepostFail,
    SinglepostTrigger,
    singleProfilePostsSuccess,
    deletepostSuccess,
    deletepostFail,
    deletepostTrigger,
    reportpostTrigger,
    postsLikesSuccess,
    likeProfilePostTrigger,
    likeMyPostTrigger,
    createDraftSuccess,
  editDraftSuccess,
  mydraftpostSuccess ,
  mydraftPostFail,
  deletepostdraftSuccess,
  mydraftpostTrigger,
  uploadStatusSuccess,
  edituploadStatusSuccess,
  postcommentSuccess,
  postcommentTrigger,
  postcommentCollapseTrigger,
  postcommentFail,
  getcommentSuccess,
  replyPostcommentSuccess,
  replyPostcommentTrigger,
  replyPostcommentFail,
  getCommentReplySuccess,
  getCommentcount,
  getreplycount,
  deletecommentTrigger,
  deletecommentSuccess,
  deletecommentFail,
  editcommentSuccess,
  sharePostTrigger,
  sharePostFail,
  sharePostSuccess,
  singlePostbyidTrigger,
  singlePostbyidFail,
  singlePostbyidSuccess,
} = postslice.actions;