import React, { useState, useRef, useEffect } from 'react';
import '@themes/CommonApplicationStyles.css';
import { useLocation } from 'react-router-dom';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import InputField from '@components/input-field/InputField';
import CustomButton from '@components/custom-button/CustomButton';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import NavBar from '@navigation/NavBar';
import '@features/create-post/CreatePostStyles.css';
import StringCrypto from 'string-crypto';
import cryptoRandomString from 'crypto-random-string';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

const sdk = require('dragonchain-sdk');

export default function Signin({ location }) {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [loader, setLoader] = useState(false);
  const [errortxt, seterrortxt] = useState('');
  const [otp, setOtp] = useState('');
  const [method, setmethod] = useState('Signup');
  const { encryptString, decryptString } = new StringCrypto();
  const [validated, setValidate] = useState(false);
  const [tab, setTab] = useState(1);
  const history = useHistory();
  const { code } = location.state || {};

  const onSubmit = async (event) => {
    const form = event.currentTarget;
    seterrortxt('');
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      await axios
        .post(`${URL.API}/auth/set-new-passwordmob`, {
          mobile: code,
          newPassword: password,
        })
        .then(function (response) {
          history.push({ pathname: '/' });
          setTab(2);
        })
        .catch(async function (error) {
          console.log(error.response.data.statusCode);
          seterrortxt(
            error &&
              error.response &&
              error.response.data &&
              error.response.data.messagee
          );
        });

      setLoader(false);
    }
  };

  return (
    <div>
      {/* <NavBar location={useLocation()} /> */}
      <Container style={{ marginTop: '150px' }}>
        <Row className='justify-content-md-center'>
          <Col lg={4}>
            <h3 className='mb-4 text-center'>Enter New Password</h3>
            <Form noValidate validated={validated} onSubmit={onSubmit}>
              <InputField
                type='password'
                value={password}
                placeholder='Enter New Password'
                label='Password'
                required
                onChange={(e) => setPassword(e.target.value)}
                error='Please Enter Password'
              />
              {
                <Col style={{ textAlign: 'center' }}>
                  {loader && <Spinner animation='border' variant='primary' />}
                </Col>
              }{' '}
              {errortxt != '' && (
                <h5
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  {errortxt == 'mobilenotexist'
                    ? 'User does not exist'
                    : errortxt == 'verifyMobile'
                    ? 'Verify Mobile'
                    : errortxt}
                </h5>
              )}
              <Row className='justify-content-md-center'>
                <Col lg={6} className='mt-2'>
                  <CustomButton
                    type='Post'
                    filled={true}
                    primary={true}
                    disable={loader}
                    name='Submit'
                    // onClick={() => login()}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// const mapStateToProps = ({ tracker = {}, post = {} }) => {
//   const createpostLoading = _.get(post, "createpostLoading", false);
//   const editpostloading = _.get(post, "editpostloading", false);
//   return { createpostLoading, editpostloading };
// };

// const mapDispatchToProps = (dispatch) => ({
//   createPost: (data) => dispatch({ type: "CreatePostCalled", payload: data }),
//   editPost: (data) => dispatch({ type: "EditPostCalled", payload: data }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Signin);
