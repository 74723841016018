import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, Nav, Button, Spinner, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '@themes/CommonApplicationStyles.css';
import Tab from '@components/tab/Tab.js';
import '@features/nftdetail/NftdetailStyles.css';
import * as _ from 'lodash';
import NavBar from '@navigation/NavBar';
import { useHistory } from 'react-router-dom';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

function Nftdetail({ match }) {
  const [modal, setmodal] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [details, setDetails] = useState(null);
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [editType, seteditType] = useState('');
  const [logs, setLogs] = useState(null);

  function openModal1() {
    setmodalIsOpen(true);
  }
  function closeModal1() {
    setmodalIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '52%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: '1px solid rgb(204 204 204 / 22%)',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 0px 20px 0px #dee2e669',
      borderRadius: '30px',
    },
  };
  const history = useHistory();
  const [key, setkey] = useState('1');
  const nftid = (match && match.params && match.params.id) || {};
  const [loader, setLoader] = useState(false);
  useEffect(async () => {
    window.scrollTo(0, 0);
    setLoader(true);
    const token = localStorage.getItem('accessToken');
    await axios
      .get(`${URL.API}/nfts/log/${nftid}?skip=0&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        localStorage.setItem('nft_logs', JSON.stringify(response.data));
        setLogs(response.data.log);
      })
      .catch(function (error) {
        console.log(error && error.response);
      });
    setLoader(false);
  }, []);
  return (
    <div id='nftlogs'>
      <NavBar location={useLocation()} />
      <h3 className='my-5 text-center' style={{ marginBottom: '50px' }}>
        NFT Logs
      </h3>
      <Container>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Log Id</th>
              <th>Event</th>
              <th>NFT Id</th>
              <th colSpan='2'>By</th>
              {/* <th>More details</th>   */}
            </tr>
          </thead>

          <tbody>
            {logs &&
              logs.map((item, index) => {
                const uuid = item && item.payload && item.payload.uuid;
                return (
                  <tr>
                    <th>{index + 1}</th>
                    <td>
                      <a
                        style={{ zIndex: 200 }}
                        className='linkhover'
                        // href='/othersprofile'
                        onClick={() =>
                          history.push(`/nftrawdetails/${uuid}`, { uuid })
                        }
                      >
                        {' '}
                        {item && item.payload && item.payload.uuid}
                      </a>
                    </td>
                    <td>
                      {item && item.payload && item.payload.event}
                      {item && item.payload && item.payload.amount && (
                        <span>
                          {' '}
                          ( ${`${
                            item && item.payload && item.payload.amount
                          }`}{' '}
                          )
                        </span>
                      )}
                    </td>
                    <td>
                      {/* <a
                        style={{ zIndex: 200 }}
                        className='linkhover'
                        onClick={() => {
                          const nftId =
                            item && item.payload && item.payload.nftid;
                          history.push('/nftdetail', { nftid: nftId });
                        }}
                      > */}
                      {item && item.payload && item.payload.nftid}
                      {/* </a> */}
                    </td>
                    <td>
                      <a
                        style={{ zIndex: 200 }}
                        className='linkhover'
                        href='/othersprofile'
                        onClick={() =>
                          localStorage.setItem(
                            'other-user-id',
                            item && item.payload && item.payload.by
                          )
                        }
                      >
                        {item && item.payload && item.payload.by}
                      </a>
                    </td>
                    <td
                      style={{ paddingLeft: 22, cursor: 'pointer' }}
                      onClick={() =>
                        history.push(`/nftlogdetails/${uuid}`, { uuid })
                      }
                      className='linkonhover'
                    >
                      <a href=''>Check details</a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Col style={{ textAlign: 'center', marginTop: 30 }}>
          {loader && <Spinner animation='border' variant='primary' />}
        </Col>
      </Container>
    </div>
  );
}

export default Nftdetail;
