import React, { useState,useEffect } from "react";
// import {View, Text, StatusBar, Image, TextInput} from 'react-native';
//import styles from '@components/share-something/ShareSomethingStyles'
// import {Images, Colors} from '@themes'
import arrowDown from "@images/arrowDown.png";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

import  "@components/share-something/ShareSomethingStyles.css"
const ShareSomething = ({
  page,
  navigation,
  onPost,
  userInfo,
  type,
  length,
  maxlength,
}) => {
  const [description, setDescription] = useState('');
  const history = useHistory()
  const { Description, } = history && history.location && history.location.state || {};
  useEffect(() => {
    if(type&&type=='edit'){
      setDescription(Description);
    }
    }, []);
  return (
    <div className="sharesomethingcard" onClick={() => history.push(`createpost`)}>
        <div className="sharesomethingcontent">

         <div className="dpcircle">
          {userInfo && userInfo.profileImage && (
            <img
              src={userInfo.profileImage.original}
           
            />
          )}
        </div>
        <div className="postform">
        <div className="privacyselect" onClick={() => history.push(`createpost`)}>
            <span className="privacyselecttext" >Community Timeline</span>
            <img
              src={arrowDown}
              alt="img"
              className="arrow"
              style={{
                paddingeft: "10px",
                marginLeft: "9px",
                marginTop: "2px",
              }}
            />
           </div>
         
</div>
        {page == "CreatePost" ? (
        
         <Form className="shareform">
         <Form.Group>
           <Form.Control
             as="textarea"
             multiline={true}
        scrollEnabled={false}
             className="formcontrol textarea sharetextbox"
             placeholder="Share Something..."
             type='text'
             length={length}
             maxlength={maxlength}
            value={description}
             onChange={async(e) => {
               setDescription(e.target.value),
               await onPost(e.target.value);
             }}
           />
         </Form.Group>
         </Form>
       
           
          
        ) : (
            <span className="posttext text-muted" onClick={() => history.push(`createpost`)}>
              Share Something....
            </span>
        )}
        </div>
      </div>
     
  );
};

const mapStateToProps = ({ tracker = {}, user = {}, post = {} }) => {
  const userInfo = _.get(user, "userInfo", "");
  return { userInfo };
};



export default connect(mapStateToProps, null)(ShareSomething);
