import {createSlice} from '@reduxjs/toolkit';
import * as _ from 'lodash';

const userslice = createSlice({
  name: 'others',
  initialState: {
    recentProfiles: null,
    homeLoading: false,
    recentProfilesError: '',
    singleProfileInfo: null,
    singleProfileLoading: false,
    myFeedInfo: null,
    searchInfo: null,
    searchLoading: false
  },
  reducers: {
    recentProfileSuccess: (state, action) => {
      // console.log('Other Redux: ', action.payload)
      return{
        ...state, 
        recentProfiles: action.payload
      }
    },
    recentProfileTrigger: (state, action) => {
      return{
        ...state,
        homeLoading: action.payload
      }
    },
    otherProfileSuccess: (state, action) => {
      return{
        ...state,
        singleProfileInfo: action.payload
      }
    },
    singleProfileTrigger: (state, action) => {
      return{
        ...state,
        singleProfileLoading: action.payload
      }
    },
    myFeedSuccess: (state, action) => {
      return{
        ...state,
        myFeedInfo: action.payload
      }
    }, 
    myFeedTrigger: (state, action) => {
      return{
        ...state,
        homeLoading: action.payload
      }
    },
    likePostTrigger: (state, action) => {
      let updatedList=[]
      if(action.payload.type=="like"){
      updatedList = _.map(state.myFeedInfo, (item) => {
        return item.id === action.payload.id? { ...item, likeCount: item.likeCount +1 } : item
      })
    }else{
      updatedList = _.map(state.myFeedInfo, (item) => {
        return item.id === action.payload.id? { ...item, likeCount: item.likeCount -1 } : item
      })
    }
      return{
        ...state,
        myFeedInfo: updatedList
      }
    },
    searchSuccess: (state, action) => {
      return{
        ...state,
        searchInfo: action.payload
      }
    },
    searchTrigger: (state, action) => {
      return{
        ...state,
        searchLoading: action.payload
      }
    }
  },
});
export default userslice.reducer;
 
export const { 
  recentProfileSuccess,
  recentProfileTrigger,
  otherProfileSuccess,
  singleProfileTrigger,
  myFeedSuccess,
  myFeedTrigger,
  likePostTrigger,
  searchSuccess,
  searchTrigger
 } = userslice.actions;
 
