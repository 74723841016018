import { all, takeEvery, takeLatest } from "redux-saga/effects";
import API from "./../services/Api";
import {
  signIn,
  signUp,
  forgotPassword,
  getUser,
  setForgetdone,
  updateProfileImage,
  updateBannerImage,
  followRequest,
  unfollow,
  getMyFollowingid,
  logout,
  editUser,
  VerificationOtp,
  setpasswordmobile,
  getMyLikesId,
  updateEmailMobile,
} from "./usersaga";
import { changeform, changescreen } from "./screentrackersaga";
import {
  getRecentProfiles,
  getProfileById,
  getMyFeed,
  unblockUser,
  blockUser,
  getMyBlockListId,
  searchByUsername,
} from "./otherssaga";
import {
  getMyPost,
  createPost,
  editPost,
  singlePost,
  getSingleProfilePostsById,
  deletePost,
  reportPost,
  likePost,
  getMydraftPost,
  deletePostdraft,
  uploadStatus,
  edituploadStatus,
  postComment,
  getPostComment,
  replyPostComment,
  getCommentReply,
  getCommentReplyCount,
  getPostCommentcount,
  deleteComment,
  editComment,
  sharePost,
  getsinglePost,
} from "./postsaga";

import {
  addProducts,
  getProducts,
  getCategories,
  getMyProducts,
  deleteProduct,
  editProductServices,
  addtocart,
  getCartProducts,
  removecartProduct,
  addAddress,
  getAddress,
  editAddress,
  orderRequest,
  updateCartProducts,
  getMyOrder,
  getMyOrderRequest,
} from "./productsaga";
const api = API.create();
export default function* root() {
  yield all([
    takeEvery("SignIncalled", signIn, api),
    takeEvery("SignUpcalled", signUp, api),
    takeEvery("ChangeScreenCalled", changescreen, api),
    takeEvery("ChangeFormCalled", changeform, api),
    takeEvery("ForgotPasswordcalled", forgotPassword, api),
    takeEvery("GetUserCalled", getUser, api),
    takeEvery("Forgotdonecalled", setForgetdone, api),
    takeEvery("GetMyPostCalled", getMyPost, api),
    takeEvery("EditUserCalled", editUser, api),
    takeEvery("UpdateProfileImageCalled", updateProfileImage, api),
    takeEvery("UpdateBannerImageCalled", updateBannerImage, api),
    takeEvery("CreatePostCalled", createPost, api),
    takeEvery("EditPostCalled", editPost, api),
    takeEvery("singlePostCalled", singlePost, api),
    takeEvery("GetRecentProfilesCalled", getRecentProfiles, api),
    takeEvery("GetProfileByIdCalled", getProfileById, api),
    takeEvery(
      "GetSingleProfilePostsByIdCalled",
      getSingleProfilePostsById,
      api
    ),
    takeEvery("deletePostCalled", deletePost, api),
    takeEvery("GetMyFeedCalled", getMyFeed, api),
    takeEvery("FollowRequestCalled", followRequest, api),
    takeEvery("UnfollowCalled", unfollow, api),
    takeEvery("GetMyFollowingidCalled", getMyFollowingid, api),
    takeEvery("logoutCalled", logout, api),
    takeEvery("BlockUserCalled", blockUser, api),
    takeEvery("UnblockUserCalled", unblockUser, api),
    takeEvery("GetMyBlockListIdCalled", getMyBlockListId, api),
    takeEvery("reportpostCalled", reportPost, api),
    // takeEvery('DeleteProfileImageCalled', deleteProfileImage, api),
    // takeEvery('DeleteBannerImageCalled', deleteBannerImage, api),
    takeEvery("OtpverifyCalled", VerificationOtp, api),
    takeEvery("setpasswordcalled", setpasswordmobile, api),
    takeEvery("GetMyLikesIdCalled", getMyLikesId, api),
    takeEvery("LikePostCalled", likePost, api),
    takeEvery("SearchByUsernameCalled", searchByUsername, api),
    takeEvery("deletePostDraftCalled", deletePostdraft, api),
    takeEvery("getdraftPostCalled", getMydraftPost, api),
    takeEvery("uploadStatusCalled", uploadStatus, api),
    takeEvery("edituploadStatusCalled", edituploadStatus, api),
    takeEvery("updateEmailMobileCalled", updateEmailMobile, api),
    takeEvery("addProductCalled", addProducts, api),
    takeEvery("getProductCalled", getProducts, api),
    takeEvery("getCategoriesCalled", getCategories, api),
    takeEvery("getMyProductsCalled", getMyProducts, api),
    takeEvery("deleteProductCalled", deleteProduct, api),
    takeEvery("editProductServicesCalled", editProductServices, api),
    takeEvery("addtocartCalled", addtocart, api),
    takeEvery("getCartProductsCalled", getCartProducts, api),
    takeEvery("removecartProductCalled", removecartProduct, api),
    takeEvery("addAddressCalled", addAddress, api),
    takeEvery("getAddressCalled", getAddress, api),
    takeEvery("editAddressCalled", editAddress, api),
    takeEvery("orderRequestCalled", orderRequest, api),
    takeEvery("updatecartCalled", updateCartProducts, api),  
    takeEvery("getMyOrderCalled", getMyOrder, api),
    takeEvery("getMyOrderRequestCalled", getMyOrderRequest, api),   
    takeEvery("postCommentCalled", postComment, api), 
    takeEvery("getPostCommentCalled", getPostComment, api),
    takeEvery("replyPostCommentCalled", replyPostComment, api),
    takeEvery("getCommentReplyCalled", getCommentReply, api),  
    takeEvery("getCommentReplyCountCalled", getCommentReplyCount, api), 
    takeEvery("getPostCommentcountCalled", getPostCommentcount, api),  
    takeEvery("deleteCommentCalled", deleteComment, api),
    takeEvery("editCommentCalled", editComment, api),  
    takeEvery("sharePostCalled", sharePost, api),  
    takeEvery("getsinglePostCalled", getsinglePost, api),
  ]);
}
