import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Card, Nav, Button } from "react-bootstrap";
import "@themes/CommonApplicationStyles.css";
import "@features/nftdetail/NftdetailStyles.css";
import "@components/detailstab/DetailstabStyles.css";

import * as _ from "lodash";
import { useHistory } from "react-router-dom";
import Dpcreator from "@components/dpround/Dpcreator";

function Activity() {
  const history = useHistory();
  const [key, setkey] = useState("1");
  const { productInfo } =
    (history && history.location && history.location.state) || {};
  const { id, title, amount, images } = productInfo || {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container style={{marginTop:'40px'}}>
        <div className="content">

                <Row lg={12} style={{ marginLeft: "20px",marginBottom:'20px',marginTop:'10px' }}>
                <Dpcreator />

                <Col>
                <div className="destitle">You added nft 4 hours ago </div>
                <div  className="description"> <span style={{textTransform:'uppercase'}}>Art</span></div>

                </Col>

                </Row>

                <Row lg={12} style={{ marginLeft: "20px",marginBottom:'20px',marginTop:'10px' }}>
                <Dpcreator /> 
                <Col>
                <div className="destitle">You have added <span style={{textTransform:'uppercase'}}>Art</span> On Sale  </div>
                <div  className="description"> for <span style={{textTransform:'uppercase'}}>100 eth</span></div>

                </Col>
                </Row>

            
        </div>
      </Container>
    </div>
  );
}
export default Activity;
