import React, { useState } from 'react';
import InputField from '@components/input-field/InputField';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(
  'pk_test_51IhpX7L9qOT0hiu3w3AqOK0mXiaDIBfs9x5K0XYicih2wiFeMRxpMnm8JOhhmOUvCpxc0EZ52kCfXFjA3suDxw9Y00ckJ9Hn3U'
);

import CustomButton from '@components/custom-button/CustomButton';
import '@components/popup/PopUpStyle.css';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Form, Col, Row, Spinner } from 'react-bootstrap';
import cryptoRandomString from 'crypto-random-string';
import { useHistory } from 'react-router-dom';

const sdk = require('dragonchain-sdk');

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

function PopUpBuy({ editType, editUser, userInfo, onclose }) {
  const { nft, detail } = editType || {};
  const {
    amount,
    description,
    userid,
    discount,
    ownerid,
    onsale,
    discountPerc,
  } = detail || {};
  const { nftid, name, type } = nft || {};
  const history = useHistory();
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [transactionid, setTransactionId] = useState('');
  const editUserHandle = async (event) => {
    setLoader(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    console.log(nftid);
    if (form.checkValidity() === true) {
      event.preventDefault();
      const token = localStorage.getItem('accessToken');
      axios
        .post(
          `${URL.API}/transfer/transfernftandbuy/${nftid}`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          handlePayment(response.data.sessionid);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
    setLoader(true);
  };

  const handlePayment = async (session) => {
    const stripe = await stripePromise;
    console.log('Session');
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session,
    });
    console.log('Success:', result.success, result);
    // history.push({ pathname: '/buysuccess' });
    onclose();

    if (result.error) {
      console.log('Error', result.error);
    }
  };

  return (
    <div className='pop'>
      <div className='popupcontent'>
        <Form noValidate validated={validated} onSubmit={editUserHandle}>
          <center>
            <label>NFT Name : </label>&nbsp;&nbsp;
            <label>{name} </label>
            <br></br>
            <label>NFT Id : </label>&nbsp;&nbsp;
            <label>{nftid} </label>
            <br></br>
            <label>Amount : </label>&nbsp;&nbsp;
            <label>${amount} </label>
          </center>

          <Col style={{ textAlign: 'center' }}>
            {loader && <Spinner animation='border' variant='primary' />}
          </Col>
          <Row>
            <Col>
              <CustomButton
                type='submit'
                filled={true}
                primary={true}
                name='Buy NFT'
              />
            </Col>
            <Col>
              <CustomButton
                type='Cancel'
                filled={true}
                primary={true}
                name='Cancel'
                // onClick={() => editCloseHandle()}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const userInfo = _.get(user, 'userInfo', '');
  return { userInfo };
};

const mapDispatchToProps = (dispatch) => ({
  editUser: (data) => dispatch({ type: 'EditUserCalled', payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopUpBuy);
