import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, Nav, Button, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '@themes/CommonApplicationStyles.css';
import Tab from '@components/tab/Tab.js';
import '@features/nftdetail/NftdetailStyles.css';
import * as _ from 'lodash';
import Loader from '@components/loader/Loader';
import NavBar from '@navigation/NavBar';
import { useHistory } from 'react-router-dom';
import pdficon from '@images/file.png';
import CustomButton from '@components/custom-button/CustomButton';
import Searchbar from '@components/searchbar/Searchbar';
import DPround from '@components/dpround/DPround';
import Details from '@components/detailstab/Details';
import Bids from '@components/detailstab/Bids';
import Owner from '@components/detailstab/Owner';
import History from '@components/detailstab/History';
const sdk = require('dragonchain-sdk');
import Modal from 'react-modal';
import { CgCloseO } from 'react-icons/cg';
import PopUp from '@components/popup/PopUp';
import PopUpPrice from '@components/popup/PopUpPrice';
import PopUpBuy from '@components/popup/PopUpBuy';
import { Carousel } from 'react-responsive-carousel';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

function Nftdetail({ match }) {
  const [modal, setmodal] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [details, setDetails] = useState(null);
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [editType, seteditType] = useState('');

  function openModal1() {
    setmodalIsOpen(true);
  }
  function closeModal1() {
    setmodalIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '52%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: '1px solid rgb(204 204 204 / 22%)',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 0px 20px 0px #dee2e669',
      borderRadius: '30px',
    },
  };
  const history = useHistory();
  const [key, setkey] = useState('1');
  const { productInfo } =
    (history && history.location && history.location.state) || {};
  const nftid = (match && match.params && match.params.id) || {};
  const { nft, image, detail } = productInfo || {};
  const { amount, description, userid, discount, ownerid, onsale, note } =
    detail || {};
  const { name, type } = nft || {};
  const [loader, setLoader] = useState(false);
  const [historydetails, setHistorydetails] = useState('');
  const [buyerid, setBuyer] = useState('');
  const [sellerid, setSeller] = useState('');
  const [amountt, setAmount] = useState('');
  const [owner, setOwner] = useState(nft.ownerid);
  const [username, setuserName] = useState('');
  const [creator, setCreator] = useState('');
  useEffect(async () => {
    window.scrollTo(0, 0);
    setLoader(true);
    const token = localStorage.getItem('accessToken');
    await axios
      .get(`${URL.API}/nft/${nftid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        // localStorage.setItem('nft_details', JSON.stringify(response.data[0]));
        setDetails(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
    await axios
      .get(`${URL.API}/nft/ownersandhistory/${nftid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        localStorage.setItem('nft_owner', JSON.stringify(response.data));
        setOwnerDetails(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoader(false);
  }, []);
  const buy = async () => {
    history.push('/buynft', { productInfo });
  };
  var account = JSON.parse(localStorage.getItem('user'));
  var customimg = [];
  image &&
    image.filename &&
    image.filename.map((item) => {
      return (
        item &&
        item.map((im) => {
          if (im.size == 'thumbnail') {
            customimg.push(im.link);
          }
        })
      );
    });
  // image &&
  //   image.filename[0] &&
  //   image.filename[0].map((val) => {
  //     if (val.size == 'thumbnail') {
  //       customimg = val.link;
  //     }
  //   });
  var profilePic;
  ownerDetails &&
    ownerDetails.latestowner &&
    ownerDetails.latestowner.profileImage &&
    ownerDetails.latestowner.profileImage.profileimage &&
    ownerDetails.latestowner.profileImage.profileimage.map((pic) => {
      if (pic.size == 'thumbnail') {
        profilePic = pic.link;
      }
    });
  console.log('hi', details && details.creator && details.creator);
  return (
    <div id='Nftdetail'>
      <NavBar location={useLocation()} />
      <Col style={{ textAlign: 'center', marginTop: 30 }}>
        {loader && <Spinner animation='border' variant='primary' />}
      </Col>
      {!loader && (
        <Container style={{ marginTop: '30px' }}>
          <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal1}
            style={customStyles}
          >
            <div onClick={closeModal1} style={{ textAlign: 'right' }}>
              <CgCloseO size={'20px'} className={'absolutecross'} />
            </div>
            <PopUpBuy
              editType={productInfo}
              onclose={() => {
                setmodalIsOpen(false);
              }}
            />
          </Modal>
          <h3 className='mb-4 text-center' style={{ marginBottom: '50px' }}>
            NFT Details
          </h3>
          <div className='content'>
            <Row>
              <Col lg={6} xs={12}>
                <div className='productimage' style={{ marginBottom: '40px' }}>
                  <Carousel
                    showArrows={true}
                    infiniteLoop={true}
                    dynamicHeight={true}
                    stopOnHover={true}
                    showStatus={true}
                    showThumbs={false}
                    autoPlay={false}
                    swipeable={true}
                    draggable={true}
                    showIndicators={true}
                  >
                    {customimg &&
                      customimg.map((item) => (
                        <img
                          src={item}
                          alt='image'
                          className='nfti img-fluid'
                        />
                      ))}
                    {/* {image !== 'no image' &&
                    image &&
                    image.filename.map((item) => (
                      <img
                        src={`data:image/png;base64,${item}`}
                        alt=''
                        className='nfti'
                      />
                    ))} */}
                  </Carousel>
                  {/* {image !== 'no image' && (
                  <img
                    alt='Image'
                    src={`data:image/png;base64,${image.filename[0]}`}
                    className='nfti'
                  />
                )} */}
                </div>
              </Col>

              <Col lg={6} xs={12}>
                {/* <Col xs={12}> */}
                <p className='nfttitle'>{name}</p>
                {/* </Col> */}
                {(!onsale || onsale != 'false') && (
                  // <Col lg={12}>
                  <p className='details'>{amount} USD </p>
                  // </Col>
                )}
                {onsale == 'false' && (
                  // <Col lg={12}>
                  <p className='details'></p>
                  // </Col>
                )}
                <p style={{}}>NFT ID: {nftid}</p>
                <p
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 300,
                  }}
                >
                  <h5>Description: </h5>

                  {description}
                </p>
                <br />
                {details && details.document && details.document.filename && (
                  <Col>
                    <h5>Attachments</h5>
                    {details &&
                      details.document &&
                      details.document.filename &&
                      details.document.filename.map((file) => (
                        <div className='d-flex  align-items-cetner mt-4'>
                          <img
                            src={pdficon}
                            alt='image'
                            height={30}
                            width={30}
                            className='attachment'
                          />
                          <a
                            href={file && file.name}
                            className='d-flex align-items-center'
                            style={{ margin: 0, marginLeft: 10 }}
                          >
                            {file && file.filename}.pdf
                          </a>
                        </div>
                      ))}
                  </Col>
                )}
                <Row lg={12} style={{}}>
                  <Col lg={4} xs={4} style={{ marginLeft: '10px' }}>
                    {(!onsale || onsale != 'false') &&
                      parseInt(account && account.uuid) !=
                        parseInt(
                          ownerDetails &&
                            ownerDetails.latestowner &&
                            ownerDetails.latestowner.uuid
                        ) && (
                        <CustomButton
                          type='Post'
                          filled={true}
                          primary={true}
                          name='Buy NFT'
                          onClick={() => openModal1()}
                        />
                      )}
                  </Col>
                  <Col lg={4} xs={4} style={{ marginLeft: '10px' }}>
                    <CustomButton
                      type='Post'
                      filled={true}
                      primary={true}
                      name='NFT Logs'
                      onClick={() =>
                        history.push(`/nftlog/${nftid}`, { nftid })
                      }
                    />
                  </Col>
                  {/* <hr
                  style={{ width: '600px', marginLeft: '10px' }}
                  className='hrrounded'
                /> */}

                  {!loader && (
                    <Row
                      lg={12}
                      style={{ marginLeft: '10px', marginTop: '25px' }}
                    >
                      <Col lg={2} xs={12}>
                        <p
                          className='txt'
                          style={{ marginLeft: '12px', marginTop: '10px' }}
                        >
                          Owner:
                        </p>
                      </Col>
                      <Col lg={2} xs={3}>
                        <DPround profileImage={profilePic} page='small' />
                      </Col>
                      <Col lg={8} xs={9}>
                        <div>
                          <label
                            style={{ marginTop: '10px' }}
                            className='linkhover'
                            onClick={() => {
                              localStorage.setItem(
                                'other-user-id',
                                ownerDetails &&
                                  ownerDetails.latestowner &&
                                  ownerDetails.latestowner.uuid
                              );
                              localStorage.setItem(
                                'other-user-name',
                                ownerDetails &&
                                  ownerDetails.latestowner &&
                                  ownerDetails.latestowner.username
                              );
                              history.push('/othersprofile');
                            }}
                          >
                            {ownerDetails &&
                              ownerDetails.latestowner &&
                              ownerDetails.latestowner.uuid}{' '}
                            (
                            {ownerDetails &&
                              ownerDetails.latestowner &&
                              ownerDetails.latestowner.username}
                            )
                          </label>
                        </div>
                        <div>
                          <label style={{ marginTop: '0px' }}></label>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Row>{' '}
                {/* <hr style={{ width: '490px' }} /> */}
                <div style={{ marginTop: '25px' }}>
                  <Tab
                    page='nftdetails'
                    onSelect={(res) => setkey(res)}
                    history={history}
                  />
                  {key == 1 && (
                    <Details
                      ownerid={nft.ownerid}
                      type={type}
                      creator={
                        details && details.creator && details.creator.userName
                      }
                      creatordp={
                        details &&
                        details.creator &&
                        details.creator.profileImage
                      }
                      loader={loader}
                      note={note}
                    />
                  )}
                  {/* {key == 2 && <Bids />} */}
                  {key == 3 && (
                    <Owner
                      historydetails={historydetails}
                      nft={nft}
                      creator={
                        details && details.creator && details.creator.userName
                      }
                      owner={ownerDetails && ownerDetails.ownerList}
                    />
                  )}
                  {key == 4 && (
                    <History
                      historydetails={ownerDetails && ownerDetails.historyList}
                      nft={nft}
                      // ownerList={details && details.ownerList}
                      creator={details && details.creator}
                      ownerList={ownerDetails && ownerDetails.ownerList}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
}

export default Nftdetail;
