import React, { useState, useRef, useEffect } from 'react';
import '@themes/CommonApplicationStyles.css';
import { useLocation } from 'react-router-dom';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import InputField from '@components/input-field/InputField';
import CustomButton from '@components/custom-button/CustomButton';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import NavBar from '@navigation/NavBar';
import '@features/create-post/CreatePostStyles.css';
import StringCrypto from 'string-crypto';
import cryptoRandomString from 'crypto-random-string';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

const sdk = require('dragonchain-sdk');

export default function Signin() {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [loader, setLoader] = useState(false);
  const [method, setmethod] = useState('Signup');
  const { encryptString, decryptString } = new StringCrypto();
  const [validated, setValidate] = useState(false);
  const [errortxt, seterrortxt] = useState('');
  const history = useHistory();
  const onSubmit = async (event) => {
    const form = event.currentTarget;
    seterrortxt('');
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      await axios
        .post(`${URL.API}/auth/signin`, {
          mobile: code,
          password,
        })
        .then(function (response) {
          localStorage.setItem('accessToken', response.data.accessToken);
          history.push({ pathname: '/explore' });
        })
        .catch(async function (error) {
          console.log(error.response.data);
          if (error.response.data.message == 'Mobile is not verified') {
            await axios
              .post(`${URL.API}/auth/resendOtp`, {
                mobile: code,
              })
              .then(function (response) {
                history.push({ pathname: '/verify-otp', state: { code } });
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            console.log('in Else', error.response.data.message);
            seterrortxt(
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
            );
          }
        });
      setLoader(false);
    }
  };
  return (
    <div className='onboarding-container'>
      {/* <NavBar location={useLocation()} /> */}
      <Container style={{ marginTop: '150px' }}>
        <Row className='justify-content-md-center'>
          <Col lg={8} className='onboarding-box'>
            <h3 className='mb-4 text-center'>Sign In</h3>
            <Form
              noValidate
              validated={validated}
              onSubmit={onSubmit}
              className='d-flex flex-column  justify-content-center'
            >
              <Col lg={4} xs={8} className='d-flex align-self-center'>
                <InputField
                  phone
                  type='Phone'
                  placeholder={'Mobile'}
                  onChange={(e) => {
                    console.log('code', e);
                    setCode(e);
                  }}
                  value={mobile}
                  required
                  error={
                    mobile.length == 0
                      ? 'Phone cannot be empty'
                      : 'Phone must be a valid'
                  }
                />
              </Col>
              <Col lg={4} xs={8} className='align-self-center'>
                <InputField
                  type='password'
                  value={password}
                  placeholder='Enter Password'
                  label='Password'
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  error='Please Enter Password'
                />
              </Col>
              {
                <Col style={{ textAlign: 'center' }}>
                  {loader && <Spinner animation='border' variant='primary' />}
                </Col>
              }
              {errortxt != '' && (
                <h5
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  {errortxt == 'mobilenotexist'
                    ? 'User does not exist'
                    : errortxt == 'Invalidpassword'
                    ? 'Invalid Password'
                    : errortxt}
                </h5>
              )}
              <Row className='justify-content-md-center'>
                <Col lg={4} xs={8} className='mt-2' style={{ margin: 'auto' }}>
                  <CustomButton
                    type='Post'
                    filled={true}
                    primary={true}
                    disable={loader || code.length < 12}
                    name='Sign In'
                    // onClick={() => login()}
                  />
                </Col>
              </Row>
              <Row className='justify-content-md-center'>
                <Col
                  lg={6}
                  xs={8}
                  className='mt-3'
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                >
                  Not Registered Yet? <a href='/signup'>Sign Up Here</a>
                </Col>
              </Row>
              <Row className='justify-content-md-center'>
                <Col
                  lg={6}
                  xs={8}
                  className='mt-3'
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                >
                  Forgot Password?{' '}
                  <a href='/reset-password'>Reset Your Password</a>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// const mapStateToProps = ({ tracker = {}, post = {} }) => {
//   const createpostLoading = _.get(post, "createpostLoading", false);
//   const editpostloading = _.get(post, "editpostloading", false);
//   return { createpostLoading, editpostloading };
// };

// const mapDispatchToProps = (dispatch) => ({
//   createPost: (data) => dispatch({ type: "CreatePostCalled", payload: data }),
//   editPost: (data) => dispatch({ type: "EditPostCalled", payload: data }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Signin);
