import 'bootstrap/dist/css/bootstrap.min.css';

import '@themes/CommonApplicationStyles.css';
import EditUploadMessage from '@components/upload-message/EditUploadMessage';
import UploadMessage from '@components/upload-message/UploadMessage';
import ProfileInfo from '@features/profile/profile-info/ProfileInfo';
import Nftdetail from '@features/nftdetail/Nftdetail';
import nftlogs from '@features/nftdetail/nftlogs';
import nftlogdetails from '@features/nftdetail/nftlogdetails';
import nftlograwdetails from '@features/nftdetail/nftlograwdetails';
import Portfolio from '@features/portfolio/Portfolio';
import Othersprofile from '@features/portfolio/Othersprofile';
import Searchnft from '@features/searchnft/Searchnft';

import createnft from '@features/createnft/createnft';
import Explore from '@features/explore/Explore';

import { persistor, store } from '@store';
import React from 'react';
import 'react-phone-input-2/lib/style.css';
import { Provider, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import signup from './features/signup/signup';
import signin from './features/signin/signin';
import editnft from './features/editnft/editnft';
import buynft from './features/buynft/buynft';
import buysuccess from './features/buysuccess/buysuccess';
import buyerror from './features/buysuccess/buyerror';
import ResendOTP from './features/OTPScreen/ResendOTP';
import VerifyOTP from './features/OTPScreen/VerifyOTP';
import requestResetPassword from './features/reset-password/request-reset-password';
import SetNewPassword from './features/reset-password/SetNewPassword';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.user);

  let Authorized = auth && auth.auth;
  return (
    <Route
      {...rest}
      render={(props) =>
        Authorized ? <Component {...props} /> : <Redirect to='/signin' />
      }
    />
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <UploadMessage />
          <EditUploadMessage />
          <Switch>
            <Route exact path='/' component={signin} />
            <Route exact path='/profileinfo' component={ProfileInfo} />
            {/* <Route exact path="/createnftinfo" component={CreateNft} />  */}
            <Route exact path='/explore' component={Explore} />
            <Route exact path='/nftdetail/:id' component={Nftdetail} />
            <Route exact path='/nftlog/:id' component={nftlogs} />
            <Route exact path='/nftlogdetails/:id' component={nftlogdetails} />
            <Route
              exact
              path='/nftrawdetails/:id'
              component={nftlograwdetails}
            />
            <Route exact path='/portfolio' component={Portfolio} />
            <Route exact path='/resend-otp' component={ResendOTP} />
            <Route exact path='/verify-otp' component={VerifyOTP} />
            <Route
              exact
              path='/reset-password'
              component={requestResetPassword}
            />
            <Route exact path='/set-new-password' component={SetNewPassword} />
            <Route exact path='/othersprofile' component={Othersprofile} />

            <Route exact path='/search' component={Searchnft} />

            <Route exact path='/signup' component={signup} />
            <Route exact path='/signin' component={signin} />
            <Route exact path='/createnft' component={createnft} />
            <Route exact path='/editnft' component={editnft} />
            <Route exact path='/buynft' component={buynft} />
            <Route exact path='/buysuccess' component={buysuccess} />
            <Route exact path='/buyerror' component={buyerror} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
