import CustomButton from '@components/custom-button/CustomButton';
import React, { useState, useRef, useEffect } from 'react';
import InputField from '@components/input-field/InputField';
import cryptoRandomString from 'crypto-random-string';
import '@features/create-post/CreatePostStyles.css';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import '@themes/CommonApplicationStyles.css';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import StringCrypto from 'string-crypto';
import NavBar from '@navigation/NavBar';
import { connect } from 'react-redux';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

const sdk = require('dragonchain-sdk');
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
export default function Signup() {
  const [mobile, setMobile] = useState('');
  const [username, setusername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [loader, setLoader] = useState(false);
  const [method, setmethod] = useState('Signup');
  const { encryptString, decryptString } = new StringCrypto();
  const [errortxt, seterrortxt] = useState('');
  const [validated, setValidate] = useState(false);
  const [key, setKey] = useState(0);
  const [otp, setOtp] = useState('');

  const history = useHistory();

  const onSubmit = async (event) => {
    const form = event.currentTarget;
    seterrortxt('');
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      await axios
        .post(`${URL.API}/auth/signup`, {
          mobile: code,
          userName: username,
          password,
        })
        .then(function (response) {
          history.push({ pathname: '/verify-otp', state: { code } });
        })
        .catch(function (error) {
          seterrortxt(error.response.data.message);
          console.log(error.response);
        });

      setLoader(false);
    }
  };

  return (
    <div>
      {/* <NavBar location={useLocation()} /> */}
      <Container style={{ marginTop: '150px' }}>
        <Row className='justify-content-md-center'>
          <Col lg={8} className='onboarding-box'>
            <h3 className='mb-4 text-center'>
              {key == 0 ? 'Sign Up' : key == 1 ? 'Verify Mobile' : 'Resend OTP'}
            </h3>

            <Form
              noValidate
              validated={validated}
              onSubmit={onSubmit}
              className='d-flex flex-column  justify-content-center'
            >
              <Col lg={4} xs={8} className='align-self-center'>
                <InputField
                  type='text'
                  value={username}
                  placeholder='Enter Username'
                  label='Username'
                  required
                  onChange={(e) => setusername(e.target.value)}
                  error='Please Enter Username'
                />
              </Col>
              <Col lg={4} xs={8} className='d-flex align-self-center'>
                <InputField
                  phone
                  type='Phone'
                  placeholder={'Mobile'}
                  onChange={(e) => {
                    console.log('code', e);
                    setCode(e);
                  }}
                  value={mobile}
                  required
                  error={
                    mobile.length == 0
                      ? 'Phone cannot be empty'
                      : 'Phone must be a valid'
                  }
                />
              </Col>
              <Col lg={4} xs={8} className='align-self-center'>
                <InputField
                  type='password'
                  value={password}
                  placeholder='Enter Password'
                  label='Password'
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  error='Please Enter Password'
                />
              </Col>

              {
                <Col style={{ textAlign: 'center' }}>
                  {loader && <Spinner animation='border' variant='primary' />}
                </Col>
              }
              {errortxt != '' && (
                <h5
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  {errortxt == 'MobileExist'
                    ? 'User already exist'
                    : errortxt == 'usernameExist'
                    ? 'Username is not available'
                    : errortxt}
                </h5>
              )}
              <Row className='justify-content-md-center'>
                <Col
                  lg={4}
                  xs={8}
                  className='align-self-center'
                  style={{ margin: 'auto' }}
                >
                  <CustomButton
                    type='Post'
                    filled={true}
                    primary={true}
                    disable={loader || code.length < 12}
                    name='Sign Up'
                    // onClick={() => register()}
                  />
                </Col>
              </Row>
              <Row className='justify-content-md-center'>
                <Col
                  lg={6}
                  xs={8}
                  className='mt-3'
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                >
                  Already Registered? <a href='/signin'>Sign In Here</a>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
// const mapStateToProps = ({ tracker = {}, post = {} }) => {
//   const createpostLoading = _.get(post, "createpostLoading", false);
//   const editpostloading = _.get(post, "editpostloading", false);
//   return { createpostLoading, editpostloading };
// };

// const mapDispatchToProps = (dispatch) => ({
//   createPost: (data) => dispatch({ type: "CreatePostCalled", payload: data }),
//   editPost: (data) => dispatch({ type: "EditPostCalled", payload: data }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Signup);
