import React, { useState } from 'react';
import InputField from '@components/input-field/InputField';
import CustomButton from '@components/custom-button/CustomButton';
import '@components/popup/PopUpStyle.css';
import { Form, Col, Spinner } from 'react-bootstrap';

const sdk = require('dragonchain-sdk');
//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

function PopUpPrice({
  editType,
  editUser,
  userInfo,
  onclose,
  getPortfolio,
  setRefresh,
  refresh,
}) {
  const { nft, detail } = editType || {};
  const {
    amount,
    description,
    userid,
    discount,
    ownerid,
    onsale,
    discountPerc,
  } = detail || {};
  const { nftid, name, type } = nft || {};

  const [loader, setLoader] = useState(false);
  const [desc1, setDesc] = useState(description);
  const [amount1, setAmount] = useState(amount);
  const [name1, setNftName] = useState(name);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');

  const putonsale = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    if (form.checkValidity() === true) {
      event.stopPropagation();
      event.preventDefault();
      console.log('Caled', amount1);
      const token = localStorage.getItem('accessToken');

      setLoader(true);
      await axios
        .post(
          `${URL.API}/nfts/putonsale/${nftid}`,
          { onsale: 'true', amount: amount1 },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(function (response) {
          // history.push({ pathname: '/portfolio' });
          window.location.reload();
          // getPortfolio();
        })
        .catch(function (error) {
          console.log(error.response);
        });
      // getPortfolio();
    }
    onclose();
    setLoader(false);
  };

  return (
    <div className='pop'>
      <div className='popupcontent'>
        <Form noValidate validated={validated} onSubmit={putonsale}>
          <label>NFT Name : </label>&nbsp;&nbsp;
          <label>{name1} </label>
          <InputField
            type='text'
            value={amount1}
            placeholder='Amount (in $)'
            label='Amount'
            onChange={(e) => setAmount(e.target.value)}
            required
            error='Please Enter amount'
          />
          <Col style={{ textAlign: 'center' }}>
            {loader && <Spinner animation='border' variant='primary' />}
          </Col>
          <CustomButton
            type='submit'
            filled={true}
            primary={true}
            name='Put On Sale'
          />
        </Form>
      </div>
    </div>
  );
}

export default PopUpPrice;
