import React, { useState, useRef, useEffect } from 'react';
import '@themes/CommonApplicationStyles.css';
import { useLocation } from 'react-router-dom';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import InputField from '@components/input-field/InputField';
import CustomButton from '@components/custom-button/CustomButton';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import NavBar from '@navigation/NavBar';
import '@features/create-post/CreatePostStyles.css';
import StringCrypto from 'string-crypto';
import cryptoRandomString from 'crypto-random-string';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

const sdk = require('dragonchain-sdk');

export default function Signin() {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState('');
  const [method, setmethod] = useState('Signup');
  const { encryptString, decryptString } = new StringCrypto();
  const [validated, setValidate] = useState(false);
  const [tab, setTab] = useState(1);
  const [errortxt, seterrortxt] = useState('');
  const history = useHistory();

  const onSubmit = async (event) => {
    const form = event.currentTarget;
    seterrortxt('');
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      await axios
        .post(`${URL.API}/auth/request-password-reset`, {
          mobile: code,
        })
        .then(function (response) {
          // history.push({ pathname: '/explore' });
          setTab(2);
        })
        .catch(async function (error) {
          console.log(
            error &&
              error.response &&
              error.response.data &&
              error.response.data.statusCode
          );
        });

      setLoader(false);
    }
  };

  const onSubmit2 = async (event) => {
    const form = event.currentTarget;
    seterrortxt('');
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      await axios
        .post(`${URL.API}/auth/verify-otp`, {
          mobile: code,
          otp,
        })
        .then(function (response) {
          history.push({ pathname: '/set-new-password', state: { code } });
        })
        .catch(function (error) {
          console.log(error && error.response);
          seterrortxt(
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
          );
        });
      setLoader(false);
    }
  };
  return (
    <div>
      {/* <NavBar location={useLocation()} /> */}
      <Container style={{ marginTop: '150px' }}>
        <Row className='justify-content-md-center'>
          <Col md={6} lg={8} className='onboarding-box'>
            <Col
              lg={4}
              xs={8}
              className='align-self-center'
              style={{ margin: 'auto' }}
            >
              <h3 className='mb-4 '>
                {tab == 1 ? 'Enter Mobile Number' : 'Enter Otp'}
              </h3>
            </Col>
            {tab == 1 && (
              <Form
                noValidate
                validated={validated}
                onSubmit={onSubmit}
                className='d-flex flex-column  justify-content-center'
              >
                <Col lg={4} xs={8} className='d-flex align-self-center'>
                  <InputField
                    phone
                    type='Phone'
                    placeholder={'Mobile'}
                    onChange={(e) => {
                      console.log('code', code.length);
                      setCode(e);
                    }}
                    value={mobile}
                    required
                    error={
                      code.length != 12
                        ? 'Phone cannot be empty'
                        : 'Phone must be a valid'
                    }
                  />
                </Col>
                <Col style={{ textAlign: 'center' }}>
                  {loader && <Spinner animation='border' variant='primary' />}
                </Col>
                {errortxt != '' && (
                  <h5 style={{ color: 'red', textAlign: 'center' }}>
                    {errortxt == 'OtpNotMatched'
                      ? 'Invalid OTP'
                      : errortxt == 'OtpExpired'
                      ? 'OTP Expired'
                      : errortxt == 'OtpNotGenerated'
                      ? 'OTP Not Generated'
                      : errortxt}
                  </h5>
                )}
                <Row className='justify-content-md-center'>
                  <Col
                    lg={4}
                    xs={8}
                    className='align-self-center'
                    style={{ margin: 'auto' }}
                  >
                    <CustomButton
                      type='Post'
                      filled={true}
                      primary={true}
                      disable={loader || code.length < 12}
                      name='Submit'
                      // onClick={() => login()}
                    />
                  </Col>
                </Row>
              </Form>
            )}
            {tab == 2 && (
              <Form noValidate validated={validated} onSubmit={onSubmit2}>
                <Col
                  lg={4}
                  xs={8}
                  className='align-self-center'
                  style={{ margin: 'auto' }}
                >
                  <InputField
                    type='otp'
                    placeholder={'OTP'}
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                    error={
                      otp.length == 0 ? 'otp cannot be empty' : 'not valid'
                    }
                    required
                  />
                </Col>

                <Col style={{ textAlign: 'center' }}>
                  {loader && <Spinner animation='border' variant='primary' />}
                </Col>
                {errortxt != '' && (
                  <h5
                    style={{
                      color: 'red',
                      textAlign: 'center',
                      textTransform: 'capitalize',
                    }}
                  >
                    {errortxt == 'OtpNotMatched'
                      ? 'Invalid OTP'
                      : errortxt == 'OtpExpired'
                      ? 'OTP Expired'
                      : errortxt == 'OtpNotGenerated'
                      ? 'OTP Not Generated'
                      : errortxt}
                  </h5>
                )}
                <Row className='justify-content-md-center'>
                  <Col
                    lg={4}
                    xs={8}
                    className='align-self-center'
                    style={{ margin: 'auto' }}
                  >
                    <CustomButton
                      type='Post'
                      filled={true}
                      primary={true}
                      disable={loader || otp.length == 0}
                      name='Sign In'
                      // onClick={() => login()}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// const mapStateToProps = ({ tracker = {}, post = {} }) => {
//   const createpostLoading = _.get(post, "createpostLoading", false);
//   const editpostloading = _.get(post, "editpostloading", false);
//   return { createpostLoading, editpostloading };
// };

// const mapDispatchToProps = (dispatch) => ({
//   createPost: (data) => dispatch({ type: "CreatePostCalled", payload: data }),
//   editPost: (data) => dispatch({ type: "EditPostCalled", payload: data }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Signin);
