import React, { Fragment } from 'react';
import './InputFieldStyles.css';
import '@themes/CommonApplicationStyles.css';
import IconPassword from '@images/IconPassword.png';
import Hide from '@images/hide.png';
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { findNonSerializableValue } from '@reduxjs/toolkit';
import { VscSearch } from 'react-icons/vsc';
const InputField = ({
  eye,
  showeye,
  setshow,
  isInvalid,
  required,
  value,
  type,
  placeholder,
  error,
  phone,
  minlength,
  onChange,
  serachicon,
  getProducts,
  comment,
  label,
  disabled,
  isfile,
}) => {
  return (
    <Fragment>
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        {/* {(label == 'Amount' || label == 'Price') && (
          <p className='amountdollar'>$</p>
        )} */}
        {isfile && (
          <Form.File id='formcheck-api-regular'>
            <Form.File.Input onChange={onChange} />
          </Form.File>
        )}
        {!phone && !comment && !isfile && (
          <Form.Control
            className='input '
            value={value}
            autoCorrect={false}
            type={type}
            maxLength={minlength}
            required={required}
            isInvalid={isInvalid}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        {eye && (
          <img
            onClick={setshow}
            src={showeye ? Hide : IconPassword}
            // style={styles.passwordIcon}
            className='passwordIcon'
            alt='DripPretty'
          />
        )}
        {serachicon && (
          <span className='searchicon'>
            <VscSearch onClick={getProducts} />
          </span>
        )}
        {phone && (
          <div className='phoneinput form-group'>
            <Form.Label>Mobile</Form.Label>
            <PhoneInput
              inputProps={{
                required: true,
              }}
              placeholder=''
              enableSearch
              specialLabel='Mobile'
              country={'in'}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        )}
        {comment && (
          <Form.Control
            className='commentinput '
            value={value}
            autoCorrect={false}
            type={type}
            maxLength={minlength}
            required={required}
            isInvalid={isInvalid}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}

        <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
      </Form.Group>
    </Fragment>
  );
};

export default InputField;
