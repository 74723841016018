import React, { useEffect, useRef, useState } from 'react';
import camera from '@images/camera.png';
import edit from '@images/edit.png';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';

import '@themes/CommonApplicationStyles.css';
import '@components/dpround/DProundStyles.css';
import defaultdp from '@images/defaultdp.jpg';
import imageedit from '@images/imageedit.png';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

function DPround({ page, loader, setLoader, profileImage }) {
  const fileInputRef = useRef();
  const history = useHistory();
  const fileInputRef2 = useRef();

  const updateProfileImage = async (i) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('image', i);
    const token = localStorage.getItem('accessToken');
    await axios
      .patch(`${URL.API}/user/profileImage`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        history.push({ pathname: '/portfolio' });
      })
      .catch((error) => {
        console.log(error.response);
      });
    setLoader(false);
  };
  return (
    <div className='dpround'>
      <div
        className={
          page == 'small' ? 'profilecircle profilecircle2' : 'profilecircle'
        }
      >
        {profileImage && <img src={profileImage} />}
        {!profileImage && <img src={defaultdp} alt='image' />}

        {/*  <div className="editcamera">
         <div className="cameracircle">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => fileInputRef.current.click()}
            >
              <img src={camera} className="editcamera pen"></img>
            </span>
            <input
              ref={fileInputRef}
              accept="image/*"
              type="file"
              hidden
              onChange={(e) => updateProfileImage(e.target.files[0])}
            />
          </div> 
        </div>*/}
      </div>
      {page == 'myportfolio' && (
        <div className='imageedtiIcon'>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => fileInputRef.current.click()}
          >
            <img src={imageedit} />
          </span>
          <input
            ref={fileInputRef}
            accept='image/*'
            type='file'
            hidden
            onChange={(e) => updateProfileImage(e.target.files[0])}
          />
        </div>
      )}
      {/* <div className="userbio">
        <div className="username">
          {userInfo && userInfo.userName}
          <img
            src={edit}
            className="edit"
            onClick={() => {
              openModal(), seteditType("username");
            }}
          ></img>
        </div> 
        <div className="title" style={others?{minHeight:30}:{}}>
          {userInfo && userInfo.userprofile && userInfo.userprofile.title}{" "}
          {userInfo && userInfo.userprofile && userInfo.userprofile.location}
          <img
            src={edit}
            className="edit"
            onClick={() => {
              openModal(), seteditType("others");
            }}
          ></img>
        </div>
      </div>*/}

      {/* <div className="editbanner">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => fileInputRef2.current.click()}
        >
          <img src={camera}></img><span className="cameratext"> Edit cover photo</span>
        </span>
        <input
          ref={fileInputRef2}
          accept="image/*"
          type="file"
          hidden
          onChange={(e) => updateBannerImage(e.target.files[0])}
        />
      </div> */}
    </div>
  );
}

export default DPround;
