import React, { useEffect, useState } from "react";
import { Row, Col, Card, Nav, Dropdown, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as _ from "lodash";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

import "@components/upload-message/uploadmessageStyle.css";

function EditUploadMessage({
  editpostloading,
  editpoststatus,
  edituploadStatus,
}) {
  const [timeout, settimeot] = useState(false);
  const [uploading, setuploading] = useState(false);

  return (
    <span>
     
      {(
        editpoststatus != "successful" && editpostloading) && (
        <div className="uploadtext">
          <AiOutlineCloudUpload size={"30px"} />
        </div>
      )}

<div className="timer">


      {!editpostloading?
        setTimeout(() => edituploadStatus("initial"), 1000):<span></span>}

</div>

      {editpoststatus === "successful" && (
        <div className="uploadtext">
          <IoCheckmarkCircleSharp size={"30px"} />
        </div>
      )}
    </span>
  );
}

const mapStateToProps = ({ tracker = {}, user = {}, post = {} }) => {

  const editpostloading = _.get(post, "editpostloading", false);
  const editpoststatus = _.get(post, "editpoststatus", "");
  return { editpostloading, editpoststatus };
};

const mapDispatchToProps = (dispatch) => ({
    edituploadStatus: (data) =>
    dispatch({ type: "edituploadStatusCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUploadMessage);
