import {createSlice} from '@reduxjs/toolkit';
import * as _ from 'lodash';

const userslice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    signInLoading:false,
    signInError:'',
    signUpLoading:false,
    signUpError:'',
    forgotPasswordLoading: false,
    forgotPasswordError: '',
    auth:false,
    profileloading:false,
    profileerror:false,
    forgetdone:false,
    editUserLoading: false,
    editUserError: '',
    updateProfileImageError: '',
    updateBannerImageError: '' ,
    myFollowingId: null,
    myBlockListId: [],
    otpverifyloading:false,
    otpverifyerror:'',
    myLikesId: [],
  },
  reducers: {
     loginSuccess: (state, action) => {
      return{
        userInfo : action.payload,auth:true,signInError:''
      }
    },
    loginTrigger: (state, action) => {
      return {
      ...state, signInLoading: action.payload,
      }
    },
    loginFail: (state, action) => {
      return{
        signInError : action.payload
      }
    },
    logoutSuccess: (state, action) => {
      return {userInfo : null}
    },
    signUpTrigger: (state, action) => {
      return {
        ...state,    signUpLoading: action.payload,
      }
    },
    // signupFulfill: (state, action) => {
    //   console.log(signUpLoading, 'SignupFulfill called')
    //   return {  signUpLoading: action.payload }
    // },
    signupSuccess: (state, action) => {
      return{
        userInfo: action.payload,signInError:''
      }
    },
    signupFail: (state, action) => {
      return{
        signUpError : action.payload
      }
    },
    forgotPasswordSuccess: (state, action) => {
      return{
    forgetdone:true,
    userInfo: action.payload,
      }
    },
    forgotdone: (state, action) => {
      return{
    forgetdone:false,
       }
    },
    forgotPasswordTrigger: (state, action) => {
      return{
        forgotPasswordLoading: action.payload , ...state
      }
    },
    forgotPasswordFail: (state, action) => {
      return{
        forgotPasswordError: action.payload
      }
    },  
    otpverifyTrigger: (state, action) => {
      return {
        ...state,
        otpverifyloading: action.payload,
      };
    },
    otpverifyFail: (state, action) => {
      return{
        otpverifyerror : action.payload
      }
    },
    profileSuccess: (state, action) => {
      // console.log('profileSUccess', action.payload)
      return{
        ...state,
        userInfo: {...state.userInfo,...action.payload}
      }
    }, 
    profileTrigger: (state, action) => {
      return {
        ...state,
        profileloading: action.payload,
      }
    },
    profileFail: (state, action) => {
      return{
        ...state,
        profileerror: action.payload
      }
    },
    editUserTrigger: (state, action) => {
      return{
        ...state,
        editUserLoading: action.payload
      }
    },
    editUserSuccess: (state, action) => {
      // console.log('EditUserSuccess: ', action.payload)
      return{
        ...state,
        userInfo: {...state.userInfo, ...action.payload}
      }
    },  
    editUserFail: (state, action) => {
      return{
        ...state,
        editUserError: action.payload
      }
    },
    updateProfileImageSuccess: (state, action) => {
      return{
        ...state,
        userInfo: {...state.userInfo, ...action.payload}
      }
    },
    updateProfileImageTrigger: (state, action) => {
      return{
        ...state,
        editUserLoading: action.payload
      }
    },
    updateBannerImageSuccess: (state, action) => {
      return{
        ...state,
        userInfo: {...state.userInfo, ...action.payload}
      }
    },
    updateBannerImageTrigger: (state, action) => {
      return{
        ...state,
        editUserLoading: action.payload
      }
    },
    myFollowingIdSuccess: (state, action) => {
      // console.log('My Following Id: ', action.payload)
      return {
        ...state,
        myFollowingId: action.payload,
      };
    },
    followTrigger: (state, action) => {
       console.log('My Following Id: ', action.payload)
      return {
        ...state,
        myFollowingId: [...state.myFollowingId, action.payload],
      };
    },
    BlockTrigger: (state, action) => {
      // console.log('My Following Id: ', action.payload)
      return {
        ...state,
      myBlockListId: [...state.myBlockListId, {"blockedId":action.payload}],
      };
    },
    unBlockTrigger: (state, action) => {
      let newids = _.reject(state.myBlockListId, {"blockedId": action.payload});
      return {
        ...state,
        myBlockListId:newids
      };
    },
    blockSuccess: (state, action) => {
      // console.log('My Following Id: ', action.payload)
      return {
        ...state,
        myBlockListId:  action.payload,
      };
    },
    unfollowTrigger: (state, action) => {
      let newids = _.reject(state.myFollowingId, {"targetId": action.payload});

       // console.log('My Following Id: ', action.payload)
      return {
        ...state,
        myFollowingId: newids,
      };
    },
    logoutSuccess: (state, action) => {
      return {};
    },
    likesmyId: (state,action) => {
      return {
           ...state,
           myLikesId:  action.payload,
         };
       },
    likesIdSuccess: (state, action) => {
      let isLiked = _.filter(state.myLikesId, ['postId', action.payload.id]);
      console.log(isLiked,"s",state.myLikesId)
        if(!_.isEmpty(isLiked)) {
          return {
            ...state,
            myLikesId:_.reject(state.myLikesId, {"postId": action.payload.id})
          };
         
        }else{
          if(state.myLikesId&&state.myLikesId.length==0)
          return{...state,myLikesId:[{"postId":action.payload.id}]}
        return {
          ...state,
          myLikesId: [...state.myLikesId, {"postId":action.payload.id}],
        };
      }
      },
  },
});
export default userslice.reducer;
 
export const { 
loginSuccess,
logoutSuccess,
loginFail, 
signupSuccess, 
signupFail , 
forgotPasswordSuccess, 
forgotPasswordFail,
profileSuccess,
forgotdone,
profileFail,
signUpTrigger,
signupFulfill,
loginTrigger,
forgotPasswordTrigger,
profileTrigger,
editUserTrigger,
editUserSuccess,
editUserFail,
updateProfileImageSuccess,
updateProfileImageTrigger,
updateBannerImageSuccess,
updateBannerImageTrigger,
myFollowingIdSuccess,
followTrigger,
unfollowTrigger,
blockSuccess,
BlockTrigger,
unBlockTrigger,
otpverifyTrigger,
otpverifyFail,
likesIdSuccess,
likesmyId,
} = userslice.actions;
 
