import React, { Fragment } from "react";
import "./CustomButtonStyles.css";
import * as _ from 'lodash';

const CustomButton = ({
  type,
  filled,
  primary,
  name,
  navigation,
  disable,
  onClick,
  cstyle,
  follow,
  isFollowing,
}) => {
  return (
    <Fragment>
      {type === "Follow" &&  _.isEmpty(isFollowing) ? (
        <Fragment>
          <button
            disabled={disable}
            type={type}
            onClick={onClick}
            className="buttonfollow"
          >
            {name}
          </button>
        </Fragment>
      ) : type === "Follow" ? (
        <Fragment>
          <button
            disabled={disable}
            type={type}
            onClick={onClick}
            className="buttonunfollow "
          >
            Unfollow
          </button>
        </Fragment>
      ) : filled && primary ? (
        <Fragment>
          <button
          style={cstyle}
            disabled={disable}
            onClick={onClick}
            type={type}
            className=" button1 buttonmain"
          >
            {name}
          </button>{" "}
        </Fragment>
      ) : filled && !primary ? (
        <Fragment>
          <button
            disabled={disable}
            onClick={onClick}
            type={type}
            className=" button2  buttonmain"
          >
            {name}
          </button>
        </Fragment>
      ) : (
        <button
          disabled={disable}
          onClick={onClick}
          type={type}
          // variant="primary"
          className=" button3 buttonText2"
        >
          {name}
        </button>
      )}
    </Fragment>
  );
};

export default CustomButton;
