import CustomButton from "@components/custom-button/CustomButton";
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputField from "@components/input-field/InputField";
import cryptoRandomString from "crypto-random-string";
import "@features/editnft/editnftStyles.css";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import "@themes/CommonApplicationStyles.css";
import { Row, Col, Spinner, Form } from "react-bootstrap";
import NavBar from "@navigation/NavBar";
import { connect } from "react-redux";
const sdk = require("dragonchain-sdk");
import * as _ from "lodash";

export default function Buynft() {
  const history = useHistory();
  const [validated, setValidate] = useState(false);

  const { productInfo, page } =
    (history && history.location && history.location.state) || {};
  const { nft, image, nftDetails } = productInfo || {};
  const { nftid, name, note, type, ownerid } = nft || {};
  const { amount, description, userid, discount } = nftDetails || {};
  const [buyerid, setBuyerid] = useState("");
  const [nftname, setNftname] = useState(name);
  const [typee, setType] = useState(type);
  const [desc, setDesc] = useState(description);
  const [price, setPrice] = useState(amount);
  const [discountPerc, setDiscount] = useState(discount);

  const [fileName, setFileName] = useState("");

  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);

  console.log("INFO", productInfo);
  const buynft = async (event) => {
    console.log("3")
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      setLoader(true);


      let client = await sdk.createClient({
        dragonchainId: 'uUYEn4SQWrAi13UnHpgMoUyrGw1eF9H65dWnEtiZGmbP',
        authKey: 'Cl0T8ydDJUt29QLtUN7GcE39PAGrmASu2kzdj9nZBjs',
        authKeyId: 'VYCJCYLHYOHH',
        endpoint:
          'https://98d256d8-e143-40a5-a3bd-c6c5fc19c2f8.api.dragonchain.com',
      });

      let uuid = cryptoRandomString({ length: 20 });
     let buyerid = localStorage.getItem('userid');

     console.log("1")
      const createResult = await client.createTransaction({
        transactionType: 'Nftcontract',
        payload: {
          uuid: uuid,
          method: 'Buynft',
          parameters: {     
            nftid: nftid,
            buyerid:buyerid,
            sellerid:ownerid,
            amount: price,
            
          },
        },
      });
      console.log("2")

      let uuid1 = cryptoRandomString({ length: 20 });

      const editResult = await client.createTransaction({
        transactionType: 'Nftcontract',
        payload: {
          uuid: uuid1,
          method: 'Editnft',
          parameters: {
            description: desc,
            userid: ownerid,
            nftid: nftid,
            amount: 0,
            discount: 0,
            ownerid:buyerid,
            onsale:"false"

          },
        },
      });

      console.log("hhhh")

      setLoader(false);
      history.push('/explore');
    }
  };

  return (
    <div id="createpost">
      <NavBar location={useLocation()} />
      <Container style={{ marginTop: "50px" }}>
        <Row className="justify-content-md-center">
          <Col lg={8}>
            <h3 className="mb-4 text-center">BUY NFT</h3>
            <Form noValidate validated={validated} onSubmit={buynft}>
              <InputField
                type="text"
                value={nftname}
                placeholder="Nft Name"
                label="NFT Name"
                disabled="true"
                //onChange={(e) => setNftname(e.target.value)}
              />
              <InputField
                type="text"
                value={typee}
                placeholder="Type"
                label="Type"
                disabled="true"
                onChange={(e) => setType(e.target.value)}
              />
              <InputField
                type="text"
                value={desc}
                placeholder="Description"
                label="Description"
                onChange={(e) => setDesc(e.target.value)}
                disabled="true"
              />
              <InputField
                type="number"
                value={price}
                placeholder="Price"
                label="Buying at"
                onChange={(e) => setPrice(e.target.value)}
                disabled="true"
              />
              <InputField
                type="number"
                value={discountPerc}
                placeholder="Discount"
                label="Discount"
                onChange={(e) => setDiscount(e.target.value)}
                disabled
              />

              <Form.Group>
                {image !== "no image" && fileName == "" && (
                  <div className="nft-img-thumbnail">
                    <img alt="" src={image} />
                  </div>
                )}
              </Form.Group>

              <Col sm="4" className="mb-3">
                {loader && <Spinner animation="border" variant="primary" />}
              </Col>
              <Row className="justify-content-md-center">
                <Col lg={6} className="mt-2 desktopbtn">
                  <CustomButton
                    type="Post"
                    filled={true}
                    primary={true}
                    name="Buy NFT"
                    disable={loader}
                
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
