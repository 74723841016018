import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import NavBar from '@navigation/NavBar';
import Tab from '@components/tab/Tab.js';
import MyItems from '@components/profilelists/MyItems';
import DPround from '@components/dpround/DPround';
import OnSale from '@components/profilelists/OnSale';
import { Row, Col, Card, Nav, Button, Spinner } from 'react-bootstrap';
import Created from '@components/profilelists/Created';
import Likes from '@components/profilelists/LIkes';
import Activity from '@components/profilelists/Activity';
import Creator from '@components/portfolioothersnfttabs/Creator';
import CreatorandOwner from '@components/portfolioothersnfttabs/CreatorandOwner';
import Owner from '@components/portfolioothersnfttabs/Owner';
//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');
function Othersprofile({ history }) {
  const [insidekey, setinsidekey] = useState(13);
  const [loader, setLoader] = useState(false);
  const [otherUser, setOtherUser] = useState(null);
  const [key, setkey] = useState('1');
  useEffect(async () => {
    setLoader(true);
    const token = localStorage.getItem('accessToken');
    const userid = localStorage.getItem('other-user-id');
    await axios
      .get(`${URL.API}/user/othersprofile/${userid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        setOtherUser(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoader(false);
  }, []);
  var profileImage;
  otherUser &&
    otherUser.profileImage &&
    otherUser.profileImage.profileimage &&
    otherUser.profileImage.profileimage.map((img) => {
      if (img.size == 'thumbnail') {
        profileImage = img.link;
      }
    });
  return (
    <div>
      <NavBar location={useLocation()} />

      <Container style={{ marginTop: '20px' }}>
        <DPround profileImage={profileImage} />
        <Col style={{ textAlign: 'center' }}>
          {loader && <Spinner animation='border' variant='primary' />}
        </Col>
        <Col>
          <p style={{ textAlign: 'center', paddingTop: '15px' }}>
            {localStorage.getItem('other-user-name')}
          </p>
          <p style={{ textAlign: 'center' }}>
            {localStorage.getItem('other-user-id')}
          </p>
        </Col>
        <div className='content mt-4'>
          {/* <Tab
            page='profile'
            onSelect={(res) => setkey(res)}
            history={history}
          /> */}

          {key == 1 && (
            <Tab
              page='othersportfolio'
              onSelect={(res) => setinsidekey(res)}
              history={history}
            />
          )}

          {insidekey == 13 && key == 1 && <Creator />}

          {insidekey == 14 && key == 1 && <CreatorandOwner />}

          {insidekey == 15 && key == 1 && <Owner />}

          {/* {key == 3 && <Created />}  */}
          {/* {key == 4 && <Activity />} */}
        </div>
      </Container>
    </div>
  );
}

export default Othersprofile;
