import {call, put} from 'redux-saga/effects';
import {changeScreenSuccess, changeFormSuccess} from './screentrackerredux';
import Api from './../services/ApiCaller';

export function* changescreen(api, {payload = {}}) {
    yield put(changeScreenSuccess(payload));
}
export function* changeform(api, {payload = {}}) {
    yield put(changeFormSuccess(payload));
}

 
 