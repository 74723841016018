import Hide from "@images/hide.png";
import IconPassword from "@images/IconPassword.png";
import "@themes/CommonApplicationStyles.css";
import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "./SearchbarStyles.css";

const Searchbar = ({
  eye,
  showeye,
  setshow,
  isInvalid,
  required,
  disabled,
  value,
  type,
  placeholder,
  error,
  phone,
  minlength,
  onChange,
  onFocus,
  onBlur,
  as,
  rows
}) => {
  return (
    <Fragment>
      <Form.Group>
        {!phone && (
          <Form.Control
          as={as}
          rows={rows}
            className="input2 "
            value={value}
            autoCorrect={false}
            type={type}
            maxLength={minlength}
            required={required}
            isInvalid={isInvalid}
            onChange={onChange}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
          />
        )}
        {eye && (
          <img
            onClick={setshow}
            src={showeye ? Hide : IconPassword}
            // style={styles.passwordIcon}
            className="passwordIcon"
            alt="DripPretty"
          />
        )}
        {phone && (
          <div className="phoneinput form-group">
            <PhoneInput
              inputProps={{
                required: true,
              }}
              placeholder=""
              enableSearch
              specialLabel=""
              country={"in"}
              value={value}
              onChange={onChange}
            />
          </div>
        )}
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
    </Fragment>
  );
};

export default Searchbar;
