import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Card, Nav, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import Tab from "@components/tab/Tab.js";
import Loader from "@components/loader/Loader";
import ProductCard from "@components/product-card/ProductCard";
import NavBar from "@navigation/NavBar";
import "@themes/CommonApplicationStyles.css";
import "@features/explore/ExploreStyles.css";
import { useHistory } from "react-router-dom";
import { result } from "lodash";
import { URL } from "@utilities/Constants";
 
const axios = require("axios");

const sdk = require("dragonchain-sdk");
function Searchnft({}) {
  const history = useHistory();
  const [key, setkey] = useState("1");
  const [list, setlist] = useState([]);
  const [loader, setLoader] = useState(false);
  const { data } =
    (history && history.location && history.location.state) || {};

  useEffect(async () => {
    setLoader(true);

    let j = 0;
    let nftlists = [];
    const token = localStorage.getItem('accessToken');

    axios
      .get(`${URL.API}/nft/filter?search="${data}"&skip=0&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response)
        //localStorage.setItem("recent_nft", response.data.finalList);
        setlist(response.data.finalList);
        setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <div>
            <NavBar location={useLocation()} />

       <Container style={{ marginTop: "20px" }}>
        <div className="content">
          <h3 className="mb-4 text-center">Search Results</h3>

          <Col style={{ textAlign: "center" }}>
            {loader && <Spinner animation="border" variant="primary" />}
          </Col>

          <Row>
            {list.map((item, index) => (
              <Col lg={4} sm={6}>
                <ProductCard key={index} productInfo={item} myprofile={false} />
              </Col>
            ))}
          </Row>

          {/* <Row>
            {list.map((item, index) => (
              <Col lg={4} sm={6}>
                <ProductCard key={index} productInfo={item} myprofile={true} />
              </Col>
            ))}
          </Row> */}
        </div>
      </Container>
    </div>
  );
}

export default Searchnft;

// var nftDetails = nftResult.response.results.reduce(
//   (latest, current) => {
//     return latest > current ? latest : current;
//   },
//   nftResult.response.results[0].payload.timestamp
// );
