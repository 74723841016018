import CustomButton from '@components/custom-button/CustomButton';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputField from '@components/input-field/InputField';
import cryptoRandomString from 'crypto-random-string';
import '@features/editnft/editnftStyles.css';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import '@themes/CommonApplicationStyles.css';
import { Row, Col, Spinner, Form } from 'react-bootstrap';
import NavBar from '@navigation/NavBar';
import { connect } from 'react-redux';
const sdk = require('dragonchain-sdk');
import * as _ from 'lodash';
import pdficon from '@images/file.png';
//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

//
import { CgCloseO } from 'react-icons/cg';

export default function Editnft() {
  const history = useHistory();
  const [validated, setValidate] = useState(false);

  const { productInfo, page } =
    (history && history.location && history.location.state) || {};
  const { nft, image, detail, document } = productInfo || {};
  const { nftid, name, type } = nft || {};
  const { amount, description, userid, discount, ownerid, onsale, note } =
    detail || {};
  const [nftname, setNftname] = useState(name) || {};
  const [notee, setNote] = useState(note) || {};
  const [typee, setType] = useState(type) || {};
  const [activate, setactivate] = useState(false);
  const [desc, setDesc] = useState(description) || {};
  const [price, setPrice] = useState(amount) || {};
  const [discountPerc, setDiscount] = useState(discount) || {};
  const [files, setfiles] = useState([]);

  // var oldFiles = [];
  // image &&
  //   image.filename &&
  //   image.filename.map(
  //     (item) =>
  //       item &&
  //       item.map((val) => {
  //         if (val.size == 'thumbnail') {
  //           oldImages.push(val.link);
  //         }
  //       })
  //   );
  var docx;
  if (document.length == 0) {
    docx = [];
  } else {
    docx = document && document.filename;
  }
  const [filesServer, setfilesServer] = useState(docx) || [];
  var oldImages = [];
  image &&
    image.filename &&
    image.filename.map(
      (item) =>
        item &&
        item.map((val) => {
          if (val.size == 'thumbnail') {
            oldImages.push(val);
          }
        })
    );
  const [fileName, setFileName] = useState([]);
  const [imageServer, setImageServer] = useState(oldImages);
  const [imagePreview, setimagePreview] = useState([]);
  const [deleteids, setdeleteids] = useState([]);
  const [deletefileids, setdeletefileids] = useState([]);

  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  useEffect(() => {
    fileName &&
      fileName.map((image) => {
        let reader = new FileReader();
        let file = image;
        reader.onloadend = () => {
          setimagePreview([...imagePreview, reader.result]);
        };
        reader.readAsDataURL(file);
      });
  }, []);
  const editnft = async (event) => {
    const form = event.currentTarget;
    setactivate(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      const token = localStorage.getItem('accessToken');
      const formData = new FormData();
      for (let i = 0; i < fileName.length; i++) {
        formData.append('images', fileName[i]);
      }
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
      }
      formData.append('description', desc);
      formData.append('amount', price);
      formData.append('note', notee);
      console.log('ids:', `[${deleteids}]`);

      await axios
        .post(
          `${URL.API}/nfts/editnft/${nftid}?fileIds=[${deleteids}]&docIds=[${deletefileids}]`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log('Res:', response);
          history.push({ pathname: '/portfolio' });
        })
        .catch((error) => {
          console.log(error.response);
        });
      setactivate(false);
      setLoader(false);
    }
  };
  //
  const ImageRemove = (id) => {
    let newbanner = [];
    let newPreview = [];
    if (fileName.length <= 5) {
      fileName.map((item, index) => {
        if (index != id) {
          newbanner = [...newbanner, item];
        }
      });
      setFileName(newbanner);
    }
    if (fileName.length <= 5) {
      imagePreview.map((item, index) => {
        if (index != id) {
          newPreview = [...newPreview, item];
        }
      });

      setimagePreview(newPreview);
      setFileName(newbanner);
    }
  };
  const ImageRemove2 = (id) => {
    let newbanner = [];
    let newPreview = [];
    if (files.length <= 2) {
      files.map((item, index) => {
        if (index != id) {
          newbanner = [...newbanner, item];
        }
      });
      setfiles(newbanner);
    }
  };

  const removeonline = (id) => {
    let newServer = [];
    let deleteServerids = [];
    if (imageServer && imageServer.length <= 5) {
      imageServer.map((item, index) => {
        if (index != id) {
          newServer = [...newServer, item];
        }
        if (index == id) {
          deleteServerids = [...deleteids, `"${item && item.id}"`];
        }
      });
      setdeleteids(deleteServerids);
      setImageServer(newServer);
    }
  };
  const removeonline2 = (id) => {
    let newServer = [];
    let deleteServerids = [];
    if (filesServer && filesServer.length <= 2) {
      filesServer &&
        filesServer.map((item, index) => {
          if (index != id) {
            newServer = [...newServer, item];
          }
          if (index == id) {
            deleteServerids = [...deletefileids, `"${item && item.id}"`];
          }
        });
      setdeletefileids(deleteServerids);
      setfilesServer(newServer);
    }
  };
  return (
    <div id='createpost'>
      <NavBar location={useLocation()} />
      <Container style={{ marginTop: '50px' }}>
        <Row className='justify-content-md-center'>
          <Col lg={8}>
            <h3 className='mb-4 text-center'>Edit NFT</h3>
            <Form noValidate validated={validated} onSubmit={editnft}>
              <InputField
                type='text'
                value={nftname}
                placeholder='Enter NFT Name'
                label='NFT Name'
                disabled='true'
                //onChange={(e) => setNftname(e.target.value)}
              />
              <label>Note</label>
              <textarea
                style={{
                  width: '100%',
                  height: '100px',
                  fontSize: '12px',
                  fontWeight: '600px',
                  borderWidth: '0px 0px 2px 0px',
                  borderColor: '#f1f1f1',
                  color: '#999999',
                  outline: 'none',
                  textIndent: '8px',
                }}
                type='text'
                value={notee}
                placeholder='Enter Note'
                onChange={(e) => setNote(e.target.value)}
                required
                error='Please Enter Note'
              />
              <InputField
                type='text'
                value={typee}
                placeholder='Type'
                label='Type'
                disabled='true'
                onChange={(e) => setType(e.target.value)}
              />
              {/* <InputField
                type='text'
                value={desc}
                placeholder='Description'
                label='Description'
                onChange={(e) => setDesc(e.target.value)}
                required
                error='Please Enter Description'
              /> */}
              <label>Description</label>
              <textarea
                style={{
                  width: '100%',
                  height: '100px',
                  fontSize: '12px',
                  fontWeight: '600px',
                  borderWidth: '0px 0px 2px 0px',
                  borderColor: '#f1f1f1',
                  color: '#999999',
                  outline: 'none',
                  textIndent: '8px',
                }}
                type='text'
                value={desc}
                placeholder='Enter Description'
                onChange={(e) => setDesc(e.target.value)}
                required
                error='Please Enter Description'
              />
              <InputField
                type='number'
                value={price}
                placeholder='Price'
                label='Price'
                onChange={(e) => setPrice(e.target.value)}
                required
                error='Please Enter Price'
              />
              {/* <InputField
                type='number'
                value={discountPerc}
                placeholder='Discount'
                label='Discount'
                onChange={(e) => setDiscount(e.target.value)}
                required
                error='Please Enter Discount'
              /> */}

              <Form.Group>
                <Form.File
                  id='ControlFile1'
                  label='Upload Image'
                  onChange={(e) => {
                    setFileName([...fileName, e.target.files[0]]);
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.onloadend = () => {
                      setimagePreview([...imagePreview, reader.result]);
                    };
                    reader.readAsDataURL(file);
                  }}
                  disabled={
                    fileName.length + imageServer.length < 5 ? false : true
                  }
                  // required
                  // error="Please Upload Certificate File"
                />
                {activate && fileName.length == 0 && (
                  <Form.Control.Feedback
                    type='invalid'
                    style={{ color: 'red', fontSize: 16 }}
                    className='my-3'
                  >
                    Please choose an Image
                  </Form.Control.Feedback>
                )}
                {/* {image !== 'no image' && fileName == '' && (
                  <div className='nft-img-thumbnail'>
                    <img
                      alt=''
                      src={`data:image/png;base64,${image.filename[0]}`}
                    />
                  </div>
                )} */}
              </Form.Group>

              <Col>
                <div className='d-flex' style={{ width: 300 }}>
                  {imageServer &&
                    imageServer.map((im, id) => {
                      return (
                        <div className='attachimage'>
                          <img
                            src={`${im && im.link}`}
                            alt='image'
                            style={{ marginTop: 20, marginLeft: 20 }}
                            height={50}
                            width={50}
                            className='attachment'
                          />
                          <CgCloseO
                            size={'20px'}
                            onClick={() => {
                              removeonline(id);
                            }}
                            // style={{ position: 'absolute', top: 20 }}
                            className='imageabsolute'
                          />
                        </div>
                      );
                    })}
                  {imagePreview &&
                    imagePreview.map((im, id) => {
                      return (
                        <div className='attachimage'>
                          <img
                            src={im}
                            alt='image'
                            style={{ marginTop: 20 }}
                            className='attachment'
                          />
                          <CgCloseO
                            size={'20px'}
                            onClick={() => {
                              ImageRemove(id);
                            }}
                            // style={{ position: 'absolute', top: 20 }}
                            className='imageabsolute'
                          />
                        </div>
                      );
                    })}
                </div>
              </Col>

              <Form.Group>
                <Form.File
                  id='ControlFile1'
                  label='Upload PDFs'
                  onChange={(e) => {
                    setfiles([...files, e.target.files[0]]);
                  }}
                  disabled={
                    ('tttt:',
                    (files && files.length) +
                      (filesServer && filesServer.length) <
                    2
                      ? false
                      : true)
                  }
                />
              </Form.Group>
              <Col>
                <div className='d-flex' style={{ width: 300 }}>
                  {files &&
                    files.map((im, id) => {
                      return (
                        <div className='attachimage'>
                          <img
                            src={pdficon}
                            alt='image'
                            style={{ marginTop: 20 }}
                            height={50}
                            width={50}
                            className='attachment'
                          />
                          <CgCloseO
                            size={'20px'}
                            onClick={() => {
                              ImageRemove2(id);
                            }}
                            // style={{ position: 'absolute', top: 20 }}
                            className='imageabsolute'
                          />
                          <h6>{im && im.name}</h6>
                        </div>
                      );
                    })}
                  {filesServer &&
                    filesServer.map((im, id) => {
                      return (
                        <div className='attachimage'>
                          <img
                            src={pdficon}
                            alt='image'
                            style={{ marginTop: 20 }}
                            height={50}
                            width={50}
                            className='attachment'
                          />
                          <CgCloseO
                            size={'20px'}
                            onClick={() => {
                              removeonline2(id);
                            }}
                            // style={{ position: 'absolute', top: 20 }}
                            className='imageabsolute'
                          />
                          <h6>{im && im.filename}</h6>
                        </div>
                      );
                    })}
                </div>
              </Col>
              {/* <InputField
              type='text'
              value={file}
              placeholder='File'
              label='Add Images'
              onChange={(e) => setFile(e.target.files[0])}
              disabled={false}
              isfile={true}
            /> */}
              <Col style={{ textAlign: 'center' }}>
                {loader && <Spinner animation='border' variant='primary' />}
              </Col>

              <Row className='justify-content-md-center'>
                <Col lg={6} className='mt-2 '>
                  <CustomButton
                    type='Post'
                    filled={true}
                    primary={true}
                    name='Save'
                    disable={
                      loader || fileName.length + imageServer.length == 0
                    }

                    // onClick={() => {
                    //   editnft();
                    // }}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// const mapStateToProps = ({ tracker = {}, post = {} }) => {
//   const createpostLoading = _.get(post, 'createpostLoading', false);
//   const editpostloading = _.get(post, 'editpostloading', false);
//   return { createpostLoading, editpostloading };
// };

// const mapDispatchToProps = (dispatch) => ({
//   createPost: (data) => dispatch({ type: 'CreatePostCalled', payload: data }),
//   editPost: (data) => dispatch({ type: 'EditPostCalled', payload: data }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Editnft);
