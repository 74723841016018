import { SCREEN_KEYS } from "@utilities/Constants";
import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import {
  likePostTrigger,
  myFeedSuccess,
  myFeedTrigger,
  singleProfileTrigger,
} from "./othersredux";
import {
  createDraftSuccess,
  createPostFail,
  createPostSuccess,
  createPostTrigger,
  deletecommentFail,
  deletecommentSuccess,
  deletecommentTrigger,
  deletepostdraftSuccess,
  deletepostFail,
  deletepostSuccess,
  deletepostTrigger,
  editDraftSuccess,
  editPostFail,
  editPostSuccess,
  editPostTrigger,
  edituploadStatusSuccess,
  getCommentcount,
  getCommentReplySuccess,
  getcommentSuccess,
  getreplycount,
  likeMyPostTrigger,
  likeProfilePostTrigger,
  mydraftPostFail,
  mydraftpostSuccess,
  myPostFail,
  mypostSuccess,
  postcommentCollapseTrigger,
  postcommentSuccess,
  postcommentTrigger,
  postsLikesSuccess,
  replyPostcommentSuccess,
  replyPostcommentTrigger,
  reportpostTrigger,
  SinglepostFail,
  SinglepostSuccess,
  SinglepostTrigger,
  singleProfilePostsSuccess,
  uploadStatusSuccess,
  sharePostTrigger,
  sharePostFail,
  sharePostSuccess,
  singlePostbyidTrigger,
  singlePostbyidFail,
  singlePostbyidSuccess,
} from "./postredux";
import { changeScreenSuccess } from "./screentrackerredux";
import { editUserTrigger, likesIdSuccess } from "./userredux";
import { getAccessToken } from "./utilities";

export function* getMyPost(api, { payload = {} }) {
  yield put(editUserTrigger(true));
  try {
    console.log("Getting My post");
    const token = yield getAccessToken();
    const result = yield call(Api.callServer, api.getMyPost, { token }, true);
    yield put(mypostSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(myPostFail(error));
  } finally {
    yield put(editUserTrigger(false));
  }
}

export function* createPost(api, { payload = {} }) {
  yield put(createPostTrigger(true));
  yield put(myFeedTrigger(true));
  yield put(editUserTrigger(true));
  try {
    const { navigation, description, imageserver, privacy } = payload;
    const token = yield getAccessToken();
    if (privacy == "draft") {
      const result = yield call(
        Api.callServer,
        api.createPost,
        {
          description,
          imageserver,
          token,
          privacy: "draft",
        },
        true
      );
      yield put(createDraftSuccess(result));
    } else {
      const result = yield call(
        Api.callServer,
        api.createPost,
        {
          description,
          imageserver,
          token,
          privacy: "public",
        },
        true
      );
      yield put(createPostSuccess(result));
      const result2 = yield call(
        Api.callServer,
        api.getMyPost,
        { token },
        true
      );
      const result3 = yield call(
        Api.callServer,
        api.getMyFeed,
        { token },
        true
      );

      yield put(mypostSuccess(result2));
      yield put(myFeedSuccess(result3));
    }

    yield put(changeScreenSuccess(SCREEN_KEYS.HOMESCREEN));
    navigation.push(SCREEN_KEYS.HOMESCREEN, { page: "HomeScreen" });
  } catch (e) {
    console.log(e);

    yield put(createPostFail(e));
  } finally {
    yield put(createPostTrigger(false));
    yield put(myFeedTrigger(false));
    yield put(editUserTrigger(false));
  }
}

export function* editPost(api, { payload = {} }) {
  yield put(editPostTrigger(true));
  try {
    const { navigation, description, banner, Id, deleteids, privacy } = payload;
    let id = Id;
    const token = yield getAccessToken();
    if (privacy == "draft") {
      const result = yield call(
        Api.callServer,
        api.editPost,
        {
          description,
          token,
          banner,
          deleteids,
          id,
          privacy,
        },
        true
      );

      yield put(editDraftSuccess(result));
      yield put(editPostSuccess(result));
    } else {
      const result = yield call(
        Api.callServer,
        api.editPost,
        {
          description,
          token,
          banner,
          id,
          deleteids,
          privacy,
        },
        true
      );
      yield put(editPostSuccess(result));
      const result4 = yield call(
        Api.callServer,
        api.getdraftpost,
        { token },
        true
      );
      yield put(mydraftpostSuccess(result4));
    }
    const result2 = yield call(Api.callServer, api.getMyPost, { token }, true);
    const result3 = yield call(Api.callServer, api.getMyFeed, { token }, true);

    yield put(mypostSuccess(result2));
    yield put(myFeedSuccess(result3));

    yield put(changeScreenSuccess(SCREEN_KEYS.HOMESCREEN));
    //  navigation.push(SCREEN_KEYS.HOMESCREEN, {page: 'HomeScreen'});
  } catch (e) {
    console.log(e);

    yield put(editPostFail(e));
  } finally {
    yield put(editPostTrigger(false));
  }
}

export function* singlePost(api, { payload = {} }) {
  yield put(SinglepostTrigger(true));
  try {
    const { navigation, postInfo } = payload;
    console.log("singlePost", payload);
    yield put(SinglepostSuccess(postInfo));
    yield put(changeScreenSuccess(SCREEN_KEYS.EDIT_POST));
    navigation.push(SCREEN_KEYS.EDIT_POST, { page: "EditPost" });
  } catch (e) {
    console.log(e);

    yield put(SinglepostFail(e));
  } finally {
    yield put(SinglepostTrigger(false));
  }
}

export function* getSingleProfilePostsById(api, { payload = {} }) {
  //yield put(singleProfileTrigger(true))
  try {
    const { id } = payload;
    console.log("Getting Single Profile Posts:", id);
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getSingleProfilePostsById,
      { id, token },
      true
    );
    yield put(singleProfilePostsSuccess(result));
    console.log("Single Profile Posts:", result);
  } catch (error) {
    console.log(error);
    // yield put(myPostFail(error));
  } finally {
    // yield put(singleProfileTrigger(false))
  }
}

export function* deletePost(api, { payload = {} }) {
  yield put(deletepostTrigger(true));
  try {
    const { navigation, id } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.deletePost,
      {
        token,
        id,
      },
      true
    );
    yield put(deletepostSuccess(result));
    const result3 = yield call(Api.callServer, api.getMyFeed, { token }, true);
    yield put(myFeedSuccess(result3));
    const result2 = yield call(Api.callServer, api.getMyPost, { token }, true);
    yield put(mypostSuccess(result2));
  } catch (e) {
    console.log(e);

    yield put(deletepostFail(e));
  } finally {
    yield put(deletepostTrigger(false));
  }
}

export function* reportPost(api, { payload = {} }) {
  yield put(reportpostTrigger(true));
  try {
    const { navigation, reportDescription, postid } = payload;
    const token = yield getAccessToken();
    console.log(reportDescription, postid, "ssss");
    const result = yield call(
      Api.callServer,
      api.reportpost,
      {
        token,
        postid,
        reportDescription,
      },
      true
    );
    // yield put(reportpostSuccess(id));
    const result3 = yield call(Api.callServer, api.getMyFeed, { token }, true);
    yield put(myFeedSuccess(result3));
    navigation.push("/");
  } catch (e) {
  } finally {
    yield put(reportpostTrigger(false));
  }
}

export function* likePost(api, { payload = {} }) {
  const { id, type, page } = payload;
  if (page == "Home") {
    console.log("Home Like");
    yield put(likePostTrigger({ id, type }));
  } else if (page == "profile") {
    console.log("Profile Like");

    yield put(likeProfilePostTrigger({ id, type }));
  } else {
    console.log("elseeeeeee");
    yield put(likeMyPostTrigger({ id, type }));
  }
  yield put(likesIdSuccess({ id }));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.likePost,
      { id, token },
      true
    );
  } catch (error) {
    console.log("resend Errorr", error);
    // yield put(resendVerificationFail(error));
  } finally {
  }
}

export function* postsLikesById(api, { payload = {} }) {
  // yield put(myFeedTrigger({"status": "acc", "targetId": payload.id}));
  yield put(singleProfileTrigger(true));

  try {
    const { id } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.postsLikesById,
      { id, token },
      true
    );
    yield put(postsLikesSuccess(result));
  } catch (error) {
    console.log("resend Errorr", error);
    // yield put(resendVerificationFail(error));
  } finally {
    yield put(myFeedTrigger(false));
    yield put(singleProfileTrigger(false));
  }
}

export function* getMydraftPost(api, { payload = {} }) {
  yield put(editUserTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getdraftpost,
      { token },
      true
    );
    yield put(mydraftpostSuccess(result));
    console.log("My post:", result);
  } catch (error) {
    console.log(error);
    yield put(mydraftPostFail(error));
  } finally {
    yield put(editUserTrigger(false));
  }
}

export function* deletePostdraft(api, { payload = {} }) {
  yield put(deletepostTrigger(true));
  try {
    const { navigation, postInfo } = payload;
    const { id } = postInfo;
    console.log("Delete", postInfo, id);
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.deletePost,
      {
        token,
        id,
      },
      true
    );
    yield put(deletepostdraftSuccess(id));
  } catch (e) {
    console.log(e);

    //yield put(deletepostdraftFail(e));
  } finally {
    yield put(deletepostTrigger(false));
  }
}

export function* uploadStatus(api, { payload = {} }) {
  try {
    yield put(uploadStatusSuccess(payload));
  } catch (e) {
    console.log(e);
  } finally {
  }
}

export function* edituploadStatus(api, { payload = {} }) {
  try {
    yield put(edituploadStatusSuccess(payload));
  } catch (e) {
    console.log(e);
  } finally {
  }
}

export function* postComment(api, { payload = {} }) {
  yield put(postcommentTrigger(true));

  try {
    const { id, comment } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.postComment,
      { id, comment, token },
      true
    );
    yield put(postcommentSuccess(result));

    const result2 = yield call(
      Api.callServer,
      api.getcomment2,
      { token, id },
      true
    );
    yield put(getcommentSuccess(result2));

    const result3 = yield call(
      Api.callServer,
      api.getcommentcount,
      { token, id },
      true
    );
console.log(result3,'result3result3 comment count')
    yield put(getCommentcount(result3));
  } catch (error) {
    console.log("resend Errorr", error);
  } finally {
    yield put(postcommentTrigger(false));
  }
}

export function* getPostComment(api, { payload = {} }) {
  console.log(payload, "before");

  const { newindex1 } = payload;
  yield put(postcommentCollapseTrigger(newindex1));
  yield put(postcommentTrigger(true));
  try {
    const { id, skip, limit } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getcomment,
      { token, id, skip, limit },
      true
    );
    yield put(getcommentSuccess(result));

    const result2 = yield call(
      Api.callServer,
      api.getcommentcount,
      { token, id },
      true
    );

    yield put(getCommentcount(result2));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(postcommentTrigger(false));
  }
}

export function* getPostCommentcount(api, { payload = {} }) {
  try {
    const { id } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getcommentcount,
      { token, id },
      true
    );
    console.log(result, "resultresult");
    yield put(getCommentcount(result));
  } catch (error) {
    console.log(error);
  } finally {
  }
}

export function* replyPostComment(api, { payload = {} }) {
  yield put(replyPostcommentTrigger(true));

  try {
    const { commentid, comment } = payload;
    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.replyPostComment,
      { commentid, comment, token },
      true
    );

    yield put(replyPostcommentSuccess(result));

    const result2 = yield call(
      Api.callServer,
      api.getcommentreply2,
      { token, commentid },
      true
    );
    yield put(getCommentReplySuccess(result2));

    const result4 = yield call(
      Api.callServer,
      api.replycommentcount,
      { token, commentid },
      true
    );
    yield put(getreplycount(result4));
  } catch (error) {
    console.log("resend Errorr", error);
  } finally {
    yield put(replyPostcommentTrigger(false));
  }
}

export function* getCommentReply(api, { payload = {} }) {
  yield put(replyPostcommentTrigger(true));
  try {
 
    const { commentid, skip2, limit2 } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getcommentreply,
      { token, commentid, skip2, limit2 },
      true
    );
    yield put(getCommentReplySuccess(result));

    const result2 = yield call(
      Api.callServer,
      api.replycommentcount,
      { token, commentid },
      true
    );
    yield put(getreplycount(result2));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(replyPostcommentTrigger(false));
  }
}

export function* getCommentReplyCount(api, { payload = {} }) {
  try {
    const { commentid } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.replycommentcount,
      { token, commentid },
      true
    );
    yield put(getreplycount(result));
  } catch (error) {
    console.log(error);
  } finally {
    // yield put(replyPostcommentTrigger(false));
  }
}

export function* deleteComment(api, { payload = {} }) {
  yield put(deletecommentTrigger(true));
  try {
    const { navigation, commentId, id, deleteType } = payload;
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.deleteComment,
      {
        token,
        commentId,
      },
      true
    );
    yield put(deletecommentSuccess(result));

    if (deleteType == "comment") {
      const result2 = yield call(
        Api.callServer,
        api.getcomment2,
        { token, id },
        true
      );
      yield put(getcommentSuccess(result2));

      const result3 = yield call(
        Api.callServer,
        api.getcommentcount,
        { token, id },
        true
      );

      yield put(getCommentcount(result3));
    } else {
      const result2 = yield call(
        Api.callServer,
        api.getcommentreply2,
        { token, commentid: commentId },
        true
      );
      yield put(getCommentReplySuccess(result2));

      const result4 = yield call(
        Api.callServer,
        api.replycommentcount,
        { token, commentid: commentId },
        true
      );
      yield put(getreplycount(result4));
    }
  } catch (e) {
    console.log(e);

    yield put(deletecommentFail(e));
  } finally {
    yield put(deletecommentTrigger(false));
  }
}

export function* editComment(api, { payload = {} }) {
  yield put(postcommentTrigger(true));
  try {
    const { comment, commentId, id, deleteType, commentId2 } = payload || {};
    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.editComment,
      {
        comment,
        token,
        commentId,
      },
      true
    );

    if (deleteType == "comment") {
      const result2 = yield call(
        Api.callServer,
        api.getcomment2,
        { token, id },
        true
      );
      yield put(getcommentSuccess(result2));

      const result3 = yield call(
        Api.callServer,
        api.getcommentcount,
        { token, id },
        true
      );

      yield put(getCommentcount(result3));
    } else {
      const result2 = yield call(
        Api.callServer,
        api.getcommentreply2,
        { token, commentid: commentId2 },
        true
      );
      yield put(getCommentReplySuccess(result2));

      const result4 = yield call(
        Api.callServer,
        api.replycommentcount,
        { token, commentid: commentId2 },
        true
      );
      yield put(getreplycount(result4));
    }
  } catch (e) {
    console.log(e);

    yield put(editPostFail(e));
  } finally {
    yield put(postcommentTrigger(false));
  }
}

export function* sharePost(api, { payload = {} }) {
  yield put(sharePostTrigger(true));
  yield put(myFeedTrigger(true));
  try {
    const { description, id } = payload || {};
    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.sharePost,
      {
        description,
        id,
        token,
      },
      true
    );
    yield put(sharePostSuccess(result));
    const result2 = yield call(Api.callServer, api.getMyPost, { token }, true);
    const result3 = yield call(Api.callServer, api.getMyFeed, { token }, true);

    yield put(mypostSuccess(result2));
    yield put(myFeedSuccess(result3));

  } catch (e) {
    yield put(sharePostFail(e));
  } finally {
    yield put(sharePostTrigger(false));
    yield put(myFeedTrigger(false));
  }
}



export function* getsinglePost(api, { payload = {} }) {
  yield put(singlePostbyidTrigger(true));
  try {
    const { id } = payload || {};
    const token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.getsinglePost,
      {
        id,
        token,
      },
      true
    );
    yield put(singlePostbyidSuccess(result));
    

  } catch (e) {
    yield put(singlePostbyidFail(e));
  } finally {
    yield put(singlePostbyidTrigger(false));
  }
}

