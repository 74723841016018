import React, { useEffect, useState, Fragment } from 'react';
import { Nav, Navbar, Button, Dropdown, Col } from 'react-bootstrap';
import '@themes/CommonApplicationStyles.css';
import '@navigation/NavBarStyles.css';
import { useHistory } from 'react-router-dom';
import web3 from './../services/web3';
import Searchbar from '@components/searchbar/Searchbar';
import DPround from '@components/dpround/DPround';
import defaultdp from '@images/defaultdp.jpg';
import { BsSearch } from 'react-icons/bs';
function NavBar(props) {
  const history = useHistory();
  const [display, setDisplay] = useState(false);
  const [user, setUser] = useState('');
  const [nftname, setNftname] = useState('');
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));
  }, []);
  var profileImage;
  const image = JSON.parse(localStorage.getItem('my-profile-image'));
  image &&
    image.profileimage &&
    image.profileimage.map((img) => {
      if (img.size == 'thumbnail') {
        profileImage = img.link;
      }
    });
  return (
    <div id='navibar'>
      <Fragment>
        <Navbar sticky='top' fixed='top' bg='white' expand='lg'>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Navbar.Brand>
              <h5 class='apptitle'>
                <span
                  className='logotext'
                  onClick={() => history.push('/explore')}
                >
                  NFT
                </span>
              </h5>
            </Navbar.Brand>
           <Searchbar
            value={nftname}
            placeholder='Search by NFT Name '
            onChange={(e) => setNftname(e.target.value)}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <BsSearch
            onClick={() => {
              history.push('/search', { data: nftname });
            }}
          />  
            <Nav
              activeKey={props.location.pathname}
              className='justify-content-end align-items-center ml-auto'
            >
              <Nav.Item>
                <Nav.Link href='/explore'>
                  <h5 class='apptitle'>
                    <span className='logotext'>Explore</span>
                  </h5>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href='/portfolio'>
                  <h5 class='apptitle'>
                    <span className='logotext'>Portfolio</span>
                  </h5>{' '}
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Button
                  className='Btn connect'
                  variant='primary'
                  onClick={() => {
                    history.push({
                      pathname: '/createnft',
                      state: { page: 'create' },
                    });
                  }}
                >
                  Create
                </Button>
              </Nav.Item>
              <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                  {/* <div className='dpround2'>
                    <div className='profilecircle2'>
                      <img src={defaultdp} alt='image' />
                    </div>
                  </div> */}
                  <DPround profileImage={profileImage} page='small' />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='/portfolio'>
                    {user && user.username}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href='/signin'
                    onClick={() => {
                      localStorage.clear();
                    }}
                  >
                    <span className=''>Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Fragment>
    </div>
  );
}

export default NavBar;
