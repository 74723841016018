import apisauce from "apisauce";

//import Env from "../../.env"
const baseUrl = "https://nft-artistry.api.dragonchain.com/api";
// //let env = Env.create();
console.log(process.env.API_URL, "envv");
//const baseUrl = ""//env.('APP_URL');
const create = (baseURL = baseUrl) => {
  const apis = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 100000,
  });

  const signIn = (data) => {
    console.log(data);
    return apis.post("/auth/signin", data);
  };
  const signUp = (data) => apis.post("/auth/signup", data);
  const forgotpassword = (data) =>
    apis.post(`/auth/request-password-reset`, data);

  const getUser = (data) => {
    // console.log('data',data)
    return apis.get(
      `/user/my-profile/`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const getMyPost = (data) => {
    return apis.get(
      `/user/my-post?skip=0&order={ "createdOn": "DESC"}&relations=["postImages"]`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const editUser = (data) => {
    // console.log('Api called')
    const { token } = data;
    // console.log('Data before deletion', data, token)
    delete data["token"];
    console.log("Data after deletion", data);
    return apis.patch(
      `/user/my-profile`,
      { ...data },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const createPost = (data) => {
    const formData = new FormData();
    for (let i = 0; i < data.imageserver.image.length; i++) {
      formData.append("images", data.imageserver.image[i]);
      console.log("data.banner[i]", data.imageserver.image[i]);
    }
    formData.append("description", data.description);
    {
      data.privacy == "draft" && formData.append("privacy", data.privacy);
    }
    return apis.post(`/post/createposts/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${data.token}`,
        Accept: "Application/json",
      },
    });
  };

  const updateProfileImage = (data) => {
    const { payload, token } = data;
    const formData = new FormData();
    formData.append("image", payload);
    console.log("UpdateProfile Api Called", formData);
    return apis.post(`/user/profile-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const updateBannerImage = (data) => {
    const { payload, token } = data;
    const formData = new FormData();
    formData.append("image", payload);
    // console.log('UpdateProfile Api Called', formData)
    return apis.post(`/user/banner-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const editPost = (data) => {
    console.log(data, "data");
    const formData = new FormData();
    for (let i = 0; i < data.banner.length; i++) {
      formData.append("images", data.banner[i]);
      console.log("data.banner[i]", data.banner[i]);
    }
    formData.append("description", data.description);
    formData.append("privacy", data.privacy);
    return apis.patch(
      `/post/patchposts/${data.id}?relations=["postImages"]&deleteIds=[${data.deleteids}]`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getRecentProfiles = (data) => {
    return apis.get(
      `/users/home/recent-profiles?order={ "createdOn": "DESC"}&skip=0&limit=4&relations=["profileImage","userprofile"]`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const getProfileById = (data) => {
    return apis.get(
      `users/profile/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const getSingleProfilePostsById = (data) => {
    return apis.get(
      `/users/profile/posts/${data.id}?skip=0&limit=4&order={ "createdOn": "DESC"}&relations=["postImages"]`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const deletePost = (data) => {
    return apis.delete(
      `/post/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getMyFeed = (data) => {
    return apis.get(
      `/users/home/myfeed?skip=0&limit=50&order={ "createdOn": "DESC"}&relations=["user"]`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const followRequest = (data) => {
    return apis.post(
      `/follow/followuser/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const acceptFollowRequest = (data) => {
    console.log("apidata", data);
    apis.post(
      `/follow/acceptuser/${data.id}?status=acc`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const unfollow = (data) => {
    return apis.delete(
      `/follow/unfollow/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getMyFollowingid = (data) => {
    return apis.get(
      `/follow/myfollowingsId`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const blockUser = (data) => {
    return apis.post(
      `/block/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const unblockUser = (data) => {
    return apis.delete(
      `/block/unblock/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getMyBlockListId = (data) => {
    return apis.get(
      `/block/myblocklistId?`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const reportpost = (data) => {
    return apis.post(`/post/${data.postid}`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };

  const deleteProfileImage = (data) => {
    const { token } = data;
    return apis.patch(
      `/user/remove-profile-image`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };
  const deleteBannerImage = (data) => {
    const { token } = data;
    return apis.delete(
      `/user/remove-banner-image`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };
  const searchByUsername = (data) => {
    return apis.get(
      `/users/home/searchprofiles?skip=0&limit=20&search=${data.search}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const verifyotp = (data) => apis.post(`/auth/verify-otp`, data);
  const setnewpasswordmobile = (data) =>
    apis.post(`/auth/set-new-passwordmob`, data);

  const likePost = (data) => {
    return apis.post(
      `/post/like/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const myLikesId = (data) => {
    return apis.get(
      `/post/like/mylikesId`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const postsLikesById = (data) => {
    return apis.get(
      `/post/like/${data.id}?relations=["user","post"]&skip=0&limit=2&order={ "createdOn": "ASC"}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getdraftpost = (data) => {
    return apis.get(
      `/user/my-draft?skip=0&limit=40&order={"createdOn":"DESC"}&relations=["postImages"]`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const updateEmailMobile = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.patch(
      `/users/settings/updateemailormobile`,
      { ...data },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const addProduct = (data) => {
    const formData = new FormData();
    for (let i = 0; i < data.imageserver.image.length; i++) {
      formData.append("images", data.imageserver.image[i]);
      console.log("data.banner[i]", data.imageserver.image[i]);
    }
    formData.append("description", data.description);
    formData.append("amount", data.amount);
    formData.append("quantity", data.quantity);
    formData.append("title", data.title);
    formData.append("category", data.category);
    formData.append("type", data.type);
    formData.append("discount", data.discount);
    return apis.post(
      `/market/createmarket?relations=["marketImages"]&featuredId=${data.featuredId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getproductservices = (data) => {
    return apis.get(`/market/listmarket?orderBy=id&sort=DESC`, {});
  };

  const searchproductservices = (data) => {
    return apis.get(
      `/market/listmarket?orderBy=id&sort=DESC&title=${data.search}`,
      {}
    );
  };

  const filterbycategory = (data) => {
    return apis.get(
      `/market/listmarket?orderBy=id&sort=DESC&category=${data.filtercategory}`,
      {}
    );
  };

  const filterandsearch = (data) => {
    return apis.get(
      `/market/listmarket?orderBy=id&sort=DESC&category=${data.filtercategory}&title=${data.search}`,
      {}
    );
  };

  const getmyproductservices = (data) => {
    return apis.get(
      `/user/my-market?orderBy=id&sort=DESC`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getCategories = (data) => {
    return apis.get(
      `/market/category/market-categories`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const deleteProduct = (data) => {
    return apis.delete(
      `/market/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const editProductServices = (data) => {
    console.log(data, "data");
    const formData = new FormData();
    for (let i = 0; i < data.banner.length; i++) {
      formData.append("images", data.banner[i]);
    }
    formData.append("description", data.description);
    formData.append("amount", data.amount);
    formData.append("quantity", data.quantity);
    formData.append("title", data.title);
    formData.append("category", data.category);
    formData.append("type", data.type);
    formData.append("discount", data.discount);
    return apis.patch(
      `/market/patchmarket/${data.id}?relations=["marketImages"]&featuredId=${data.featuredId}&deleteIds=[${data.deleteids}]`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const addtocart = (data) => {
    return apis.post(
      `/cart/addtocart/${data.id}?relations=["user","market"]`,
      data,
      {
        headers: { Authorization: `Bearer ${data.token}` },
      }
    );
  };
  const updatecart = (data) => {
    return apis.patch(
      `/cart/patchcart/${data.id}?relations=["user","market"]`,
      data,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
          Accept: "Application/json",
        },
      }
    );
  };

  const getcartproduct = (data) => {
    return apis.get(
      `/user/my-cart`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const removecartproduct = (data) => {
    return apis.delete(
      `/cart/removecart/${data.Id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const editcartProduct = (data) => {
    console.log(data, "data");
    return apis.patch(
      `/cart/patchcart/${data.id}?relations=["user","market"]`,
      data,
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const addaddress = (data) => {
    return apis.post(`/order/address/createaddress`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };

  const getAddress = (data) => {
    return apis.get(
      `/order/address/listaddress`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const editAddress = (data) => {
    console.log(data, "data");
    return apis.patch(`/order/address/editaddress/${data.Id}`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };

  const createorder = (data) => {
    return apis.post(`/order/createorder`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };

  const myorder = (data) => {
    return apis.get(
      `/user/my-order?order={ "createdOn": "DESC"}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const myOrderRequest = (data) => {
    return apis.get(
      `/order/listorder`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const onlinepaymentconfirm = (data) => {
    return apis.post(
      `/order/orderStatus?relations=["payment"]&intentId=${data.intentId}&clientSecret=${data.clientSecret}&status=${data.status}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const postComment = (data) => {
    return apis.post(`/post/comment/${data.id}`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };

  const getcomment2 = (data) => {
    return apis.get(
      `/post/comment/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getcomment = (data) => {
    return apis.get(
      `/post/comment/${data.id}?skip=${data.skip}&limit=${data.limit}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };
  const getcommentcount = (data) => {
    return apis.get(
      `/post/comment/commentcount/${data.id}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const replyPostComment = (data) => {
    return apis.post(`/post/comment/reply/${data.commentid}`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };

  const getcommentreply = (data) => {
    return apis.get(
      `/post/comment/replies/${data.commentid}?skip=${data.skip2}&limit=${data.limit2}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const replycommentcount = (data) => {
    return apis.get(
      `/post/comment/replycount/${data.commentid}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const getcommentreply2 = (data) => {
    return apis.get(
      `/post/comment/replies/${data.commentid}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const deleteComment = (data) => {
    return apis.delete(
      `/post/comment/${data.commentId}`,
      {},
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  };

  const editComment = (data) => {
    console.log(data, "data");
    return apis.patch(`/post/comment/patchcomment/${data.commentId}`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };


  const sharePost = (data) => {
    return apis.post(`/post/share/${data.id}`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };


  const getsinglePost = (data) => {
    return apis.get(`/post/${data.id}?relations=["postImages","user"]`, {}, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };


  return {
    signIn,
    signUp,
    forgotpassword,
    getUser,
    getMyPost,
    editUser,
    createPost,
    updateProfileImage,
    updateBannerImage,
    editPost,
    getRecentProfiles,
    getProfileById,
    deletePost,
    getSingleProfilePostsById,
    getMyFeed,
    followRequest,
    acceptFollowRequest,
    unfollow,
    getMyFollowingid,
    blockUser,
    unblockUser,
    getMyBlockListId,
    reportpost,
    deleteProfileImage,
    deleteBannerImage,
    verifyotp,
    setnewpasswordmobile,
    likePost,
    myLikesId,
    postsLikesById,
    searchByUsername,
    getdraftpost,
    updateEmailMobile,
    addProduct,
    getproductservices,
    getCategories,
    getmyproductservices,
    deleteProduct,
    editProductServices,
    addtocart,
    getcartproduct,
    removecartproduct,
    editcartProduct,
    addaddress,
    getAddress,
    editAddress,
    createorder,
    myorder,
    updatecart,
    myOrderRequest,
    searchproductservices,
    filterbycategory,
    filterandsearch,
    onlinepaymentconfirm,
    postComment,
    getcomment,
    getcomment2,
    replyPostComment,
    getcommentreply,
    getcommentreply2,
    replycommentcount,
    getcommentcount,
    deleteComment,
    editComment,
    sharePost,
    getsinglePost,
  };
};
export default {
  create,
};
