import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';

import more from '@images/more.png';
import { useHistory } from 'react-router-dom';
import '@components/product-card/Product.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import like from '@images/like.png';
import liked from '@images/liked.png';
import Modal from 'react-modal';
import { CgCloseO } from 'react-icons/cg';
import PopUp from '@components/popup/PopUp';
import PopUpPrice from '@components/popup/PopUpPrice';
import PopUpBuy from '@components/popup/PopUpBuy';

function ProductCard({ navigation, productInfo, myprofile, myitems }) {
  const [list, setlist] = useState([]);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState('');

  const [modal, setmodal] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [editType, seteditType] = useState('');

  function openModal() {
    setmodal(true);
  }
  function closeModal() {
    setmodal(false);
  }
  function openModal1() {
    setmodalIsOpen(true);
  }
  function closeModal1() {
    setmodalIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '52%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: '1px solid rgb(204 204 204 / 22%)',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 0px 20px 0px #dee2e669',
      borderRadius: '30px',
    },
  };
  const { nft, image, detail } = productInfo || {};
  const { nftid, name } = nft || {};
  // const { filename, } = image || {};
  const { amount, description, userid, discount, onsale, ownerid, note } =
    detail || {};
  const [newdiscount, setnewdiscount] = useState();
  const history = useHistory();
  function getdiscount(num2) {
    let discount = amount - (amount * num2) / 100;
  }

  console.log(productInfo);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'));
    setUser(user && user.uuid);
  }, []);
console.log("My profile --------------->",myprofile)
  console.log('asdf', user, ownerid);
  //  Put on sale
  const returnval = (val) => {
    let img = '';
    image &&
      image.filename[0] &&
      image.filename[0].map((val) => {
        if (val.size == 'thumbnail') {
          return val.link;
        }
      });
  };
  var customimg = '';
  image &&
    image.filename[0] &&
    image.filename[0].map((val) => {
      if (val.size == 'thumbnail') {
        customimg = val.link;
      }
    });
  return (
    <div>
      <Modal
        isOpen={modal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div onClick={closeModal} style={{ textAlign: 'right' }}>
          <CgCloseO size={'20px'} className={'absolutecross'} />
        </div>
        <PopUpPrice
          editType={productInfo}
          onclose={() => {
            setmodal(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal1}
        style={customStyles}
      >
        <div onClick={closeModal1} style={{ textAlign: 'right' }}>
          <CgCloseO size={'20px'} className={'absolutecross'} />
        </div>
        <PopUpBuy
          editType={productInfo}
          onclose={() => {
            setmodalIsOpen(false);
          }}
        />
      </Modal>

      <div className='productcard'>
        <div className='postcontent'>
          {!myprofile ? (
            <div className='dropdownbox'>
              <Dropdown>
                <Dropdown.Toggle className='threedot' id='dropdown-basic'>
                  <img src={more} alt='img' />
                </Dropdown.Toggle>
                <Dropdown.Menu className='menu'>
                  <Dropdown.Item
                    onClick={() => {
                      history.push(`/nftdetail/${nftid}`, {
                        productInfo,
                        ownerid: 'ownerid',
                      });
                    }}
                  >
                    View Details
                  </Dropdown.Item>
                  {console.log('onsale:', onsale)}
                  {onsale == false && ownerid == user && (
                    <Dropdown.Item
                      onClick={() => {
                        history.push('/editnft', { productInfo, page: 'edit' });
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                  )}
                  {onsale == 'true' && ownerid != user && (
                    <Dropdown.Item onClick={openModal1}>Buy Now</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <div className='dropdownbox'>
              <Dropdown>
                <Dropdown.Toggle className='threedot' id='dropdown-basic'>
                  <img src={more} alt='img' />
                </Dropdown.Toggle>
                <Dropdown.Menu className='menu'>
                  <Dropdown.Item
                    onClick={() => {
                      history.push(`/nftdetail/${nftid}`, {
                        productInfo,
                        ownerid: 'ownerid',
                      });
                    }}
                  >
                    View Details
                  </Dropdown.Item>
                  {onsale == 'false' && ownerid == user && (
                    <Dropdown.Item
                      onClick={() => {
                        history.push('/editnft', { productInfo, page: 'edit' });
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                  )}

                  {myitems && onsale == 'false' && ownerid == user && (
                    <Dropdown.Item onClick={openModal}>
                      Put on sale
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <div
            className='imagearea'
            onClick={() => history.push(`/nftdetail/${nftid}`, { productInfo })}
          >
            <img alt='Image' src={customimg} />
          </div>

          <div
            className='producttitle '
            onClick={() => history.push(`/nftdetail/${nftid}`, { productInfo })}
          >
            <span className='textwraps'>{name}</span>
          </div>
          {onsale == 'false' && (
            <div
              className='productamount'
              onClick={() =>
                history.push(`/nftdetail/${nftid}`, { productInfo })
              }
            >
              <span>Not On Sale</span>
            </div>
          )}
          {(!onsale || onsale != 'false') && (
            <div
              className='productamount'
              onClick={() =>
                history.push(`/nftdetail/${nftid}`, { productInfo })
              }
            >
              <span>{amount} USD</span>
            </div>
          )}

          {(!onsale || onsale != 'false') && (
            <div className='productdiscount '>
              {/* <span className="discount">{discount}&nbsp;%</span> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
