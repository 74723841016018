import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, Nav, Button, Spinner, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '@themes/CommonApplicationStyles.css';
import Tab from '@components/tab/Tab.js';
import '@features/nftdetail/NftdetailStyles.css';
import * as _ from 'lodash';
import Loader from '@components/loader/Loader';
import NavBar from '@navigation/NavBar';
import { useHistory } from 'react-router-dom';
import CustomButton from '@components/custom-button/CustomButton';
import Searchbar from '@components/searchbar/Searchbar';
import Dpcreator from '@components/dpround/Dpcreator';
import Details from '@components/detailstab/Details';
import Bids from '@components/detailstab/Bids';
import Owner from '@components/detailstab/Owner';
import History from '@components/detailstab/History';
const sdk = require('dragonchain-sdk');
import Modal from 'react-modal';
import { CgCloseO } from 'react-icons/cg';
import PopUp from '@components/popup/PopUp';
import PopUpPrice from '@components/popup/PopUpPrice';
import PopUpBuy from '@components/popup/PopUpBuy';
import { Carousel } from 'react-responsive-carousel';
import ReactJson from 'react-json-view';
import pdficon from '@images/file.png';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

function Nftdetail({ match }) {
  const [modal, setmodal] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [details, setDetails] = useState(null);
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [editType, seteditType] = useState('');
  const [logs, setLogDetails] = useState(null);

  function openModal1() {
    setmodalIsOpen(true);
  }
  function closeModal1() {
    setmodalIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '52%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: '1px solid rgb(204 204 204 / 22%)',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 0px 20px 0px #dee2e669',
      borderRadius: '30px',
    },
  };
  const history = useHistory();
  const [key, setkey] = useState('1');
  const [event, setEvent] = useState('PutOnSale');

  const uuid = (match && match.params && match.params.id) || {};
  const [loader, setLoader] = useState(false);
  useEffect(async () => {
    window.scrollTo(0, 0);
    setLoader(true);
    const token = localStorage.getItem('accessToken');
    await axios
      .get(`${URL.API}/nfts/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response);
        //   localStorage.setItem('nft_logs', JSON.stringify(response.data));
        setLogDetails(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
    setLoader(false);
  }, []);

  const renderPutonSale = (data) => {
    const {
      amount,
      description,
      nftid,
      nftinfoid,
      note,
      onhold,
      onsale,
      ownerid,
      timestamp,
      userid,
    } = data[0];
    return (
      <>
        <Col lg={6} xs={12}>
          <p className='txt' style={{ marginLeft: '12px', marginTop: '10px' }}>
            Owner:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              <a
                style={{ zIndex: 200 }}
                className=''
                href='/othersprofile'
                onClick={() => localStorage.setItem('other-user-id', ownerid)}
              >
                {ownerid}
              </a>
            </label>
          </p>
          <p className='txt' style={{ marginLeft: '12px', marginTop: '10px' }}>
            Amount:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {amount}
            </label>
          </p>
          <p className='txt' style={{ marginLeft: '12px', marginTop: '10px' }}>
            Time:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {timestamp}
            </label>
          </p>
          <p className='txt' style={{ marginLeft: '12px', marginTop: '10px' }}>
            Nft id:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {nftid}
            </label>
          </p>
        </Col>
      </>
    );
  };
  const renderModified = (data) => {
    const {
      amount,
      description,
      nftid,
      nftinfoid,
      note,
      onhold,
      onsale,
      ownerid,
      timestamp,
      userid,
    } = data[0];
    const { filename } = data[1];

    var customimg = [];
    filename &&
      filename.map((item) => {
        return (
          item &&
          item.map((im) => {
            if (im.size == 'thumbnail') {
              customimg.push(im.link);
            }
          })
        );
      });
    return (
      <>
        <Col lg={6} xs={12}>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            dynamicHeight={true}
            stopOnHover={true}
            showStatus={true}
            showThumbs={false}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showIndicators={true}
          >
            {customimg &&
              customimg.map((item) => (
                <img src={item} alt='image' className='nfti' />
              ))}
            {/* {image !== 'no image' &&
                    image &&
                    image.filename.map((item) => (
                      <img
                        src={`data:image/png;base64,${item}`}
                        alt=''
                        className='nfti'
                      />
                    ))} */}
          </Carousel>
        </Col>
        <Col lg={6} xs={12}>
          <p className='txt'>
            Description:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {description}
            </label>
          </p>
          <p className='txt'>
            Owner:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              <a
                style={{ zIndex: 200 }}
                className=''
                href='/othersprofile'
                onClick={() => localStorage.setItem('other-user-id', ownerid)}
              >
                {ownerid}
              </a>
            </label>
          </p>
          <p className='txt'>
            Amount:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {amount}
            </label>
          </p>
          <p className='txt'>
            Time:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {timestamp}
            </label>
          </p>
          <p className='txt'>
            Nft id:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {nftinfoid}
            </label>
          </p>
          <p className='txt'>
            Status:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {onsale ? 'On Sale' : 'Draft '}
            </label>
          </p>
          {data[2] && data[2].filename && (
            <Col className='mt-2'>
              <h6>Attachments</h6>
              {data[2].filename.map((file) => (
                <div className='d-flex  align-items-cetner mt-3'>
                  <img
                    src={pdficon}
                    alt='image'
                    height={30}
                    width={30}
                    className='attachment'
                  />
                  <a
                    href={file && file.name}
                    className='d-flex align-items-center'
                    style={{ margin: 0, marginLeft: 10 }}
                  >
                    {file && file.filename}.pdf
                  </a>
                </div>
              ))}
            </Col>
          )}
        </Col>
      </>
    );
  };
  const renderCreated = (data) => {
    const {
      name,

      type,
    } = data[0];
    const {
      amount,
      description,
      nftid,
      nftinfoid,
      note,
      onhold,
      onsale,
      ownerid,
      timestamp,
      userid,
    } = data[1];
    const { filename } = data[2];

    var customimg = [];
    filename &&
      filename.map((item) => {
        return (
          item &&
          item.map((im) => {
            if (im.size == 'thumbnail') {
              customimg.push(im.link);
            }
          })
        );
      });
    return (
      <>
        <Col lg={6} xs={12}>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            dynamicHeight={true}
            stopOnHover={true}
            showStatus={true}
            showThumbs={false}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showIndicators={true}
          >
            {customimg &&
              customimg.map((item) => (
                <img src={item} alt='image' className='nfti' />
              ))}
            {/* {image !== 'no image' &&
                    image &&
                    image.filename.map((item) => (
                      <img
                        src={`data:image/png;base64,${item}`}
                        alt=''
                        className='nfti'
                      />
                    ))} */}
          </Carousel>
        </Col>
        <Col lg={6} xs={12}>
          <p className='txt'>
            Name:
            <label style={{ marginTop: 5, marginLeft: 10 }} className=''>
              {name}
            </label>
          </p>
          <p className='txt'>
            Description:
            <label style={{ marginTop: 5, marginLeft: 10 }} className=''>
              {description}
            </label>
          </p>
          <p className='txt'>
            Owner:
            <label style={{ marginTop: 5, marginLeft: 10 }} className=''>
              <a
                style={{ zIndex: 200 }}
                className=''
                href='/othersprofile'
                onClick={() => localStorage.setItem('other-user-id', ownerid)}
              >
                {ownerid}
              </a>
            </label>
          </p>
          <p className='txt'>
            Amount:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {amount}
            </label>
          </p>
          <p className='txt'>
            Type:
            <label style={{ marginTop: 5, marginLeft: 10 }} className=''>
              {type}
            </label>
          </p>
          <p className='txt'>
            Time:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {timestamp}
            </label>
          </p>
          <p className='txt'>
            Nft id:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {nftinfoid}
            </label>
          </p>
          <p className='txt'>
            Status:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {onsale == 'true' ? 'On Sale' : 'Draft '}
            </label>
          </p>
          {data[3] && data[3].filename && (
            <Col className='mt-2'>
              <h6>Attachments</h6>
              {data[3].filename.map((file) => (
                <div className='d-flex  align-items-cetner mt-3'>
                  <img
                    src={pdficon}
                    alt='image'
                    height={30}
                    width={30}
                    className='attachment'
                  />
                  <a
                    href={file && file.name}
                    className='d-flex align-items-center'
                    style={{ margin: 0, marginLeft: 10 }}
                  >
                    {file && file.filename}.pdf
                  </a>
                </div>
              ))}
            </Col>
          )}
        </Col>
      </>
    );
  };
  const renderTransferSuccessful = (data) => {
    const {
      amount,
      clientsecret,
      intentid,
      nftid,
      sessionid,
      settled,
      status,
    } = data[0];
    const {
      description,
      nftinfoid,
      note,
      onhold,
      onsale,

      ownerid,
      userid,
    } = data[1];
    const {
      buyerid,

      sellerid,
      timestamp,
      transferid,
    } = data[2];
    return (
      <>
        {/* <Col lg={6} xs={12}></Col> */}
        <Col lg={6} xs={12}>
          <p className='txt'>
            NFT Id:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {nftid}
            </label>
          </p>
          <p className='txt'>
            Buyer Id:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {buyerid}
            </label>
          </p>
          <p className='txt'>
            Seller Id:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {sellerid}
            </label>
          </p>
          <p className='txt'>
            Transfer Id:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {transferid}
            </label>
          </p>
        </Col>
        <Col lg={6} xs={12}>
          <p className='txt'>
            Description:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {description}
            </label>
          </p>
          <p className='txt'>
            Owner:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              <a
                style={{ zIndex: 200 }}
                className=''
                href='/othersprofile'
                onClick={() => localStorage.setItem('other-user-id', ownerid)}
              >
                {ownerid}
              </a>
            </label>
          </p>
          <p className='txt'>
            Amount:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {amount}
            </label>
          </p>
          <p className='txt'>
            Time:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {timestamp}
            </label>
          </p>

          <p className='txt'>
            Status:
            <label style={{ marginTop: '10px', marginLeft: 10 }} className=''>
              {'   '}
              {status}
            </label>
          </p>
        </Col>
      </>
    );
  };
  console.log('lOgsssss:', logs);
  return (
    <div id='nftlogs'>
      <NavBar location={useLocation()} />
      <Col style={{ textAlign: 'center', marginTop: 30 }}>
        {loader && <Spinner animation='border' variant='primary' />}
        {!loader && <div> {logs && logs.event}</div>}
      </Col>

      {logs && (
        <Container className='mt-5'>
          {!loader && (
            <Row
              style={{ border: '2px solid black', padding: 20, margin: 'auto' }}
            >
              {logs &&
                logs.event == 'PutOnSale' &&
                renderPutonSale(logs.details)}
              {logs && logs.event == 'Modified' && renderModified(logs.details)}
              {logs && logs.event == 'Created' && renderCreated(logs.details)}
              {logs &&
                logs.event == 'Transfer Successful' &&
                renderTransferSuccessful(logs.details)}
            </Row>
          )}

          {/* <ReactJson src={logs} theme='bright:inverted' /> */}
        </Container>
      )}
    </div>
  );
}

export default Nftdetail;
