import React, { useEffect, useState } from "react";
import { Row, Col, Card, Nav, Dropdown, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as _ from "lodash";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

import "@components/upload-message/uploadmessageStyle.css";

function UploadMessage({
  createpostLoading,
  poststatus,
  uploadStatus,
}) {
  const [timeout, settimeot] = useState(false);
  const [uploading, setuploading] = useState(false);

  // function uploadelogo() {
  //   return (
  //     <div className="uploadtext">
  //       <IoCheckmarkCircleSharp size={"30px"} />
  //     </div>
  //   );
  // }

  // useEffect(() => {
  //   if( poststatus && poststatus === "successfull"){

  //   let timer1 = setTimeout(() => settimeot(false), 50);
  //   console.log(timer1, "timer1");
  //   return () => {
  //     clearTimeout(timer1);
  //     console.log(timer1, "timer2");
  //   };
  //   }
  // });

  return (
    <span>
      {(poststatus != "successful" && createpostLoading) && (
        <div className="uploadtext">
          <AiOutlineCloudUpload size={"30px"} />
        </div>
      )}

<div className="timer">
      {!createpostLoading  &&
        setTimeout(() => uploadStatus("initial"), 1000)}
</div>
      {poststatus === "successful" && (
        <div className="uploadtext">
          <IoCheckmarkCircleSharp size={"30px"} />
        </div>
      )}
    </span>
  );
}

const mapStateToProps = ({ tracker = {}, user = {}, post = {} }) => {
  const createpostLoading = _.get(post, "createpostLoading", false);
  const poststatus = _.get(post, "poststatus", "");
  return { createpostLoading, poststatus };
};

const mapDispatchToProps = (dispatch) => ({
  uploadStatus: (data) =>
    dispatch({ type: "uploadStatusCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadMessage);
