import CustomButton from '@components/custom-button/CustomButton';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputField from '@components/input-field/InputField';
import cryptoRandomString from 'crypto-random-string';
import '@features/editnft/editnftStyles.css';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import '@themes/CommonApplicationStyles.css';
import { Row, Col, Spinner, Form } from 'react-bootstrap';
import NavBar from '@navigation/NavBar';
import { connect } from 'react-redux';
const sdk = require('dragonchain-sdk');
import * as _ from 'lodash';

export default function Buysuccess() {
  const history = useHistory();
  const [validated, setValidate] = useState(false);

  const { productInfo, page, transactionId, nftId } =
    (history && history.location && history.location.state) || {};
  const { nft, image, detail } = productInfo || {};
  const { nftid, name, note, type, ownerid } = nft || {};
  const { amount, description, userid, discount } = detail || {};
  const [buyerid, setBuyerid] = useState('');
  const [nftname, setNftname] = useState(name);
  const [typee, setType] = useState(type);
  const [desc, setDesc] = useState(description);
  const [price, setPrice] = useState(amount);
  const [discountPerc, setDiscount] = useState(discount);

  const [fileName, setFileName] = useState('');

  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);

  console.log('INFO', productInfo);
  console.log(history, transactionId, nftid);

  return (
    <div id='createpost'>
      <NavBar location={useLocation()} />
      <Container style={{ marginTop: '50px' }}>
        <Row className='justify-content-md-center'>
          <Col lg={8}>
            <h3 className='mb-4 text-center'>
              <span>Congratulation! Transfer Successfull</span>
            </h3>
          </Col>
        </Row>

        <Row className='justify-content-md-center'>
          <Col style={{ textAlign: 'center', fontSize: '22px' }}>
            Go to <a href='/portfolio'>Portfolio</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
