import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, Nav, Button } from 'react-bootstrap';
import '@themes/CommonApplicationStyles.css';
import '@features/nftdetail/NftdetailStyles.css';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import DPround from '@components/dpround/DPround';

function Details({
  loader,
  addtocartloading,
  ownerid,
  type,
  creator,
  note,
  creatordp,
}) {
  const history = useHistory();
  const [key, setkey] = useState('1');
  const { productInfo } =
    (history && history.location && history.location.state) || {};
  const { id, title, amount, images } = productInfo || {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var profilePic;
  creatordp &&
    creatordp.profileimage &&
    creatordp.profileimage.map((pic) => {
      if (pic.size == 'thumbnail') {
        profilePic = pic.link;
      }
    });
  console.log('dp', profilePic);
  return (
    <div>
      <Container>
        <div className='content'>
          <Row
            lg={12}
            style={{
              marginLeft: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            {!loader && <DPround profileImage={profilePic} page='small' />}

            {!loader && (
              <Col>
                <div className='destitle'>Creator</div>
                <div
                  className='description'
                  className='linkhover'
                  onClick={() => {
                    localStorage.setItem('other-user-id', ownerid);
                    localStorage.setItem('other-user-name', creator);
                    history.push('/othersprofile');
                  }}
                >
                  {ownerid} ({creator})
                </div>
              </Col>
            )}
          </Row>
          <Row
            lg={12}
            style={{
              marginLeft: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            <DPround profileImage={''} page='small' />

            <Col>
              <div className='destitle'>Nft Type</div>
              <div className='description'>{type}</div>
            </Col>
          </Row>
          <Row
            lg={12}
            style={{
              marginLeft: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            <DPround profileImage={''} page='small' />

            <Col>
              <div className='destitle'>Note</div>
              <div className='description'>{note}</div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Details;
