import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, Nav, Button } from 'react-bootstrap';
import '@themes/CommonApplicationStyles.css';
import '@features/nftdetail/NftdetailStyles.css';
import '@components/detailstab/DetailstabStyles.css';
import moment from 'moment';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import DPround from '@components/dpround/DPround';

function History({ historydetails, nft, ownerList, creator }) {
  const history = useHistory();
  const [key, setkey] = useState('4');
  const { productInfo } =
    (history && history.location && history.location.state) || {};
  const { id, title, amount, images } = productInfo || {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formatDateTime = ({
    value,
    format = ' DD/MM/YYYY hh:mm:ss',
    defaultValue = '',
  }) => {
    if (!moment(value).isValid()) {
      return defaultValue;
    }
    var removesevenhrs = moment(value).add(0, 'h');
    var fromNow = moment(removesevenhrs).fromNow();
    fromNow = fromNow.replace('an hour', '1 hour');
    fromNow = fromNow.replace('a minute', '1 minute');
    fromNow = fromNow.replace('a day', '1 day');
    fromNow = fromNow.replace('a month', '1 month');
    return moment(value).calendar(null, {
      lastWeek: '[' + fromNow + ']',
      lastDay: '[1 day ago]',
      sameDay: '[' + fromNow + ']',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      sameElse: function () {
        return '[' + fromNow + ']';
      },
    });
  };
  var profilePic2;
  creator &&
    creator.profileImage &&
    creator.profileImage.profileimage &&
    creator.profileImage.profileimage.map((pic) => {
      if (pic.size == 'thumbnail') {
        profilePic2 = pic.link;
      }
    });
  console.log(profilePic2);
  return (
    <div>
      <Container>
        {historydetails &&
          historydetails.map((b) => {
            let profilePic;
            b &&
              b.owner &&
              b.owner.profileImage &&
              b.owner.profileImage.profileimage &&
              b.owner.profileImage.profileimage.map((pic) => {
                if (pic.size == 'thumbnail') {
                  profilePic = pic.link;
                }
              });
            return (
              <div className='content'>
                <Row
                  lg={12}
                  style={{
                    marginLeft: '20px',
                    marginBottom: '20px',
                    marginTop: '10px',
                  }}
                >
                  <DPround profileImage={profilePic} page='small' />

                  <Col>
                    <div className='destitle'>Transfered</div>
                    <div className='description'>
                      From{' '}
                      <span
                      // className='linkhover'
                      // onClick={() => {
                      //   localStorage.setItem(
                      //     'other-user-name',
                      //     b.seller && b.seller.userName
                      //   );
                      //   history.push('/othersprofile');
                      // }}
                      >
                        {b.seller && b.seller.userName}
                      </span>{' '}
                      To{' '}
                      <span
                      // className='linkhover'
                      // onClick={() => {
                      //   localStorage.setItem(
                      //     'other-user-name',
                      //     b.owner && b.owner.userName
                      //   );
                      //   history.push('/othersprofile');
                      // }}
                      >
                        {b.owner && b.owner.userName}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        <Row
          lg={12}
          style={{
            marginLeft: '20px',
            marginBottom: '20px',
            marginTop: '10px',
          }}
        >
          <DPround profileImage={profilePic2} page='small' />
          <Col>
            <div className='destitle'>Created by</div>
            <div
              className='description'
              className='linkhover'
              onClick={() => {
                localStorage.setItem('other-user-id', creator && creator.uuid);
                localStorage.setItem(
                  'other-user-name',
                  creator && creator.userName
                );
                history.push('/othersprofile');
              }}
            >
              <span>
                {creator && creator.uuid}({creator && creator.userName})
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default History;
