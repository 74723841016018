import '@components/tab/TabStyle.css';
import React, { Fragment } from 'react';
import Nav from 'react-bootstrap/Nav';
function Tab({ onSelect, page }) {
  return (
    <Fragment>
      <div className='profilenavbar'>
        {page == 'profile' && (
          <Nav
            className='mr-auto'
            defaultActiveKey='1'
            onSelect={(selectedKey) => onSelect(selectedKey)}
          >
            <Nav.Link eventKey='1'>My NFT</Nav.Link>

            <Nav.Link eventKey='2'>On Sale</Nav.Link>

            {/*             
            <Nav.Link eventKey="4">Activity</Nav.Link> */}
          </Nav>
        )}
      </div>
      <div className='explorernavbar'>
        {page == 'explorer' && (
          <Nav
            className='mr-auto'
            defaultActiveKey='1'
            onSelect={(selectedKey) => onSelect(selectedKey)}
          >
            <Nav.Link eventKey='1'>All</Nav.Link>

            <Nav.Link eventKey='2'>Art</Nav.Link>
            <Nav.Link eventKey='3'>Photography</Nav.Link>

            <Nav.Link eventKey='4'>Portrait</Nav.Link>
            <Nav.Link eventKey='5'>Music</Nav.Link>
          </Nav>
        )}
      </div>

      <div className='detailsnavbar'>
        {page == 'nftdetails' && (
          <Nav
            className='mr-auto'
            defaultActiveKey='1'
            onSelect={(selectedKey) => onSelect(selectedKey)}
          >
            <Nav.Link eventKey='1'>Details</Nav.Link>

            {/* <Nav.Link eventKey="2">Bids</Nav.Link> */}
            <Nav.Link eventKey='3'>Owners</Nav.Link>

            <Nav.Link eventKey='4'>History</Nav.Link>
          </Nav>
        )}
      </div>

      <div className='portfolionavbar'>
        {page == 'portfolio' && (
          <Nav
            className='mr-auto'
            defaultActiveKey='3'
            onSelect={(selectedKey) => onSelect(selectedKey)}
          >
            <Nav.Link eventKey='3'>Creator</Nav.Link>

            <Nav.Link eventKey='4'>Creator and Owner</Nav.Link>
            <Nav.Link eventKey='5'>Owner</Nav.Link>
          </Nav>
        )}
      </div>
      <div className='portfolionavbar'>
        {page == 'othersportfolio' && (
          <Nav
            className='mr-auto'
            defaultActiveKey='13'
            onSelect={(selectedKey) => onSelect(selectedKey)}
          >
            <Nav.Link eventKey='13'>Creator</Nav.Link>

            <Nav.Link eventKey='14'>Creator and Owner</Nav.Link>
            <Nav.Link eventKey='15'>Owner</Nav.Link>
          </Nav>
        )}
      </div>
    </Fragment>
  );
}

export default Tab;
