import React, { useState, useRef, useEffect } from "react";

import "@themes/CommonApplicationStyles.css";
//import '@features/create-post/CreatePostStyles';
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

// Components
import ShareSomething from "@components/share-something/ShareSomething";
import InputField from "@components/input-field/InputField";

import CustomButton from "@components/custom-button/CustomButton";
import photo from "@images/photo.png";
import camera from "@images/camera.png";
import * as _ from "lodash";
import { connect } from "react-redux";
import NavBar from "@navigation/NavBar";
import "@features/profile/profile-info/ProfileInfoStyles.css";
import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { CgCloseO } from "react-icons/cg";


function ProfileInfo({

}) {

 

 

  return (
    <div>
      <NavBar location={useLocation()} />
      <Container style={{marginTop:'100px'}}>

      <Row className="justify-content-md-center">
          <Col lg={8}>
            <h3 className="mb-4 text-center">Create Profile</h3>
     
         
       
            <InputField
      
           
              type="text"
      
              placeholder="Name"
            />
     

        
            <InputField
      
           
              type="text"
            
              placeholder="About"
            />
        
            <InputField
      
           
              type="text"
            
              placeholder="Email"
            />
         
          <InputField
                
                type="Phone"
                placeholder={"Phone"}
            
                required
              
              />
           
              <label>Profile Image :</label>&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="file"
        
        />
    

          

           
     
        

       
   <Col lg={6}  className="desktopbtn" >
            <CustomButton
              type="Post"
              filled={true}
              primary={true}
              name="Save"
              
         
   
            />
          </Col>
     
          </Col>
        </Row>
      </Container>
    </div>
  );
}



export default ProfileInfo;
