import React, { useState } from "react";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/popup/PopUpStyle.css";
import { connect } from "react-redux";
import * as _ from "lodash";
import { Form, Col } from "react-bootstrap";

function PopUp({ editType, editUser, userInfo, onclose }) {
  const [userName, setuserName] = useState(userInfo && userInfo.userName);
  const [title, setTitle] = useState(
    userInfo && userInfo.userprofile && userInfo.userprofile.title
  );
  const [location, setLocation] = useState(
    userInfo && userInfo.userprofile && userInfo.userprofile.location
  );
  const [age, setAge] = useState(
    userInfo && userInfo.userprofile && userInfo.userprofile.age
  );
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const editUserHandle = () => {
    if (editType === "username") {
      if (_.isEmpty(userName)) {
        setError("Username cannot be empty");
      } else
        editUser({
          item: { userName },
        });
    } else if (editType === "others") {
      const Age = parseInt(age);
      editUser({
        item: { title, location, Age },
      });
    }

    onclose();
  };

  return (
    <div className="pop">
      <div className="popupcontent">
        <Form noValidate validated={validated} onSubmit={editUserHandle}>
          {editType == "username" && (
            <div className="insidefield">
              <InputField
                type="Email"
                placeholder="Username"
                value={userName}
                required
                minlength={20}
                error={"Username cannot be empty"}
                onChange={(e) => {
                  let name = e.target.value;
                  name = name.replace(" ", "");

                  setuserName(name);
                }}
              />
            </div>
          )}
          {editType == "others" && (
            <div>
              <div className="insidefield">
                <InputField
                  type="Email"
                  placeholder="Title"
                  value={title}
                  minlength={20}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="insidefield">
                <InputField
                  type="Email"
                  placeholder="Location"
                  minlength={15}

                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div className="insidefield">
                <InputField
                  type="Email"
                  placeholder="Age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
            </div>
          )}
          {error && <div>{error}</div>}
          <CustomButton
            type="submit"
            filled={true}
            primary={true}
            name="Save"
            onClick={() => editUserHandle()}
          />
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const userInfo = _.get(user, "userInfo", "");
  return { userInfo };
};

const mapDispatchToProps = (dispatch) => ({
  editUser: (data) => dispatch({ type: "EditUserCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
