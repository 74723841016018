export const ICON_TYPES = {
  AntDesign: 'AntDesign',
  IonIcons: 'Ionicons',
  FontAwesome: 'FontAwesome',
  FontAwesome5: 'FontAwesome5',
  SimpleLineIcons: 'SimpleLineIcons',
  MaterialIcons: 'MaterialIcons',
  MaterialCommunityIcons: 'MaterialCommunityIcons',
  Entypo: 'Entypo',
  EvilIcons: 'EvilIcons',
  Octicons: 'Octicons',
  Feather: 'Feather',
};

export const URL = {
  API: 'https://nftapi.sparkrex.com/api',
};

export const SCREEN_KEYS = {
  WIZARD: 'WIZARD',
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  FORGOT_PASSWORD_SCREEN: 'FORGOT_PASSWORD_SCREEN',
  PROFILE_SCREEN: 'PROFILE_SCREEN',
  EDIT_PROFILE_SCREEN: 'EDIT_PROFILE_SCREEN',
  TOU: 'Terms of use',
  PRIVACY: 'privacy policy',
  ALLNOTIFICATION_SCREEN: 'ALLNOTIFICATION_SCREEN',
  CART: 'CART',
  CHECKOUT: 'CHECKOUT',
  CONTACT_US: 'CONTACT_US',
  HOMESCREEN: '/',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  PRODUCT_LIST: 'PRODUCT_LIST',
  SPLASH_SCREEN: 'SPLASHSCREEN',
  MY_ACCOUNT: 'MY_ACCOUNT',
  LOCATION: 'LOCATION',
  MYORDER_SCREEN: 'MYORDER_SCREEN',
  ORDER_DETAIL: 'ORDER_DETAIL',
  PAYMENT_SCREEN: 'PAYMENT_SCREEN',
  ADDRESS: 'ADDRESS',
  ALLPRODUCT_SCREEN: 'ALLPRODUCT_SCREEN',
  ORDERPLACED_SCREEN: 'ORDERPLACED_SCREEN',
  ALLCAT_SCREEN: 'ALLCAT_SCREEN',
  SEARCH_SCREEN: 'SEARCH_SCREEN',
};

export const FORM_KEYS = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  EDIT_PROFILE: 'EDIT_PROFILE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  OTP: 'OTP',
};

export const LABELS = {
  EMAIL: 'Email',
  PASSWORD: 'Password',
  PASSWORD_AGAIN: 'Password Again',
  NAME: 'Username',
  LOCATION1: 'City,State',
  LOCATION: 'Location',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  OCCUPATION: 'Occupation',
  DESCRIPTION: 'What are you up to?',
  BIO: 'Bio',
  SKILLS: 'Skills',
  SKILL: 'Skill',
  WELCOME_SIGN_UP: 'Welcome to \nEcom App',
  WELCOME_SIGN_IN: 'Welcome \nBack',
  SKIP: 'Skip',
  SIGN_IN_1: 'Return to sign in',
  INTERESTS: 'Interests',
  SIGN_IN: 'Sign In',
  SIGN_UP: 'Sign Up',
  FORGET_PASSWORD: 'Forgot password?',
  FORGET_PASSWORD_1: 'Reset password',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  ALREADY_HAVE_ACCOUNT: 'Have already an account?',
  LETS_START_NEW_ACCOUNT: 'Lets start with creating your new account.',
  SHARE_ABOUT_YOURSELF: 'please share some bits about yourself',
  NEXT: 'Next',
  DONE: 'Done',
  SPAM: 'Spam',
  Copy: 'Copyright Infringement',
  Drugs: 'Drugs or Self Harm',
  Violence: 'Violence or Harm',
  Hate: 'Harassment Bullying and Hate',
  Weapons: 'Terrorism, Violence or Weapons',
  RESET_PASSWORD: 'Reset My Password',
  YOUR_INTEREST: 'what topics are you interested to see on your feed',
  Enter_Email: 'Enter Mobile Number',
  Enter_OTP: 'Enter OTP',
  New_Password: 'Enter New Password',
};

export const ERRORS = {
  SERVER_NOT_RESPONDING: 'Try After Sometime',
};

export const MESSAGES = {
  SIGNUP_SUCCESSFUL: 'Sign up successful Please verify Email',
  SOME_FIELDS_EMPTY: 'Some fields cannot be empty',
  SKILL_ADDED: 'Skill added',
  ENTER_INVALID: 'Email is invalid',
  MOBILE_INVALID: 'Mobile Number is invalid',
  Name_INVALID: 'Name cannot be empty',
  PASSWORD_INVALID: 'Invaild Password ',
  PASSWORD_NOT_MATCH: 'Password does not match',
  OTP_INVALID: 'Invalid OTP',
};
export const CATEGORY_LIST = [
  { id: 1, label: 'Chicken' },
  { id: 2, label: 'Eggs' },
  { id: 3, label: 'Mutton' },
  { id: 4, label: 'Fish' },

  // {id: 5, label: 'Cold cuts'},
  // {id: 6, label: 'Kebabs'},
];
export const RECENTNFTLIST = [
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB2',
    title: 'Flash-bot2 ',
    amount: 300,
    images: 'art2.jpg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB3',
    title: 'Flash-bot3',
    amount: 100,
    images: 'art3.jpg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB9',
    title: 'Flash-bot3',
    amount: 60,
    images: 'art4.jpeg',
  },
];

export const NFTLIST = [
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB4',
    title: 'Deliberation art print ',
    amount: 22,
    images: 'art4.jpeg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB2',
    title: 'Flash-bot2 ',
    amount: 300,
    images: 'art2.jpg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB',
    title: 'Flash-bot3',
    amount: 100,
    images: 'art3.jpg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB',
    title: 'Cat',
    amount: 60,
    images: 'art6.jpeg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB9',
    title: 'Hello world',
    amount: 30,
    images: 'art5.jpeg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB1',
    title: 'Flash-bot[Reverse mod Deliberation art print sddnsds',
    amount: 100,
    images: 'art1.jpeg',
  },
];

export const MYNFTLIST = [
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB1',
    title: 'Flash-bot[Reverse mod Deliberation art print sddnsds',
    amount: 100,
    images: 'art1.jpeg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB2',
    title: 'Flash-bot2',
    amount: 100,
    images: 'art2.jpg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB3',
    title: 'Flash-bot3',
    amount: 100,
    images: 'art3.jpg',
  },
];

export const SALENFTLIST = [
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB2',
    title: 'Flash-bot2',
    amount: 100,
    images: 'art2.jpg',
  },
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB3',
    title: 'Flash-bot3',
    amount: 100,
    images: 'art3.jpg',
  },
];
export const CREATEDNFTLIST = [
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB1',
    title: 'Flash-bot[Reverse mod',
    amount: 100,
    images: 'art1.jpeg',
  },
];
export const LIKENFTLIST = [
  {
    id: '0x53F3203c7e3B6c93FCe3811Ce17E31FABeF154bB4',
    title: 'Deliberation art print ',
    amount: 22,
    images: 'art4.jpeg',
  },
];
