import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, Nav, Button, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import Tab from '@components/tab/Tab.js';
import Loader from '@components/loader/Loader';
import ProductCard from '@components/product-card/ProductCard';
import NavBar from '@navigation/NavBar';
import '@themes/CommonApplicationStyles.css';
import '@features/explore/ExploreStyles.css';

const sdk = require('dragonchain-sdk');
function Portrait({ list }) {
  const [key, setkey] = useState('1');
  const [loader, setLoader] = useState(false);

  return (
    <div>
      <Container style={{ marginTop: '20px' }}>
        <div className='content'>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              {loader && <Spinner animation='border' variant='primary' />}
            </Col>
          </Row>

          <Row>
            {list
              .filter((li) => {
                if (li && li.nft && li.nft.type == 'Portrait') {
                  return li;
                }
              })
              .map((item, index) => (
                <Col lg={4} sm={6}>
                  <ProductCard
                    key={index}
                    productInfo={item}
                    myprofile={false}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Portrait;

// var nftDetails = nftResult.response.results.reduce(
//   (latest, current) => {
//     return latest > current ? latest : current;
//   },
//   nftResult.response.results[0].payload.timestamp
// );
