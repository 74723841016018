import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import NavBar from '@navigation/NavBar';
import Tab from '@components/tab/Tab.js';
import MyItems from '@components/profilelists/MyItems';
import DPround from '@components/dpround/DPround';
import OnSale from '@components/profilelists/OnSale';
import ProductCard from '@components/product-card/ProductCard';
import Created from '@components/profilelists/Created';
import Likes from '@components/profilelists/LIkes';
import Activity from '@components/profilelists/Activity';
import Creator from '@components/portfoliomynfttabs/Creator';
import CreatorandOwner from '@components/portfoliomynfttabs/CreatorandOwner';
import { Row, Col, Card, Nav, Button, Spinner } from 'react-bootstrap';

import Owner from '@components/portfoliomynfttabs/Owner';
const sdk = require('dragonchain-sdk');
import { useHistory } from 'react-router-dom';

function Portfolio({}) {
  const history = useHistory();
  const [key, setkey] = useState('1');
  const [loader, setLoader] = useState(false);
  const [insidekey, setinsidekey] = useState(3);
  const [buyerid, setBuyer] = useState('');
  const [sellerid, setSeller] = useState('');
  const [amountt, setAmount] = useState('');
  const [id, setId] = useState('');
  const [username, setuserName] = useState('');
  const [mynft, setmynft] = useState([]);
  var user;
  var profileImage;
  const image = JSON.parse(localStorage.getItem('my-profile-image'));
  image &&
    image.profileimage &&
    image.profileimage.map((img) => {
      if (img.size == 'thumbnail') {
        profileImage = img.link;
      }
    });
  useEffect(() => {
    user = JSON.parse(localStorage.getItem('user'));
    // const token = localStorage.getItem('accessToken');
    // await axios
    //   .get(`${URL.API}/nft/recent?skip=0&limit=10`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then(function (response) {
    //     localStorage.setItem('recent_nft', response.data.finalList);
    //     setRecent(response.data.finalList);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);
  return (
    <div>
      <NavBar location={useLocation()} />

      <Container style={{ marginTop: '20px' }}>
        <DPround
          page='myportfolio'
          loader={loader}
          setLoader={setLoader}
          profileImage={profileImage}
        />
        <Col style={{ textAlign: 'center' }}>
          {loader && <Spinner animation='border' variant='primary' />}
        </Col>
        <Col>
          <p style={{ textAlign: 'center', paddingTop: '15px' }}>
            {JSON.parse(localStorage.getItem('user')) &&
              JSON.parse(localStorage.getItem('user')).username}
          </p>
          <p style={{ textAlign: 'center' }}>
            {JSON.parse(localStorage.getItem('user')) &&
              JSON.parse(localStorage.getItem('user')).uuid}
          </p>
        </Col>
        <div className='content mt-4'>
          <Tab
            page='profile'
            onSelect={(res) => setkey(res)}
            history={history}
          />
          {/* <Tab
            page="portfolio"
            onSelect={(res) => setkey(res)}
            history={history}
          /> */}

          {key == 1 && (
            <Tab
              page='portfolio'
              onSelect={(res) => setinsidekey(res)}
              history={history}
            />
          )}

          {insidekey == 3 && key == 1 && <Creator />}

          {insidekey == 4 && key == 1 && <CreatorandOwner />}

          {insidekey == 5 && key == 1 && <Owner />}

          {key == 2 && <OnSale />}
        </div>
      </Container>
    </div>
  );
}

export default Portfolio;
