import { call, put } from "redux-saga/effects";
import {
  loginSuccess,
  loginFail,
  signupFail,
  signupSuccess,
  forgotPasswordSuccess,
  forgotPasswordFail,
  profileTrigger,
  profileSuccess,
  profileFail,
  signUpTrigger,
  loginTrigger,
  forgotdone,
  forgotPasswordTrigger,
  editUserFail,
  editUserSuccess,
  editUserTrigger,
  updateProfileImageSuccess,
  updateProfileImageTrigger,
  updateBannerImageSuccess,
  updateBannerImageTrigger,
  myFollowingIdSuccess,
  logoutSuccess,
  followTrigger,
  unfollowTrigger,
  otpverifyTrigger,
  likesmyId,
  otpverifyFail,
} from "./userredux";
import {singleProfileTrigger, myFeedTrigger, } from './othersredux';
import { changeScreenSuccess } from "./screentrackerredux";
import {SCREEN_KEYS} from '@utilities/Constants';
import Api from "./../services/ApiCaller";
import { changeform } from "./screentrackersaga";
import { getAccessToken, getUserId} from "./utilities";

export function* signIn(api, { payload = {} }) {
  yield put(loginTrigger(true));
  let erromsg = "";
  try {
    const { navigation, email, userName, password, loginType, mobile, } = payload;
    let data = {
      userName,
      password,
      loginType,
    };
    if (loginType == 'mobile') data.mobile = mobile;
    else data.email = email;
    const result = yield call(
      Api.callServer,
      api.signIn,
      data,
      true
    );
    console.log(result);
    yield put(loginSuccess(result));
    const token = yield getAccessToken()
    const result2 = yield call(Api.callServer, api.getUser, {token}, true);
    console.log(result)
    yield put(profileSuccess(result2));
    navigation.push("/");
  } catch (errorPayload) {
    if (errorPayload.message === "Invalidpassword") {
      erromsg = "Invaild Password ";
    } else if (errorPayload.message === "usernotexist") {
      erromsg = "Email does not exist";
    } else if (errorPayload.message === "emailNotVerified") {
      erromsg = "Email is not verified";
    }
    else if (errorPayload.message === "mobilenotexist") {
      erromsg = "Mobile does not exist"
    }
    else {
      erromsg = errorPayload.message;
    }
    yield put(loginFail(erromsg));
  } finally {
    yield put(loginTrigger(false));
  }
}

export function* signUp(api, { payload = {} }) {
  yield put(signUpTrigger(true));
  let errormsg = "";
  try {
    const {
      navigation,
      email,
      userName,
      password,
      loginType,
      sendVerification,
      formkey,
      mobile,
      profileType,
    } = payload;

    let data = {
      userName,
      password,
      loginType,
      sendVerification,
      profileType,
    };
    if (loginType == 'mobile') data.mobile = mobile;
    else data.email = email;
    console.log(navigation,'navigation');
    const result = yield call(
      Api.callServer,
      api.signUp,
      data,
      true
    );
    console.log("usersaga", result);
    yield put(signupSuccess(result));
   // history.push(`verification`)
   if (loginType == 'mobile'){
    navigation.push("/otp", {mobile});
    console.log(navigation,'navigation-mobile');
   }if (loginType == 'email'){
    navigation.push("/verification"); 
    console.log(navigation,'navigation');
   }
   
  } catch (errorPayload) {
    if (errorPayload.message === "EmailUsed") {
      errormsg =
        "The Email You have Entered is already in use. \nKindly sign in";
    } 
    else if (errorPayload.message == "MobileUsed") {
      errormsg =
        "The Mobile Number You have Entered is already in use. \nKindly sign in";
    }
    else {
      errormsg = errorPayload.message;
    }
    console.log(errormsg);
    yield put(signupFail(errormsg));
  } finally {
    yield put(signUpTrigger(false));
  }
}

export function* forgotPassword(api, { payload = {} }) {
  yield put(forgotPasswordTrigger(true));
  try {
    const { loginType, navigation, email, mobile } = payload;
    // console.log("forgotPassword", payload)
    let data = {
      loginType,
    };
    if (loginType == 'mobile') data.mobile = mobile;
    else data.email = email;
    const result = yield call(
      Api.callServer,
      api.forgotpassword,
      data,
      true
    );
    yield put(forgotPasswordSuccess(result));
    if (loginType == 'mobile')
    navigation.push("/otp", {mobile,reset:true});
  } catch (e) {
    console.log(e);
    yield put(forgotPasswordFail(e.message));
  } finally {
    yield put(forgotPasswordTrigger(false));
  }
}
export function* setForgetdone(api, { payload = {} }) {
  try {
    yield put(forgotdone(false));
  } catch (e) {}
}

export function* getUser(api, {payload = {}}) {
  yield put(profileTrigger(true))
  try {
    const token = yield getAccessToken();
    const result = yield call(Api.callServer, api.getUser, {token}, true);
    console.log(result)
    yield put(profileSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(profileFail(error));
  } finally {
    yield put(profileTrigger(false))
  }
}
 
export function* editUser(api, {payload = {}}) {
  yield put(editUserTrigger(true))
  const {
    item
  } = payload
  try {
    console.log('Edit User function')
    const token = yield getAccessToken()
    console.log('Token',token)
    // const userId = yield getUserId();
    const result = yield call(Api.callServer, api.editUser, {...item, token }, true);
    console.log(result)
    yield put(editUserSuccess(result));
  } catch(error) {
    console.log(error)
    // yield put(editUserFail(error))
  } 
  // finally {
    yield put(editUserTrigger(false))
  // }
}

export function* VerificationOtp(api, {payload = {}}) {
  yield put(otpverifyTrigger(true));
  let errormsg = "";
  try {
    const {mobile, otp,navigation,reset, type,loginType} = payload;
    console.log('payload',payload)
    const result = yield call(
      Api.callServer,
      api.verifyotp,
      {mobile, otp},
      true,
    );
    console.log(result, 'verify otp');

   if(reset){
    navigation.push("/reset", {mobile, otp});
   }else if(type==='mobile' && loginType!='mobile'){
    navigation.push("/"); 
   }else if(type==='mobile' && loginType==='mobile'){
    navigation.push("/signin"); 
   }else{
    navigation.push("/verification"); 
   }
  
   console.log(navigation,'navigation');
  } catch (error) {
    if (error.message === "OtpNotMatched") {
      errormsg =
        "Otp Not Matched";
    }else if(error.message==="OtpExpired"){
      errormsg =
      "Otp Expired";
    }
    console.log('resend Errorr', error);
    yield put(otpverifyFail(errormsg));
  } finally {
    yield put(otpverifyTrigger(false));
  }
}

export function* updateProfileImage(api, {payload = {}}) {
  yield put(updateProfileImageTrigger(true))
  try {
    console.log('Update Profile Image Saga',payload)
    const {image} = payload
    const token = yield getAccessToken();
    // console.log('Image', image)
    const result = yield call(Api.callServer, api.updateProfileImage, {payload, token}, true);
    const result2 = yield call(Api.callServer, api.getUser, {token}, true);
    yield put(profileSuccess(result2));
    yield put(updateProfileImageSuccess(result))
    console.log('Update ProfileImage Result', result)
  } catch (error) {
    console.log('Update Image Errorr', error);
  } finally {
    yield put(updateProfileImageTrigger(false))
  }
}

export function* updateBannerImage(api, {payload = {}}) {
  yield put(updateBannerImageTrigger(true))
  try {
    console.log('Update Banner Image Saga')
    const {image} = payload
    const token = yield getAccessToken();
    // console.log('Image', image)
    const result = yield call(Api.callServer, api.updateBannerImage, {payload, token}, true);
    const result2 = yield call(Api.callServer, api.getUser, {token}, true);

    yield put(profileSuccess(result2));
    console.log('Update Banner Result', result)
    yield put(updateBannerImageSuccess(result))
  } catch (error) {
    console.log('Update Image Errorr', error);
  } finally {
    yield put(updateBannerImageTrigger(false))
  }
}


export function* followRequest(api, {payload = {}}) {
  yield put(followTrigger({"status": "acc", "targetId": payload.id}));
   try {
     console.log('followRequest',payload)
     const {id} = payload;
     const token = yield getAccessToken();
     const result = yield call(
       Api.callServer,
       api.followRequest,
       {id, token},
       true,
     );
     const result2 = yield call(Api.callServer, api.getUser, {token}, true);
     yield put(profileSuccess(result2));
   } catch (error) {
     console.log('resend Errorr', error);
     // yield put(resendVerificationFail(error));
   } finally {
     yield put(singleProfileTrigger(false));
   }
 }
 
 export function* acceptFollowRequest(api, {payload = {}}) {
   yield put(singleProfileTrigger(true));
   try {
     const {id} = payload;
     const token = yield getAccessToken();
     const result = yield call(
       Api.callServer,
       api.acceptFollowRequest,
       {id, token},
       true,
     );
     // yield put(profileSuccess(result));
   } catch (error) {
     console.log('resend Errorr', error);
     // yield put(resendVerificationFail(error));
   } finally {
     yield put(singleProfileTrigger(false));
   }
 }
 
 export function* unfollow(api, {payload = {}}) {
   yield put(unfollowTrigger(payload.id));
   try {
     const {id} = payload;
     const token = yield getAccessToken();
    // let Id = parseInt(id)
    // console.log('Id',Id)
     const result = yield call(Api.callServer, api.unfollow, {id, token}, true);
    const result2 = yield call(Api.callServer, api.getUser, {token}, true);
     //yield put(profileSuccess(result));
    yield put(profileSuccess(result2));
   } catch (error) {
     console.log('resend Errorr', error);
     // yield put(resendVerificationFail(error));
   } finally {
     yield put(singleProfileTrigger(false));
   }
 }
 
 export function* getMyFollowingid(api, {payload = {}}) {
   yield put(singleProfileTrigger(true));
   try {
     const token = yield getAccessToken();
     const result = yield call(
       Api.callServer,
       api.getMyFollowingid,
       {token},
       true,
     );
     yield put(myFollowingIdSuccess(result));
   } catch (error) {
     console.log(error);
   } finally {
     yield put(singleProfileTrigger(false));
   }
 }
 
 export function* logout(api, {payload = {}}) {
   try {
     yield put(logoutSuccess());
     yield put(changeScreenSuccess(SCREEN_KEYS.SIGN_IN));
 
     payload.reset({
       index: 0,
       routes: [{name: SCREEN_KEYS.SIGN_IN}],
     });
   } catch (error) {
     console.log('resend Errorr', error);
     // yield put(resendVerificationFail(error));
   } finally {
     yield put(singleProfileTrigger(false));
   }
 }

 export function* setpasswordmobile(api, {payload = {}}) {
 // yield put(otpverifyTrigger(true));
  try {
    const {mobile, newPassword,otp,navigation,} = payload;
    console.log('payload',payload)
    const result = yield call(
      Api.callServer,
      api.setnewpasswordmobile,
      {mobile,newPassword},
      true,
    );
     navigation.push("/signin"); 
  } catch (error) {
    console.log('resend Errorr', error);
   // showToast(error.message);
  } finally {
    yield put(otpverifyTrigger(false));
  }
}

export function* getMyLikesId(api, {payload = {}}) {
  yield put(myFeedTrigger(true));
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.myLikesId,
      {token},
      true,
    );
    yield put(likesmyId(result));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(myFeedTrigger(false));
  }
}

export function* updateEmailMobile(api, {payload = {}}) {
  let erromsg=""; 
  yield put(editUserTrigger(true));
  const {
    mobile,
    email,
    navigation,
    loginType,
    type,
  } = payload;
  try {

    
    const token = yield getAccessToken();
     console.log('mobile',mobile)
     console.log(loginType,'loginType')
     console.log(type,'type')
    
    // const userId = yield getUserId();
    if(type==="email"){
      const result = yield call(
        Api.callServer,
        api.updateEmailMobile,
        {email, token},
        true,
      );
      console.log(result,'resultresult email')
      yield put(editUserSuccess(result));
    } else{
      const result = yield call(
        Api.callServer,
        api.updateEmailMobile,
        {mobile, token},
        true,
      );
      yield put(editUserSuccess(result));
      console.log(result,'resultresult mobile')
    }
    if (type==="email"){
      //login via email and changed email
      navigation.push("/verification"); 
  }
  else {
    navigation.push("/otp", {mobile,type,loginType});
  }
  
  } catch (error) {
    console.log(error);
    if(type==="email"){
      erromsg = "Email already in use";
    }else
    erromsg = "Mobile already in use";
   yield put(editUserFail(erromsg))
  }
   finally {
  yield put(editUserTrigger(false));
   }
}
 



 
 
