
import React from 'react'
import './LoderStyle.css'
import {BounceLoader,  BeatLoader} from 'react-spinners'


function Loader() {
    return (
        <div className="load">
       <div className="loader">
         <BeatLoader   size={24} color='pink' loading />   
         </div>
        </div>
    )
}

export default Loader