import { Row, Col, Card, Nav, Button, Spinner } from 'react-bootstrap';
import React, { Fragment, useState, useEffect } from 'react';
import ProductCard from '@components/product-card/ProductCard';
import { MYNFTLIST } from '@utilities/Constants';
import Modal from 'react-modal';
import { CgCloseO } from 'react-icons/cg';
import PopUp from '@components/popup/PopUp';
import PopUpPrice from '@components/popup/PopUpPrice';
const sdk = require('dragonchain-sdk');


const MyItems = () => {
  const [list, setlist] = useState([]);
  const [loader, setLoader] = useState(false);

  const [modal, setmodal] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [editType, seteditType] = useState('');

  function openModal() {
    setmodalIsOpen(true);
  }
  function closeModal() {
    setmodalIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '52%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: '1px solid rgb(204 204 204 / 22%)',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 0px 20px 0px #dee2e669',
      borderRadius: '30px',
    },
  };

  useEffect(async () => {
    setLoader(true);
    let userid = localStorage.getItem('userid');
    let client = await sdk.createClient({
      dragonchainId: 'uUYEn4SQWrAi13UnHpgMoUyrGw1eF9H65dWnEtiZGmbP',
      authKey: 'Cl0T8ydDJUt29QLtUN7GcE39PAGrmASu2kzdj9nZBjs',
      authKeyId: 'VYCJCYLHYOHH',
      endpoint:
        'https://98d256d8-e143-40a5-a3bd-c6c5fc19c2f8.api.dragonchain.com',
    });

    const searchResult = await client.queryTransactions({
      transactionType: 'Createnft',
      redisearchQuery: `@Ownerid:${userid}`,
    });
    console.log(searchResult);
    let nftlists = [];
    searchResult.response.results.map(async (items, index) => {
      const nftResult = await client.queryTransactions({
        transactionType: 'NFT-ADD-INFO',
        redisearchQuery: `@Nftid:${items.payload.nftid}`,
      });
      //console.log("called 3");
      const nftResult1 = await client.queryTransactions({
        transactionType: 'NFT-ADD-FILE',
        redisearchQuery: `@Nftid:${items.payload.nftid}`,
      });

      let position;
      let largest;
      let positionfile;
      let largestfile;

      if (nftResult.response.total != 0 && nftResult.response.results) {
        position = 0;
        largest = nftResult.response.results[0].payload.timestamp;

        for (let i = 1; i < nftResult.response.results.length; i++) {
          if (largest < nftResult.response.results[i].payload.timestamp) {
            largest = nftResult.response.results[i].payload.timestamp;
            position = i;
          }
        }

        if (nftResult1.response.total != 0 && nftResult1.response.results) {
          positionfile = 0;
          largestfile = nftResult1.response.results[0].payload.timestamp;

          for (let i = 1; i < nftResult1.response.results.length; i++) {
            if (
              largestfile < nftResult1.response.results[i].payload.timestamp
            ) {
              largestfile = nftResult1.response.results[i].payload.timestamp;
              positionfile = i;
            }
          }
          nftlists.push({
            nft: items.payload,
            nftDetails: nftResult.response.results[position].payload,
            image: nftResult1.response.results[positionfile].payload.filename,
          });
        } else {
          nftlists.push({
            nft: items.payload,
            nftDetails: nftResult.response.results[position].payload,
            image: 'no image',
          });
        }
      } else if (
        nftResult1.response.total != 0 &&
        nftResult1.response.results
      ) {
        positionfile = 0;
        largestfile = nftResult1.response.results[0].payload.timestamp;

        for (let i = 1; i < nftResult1.response.results.length; i++) {
          if (largest < nftResult1.response.results[i].payload.timestamp) {
            largestfile = nftResult1.response.results[i].payload.timestamp;
            positionfile = i;
          }
        }

        if (nftResult.response.total != 0 && nftResult.response.results) {
          position = 0;
          largest = nftResult.response.results[0].payload.timestamp;

          for (let i = 1; i < nftResult.response.results.length; i++) {
            if (largest < nftResult.response.results[i].payload.timestamp) {
              largest = nftResult.response.results[i].payload.timestamp;
              position = i;
            }
          }
          nftlists.push({
            nft: items.payload,
            nftDetails: nftResult.response.results[position].payload,
            image: nftResult1.response.results[positionfile].payload.filename,
          });
        } else {
          nftlists.push({
            nft: items.payload,
            nftDetails: 'no details',
            image: nftResult1.response.results[positionfile].payload.filename,
          });
        }
      }

      setlist((prev) => [...nftlists]);
    });
    /// setlist(nftlists);
    setLoader(false);

    console.log('List', list);
  }, []);

  return (
    <Fragment>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div onClick={closeModal} style={{ textAlign: 'right' }}>
          <CgCloseO size={'20px'} className={'absolutecross'} />
        </div>
        <PopUpPrice
          editType={editType}
          onclose={() => {
            setmodalIsOpen(false);
          }}
        />
      </Modal>
      <Col sm='4' className='mb-3'>
        {loader && <Spinner animation='border' variant='primary' />}
      </Col>
      <Row>
        {list.map((item, index) => (
          <Col lg={4} sm={6}>
            {/* <div onClick={openModal}> */}
            <div>
              <ProductCard key={index} productInfo={item} myitems myprofile />
            </div>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default MyItems;
