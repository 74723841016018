import {call, put} from 'redux-saga/effects';
import {
    recentProfileSuccess,
    recentProfileTrigger,
    singleProfileTrigger,
    otherProfileSuccess,
    myFeedSuccess,
    myFeedTrigger,
    searchTrigger,
    searchSuccess,

} from './othersredux';
import Api from "./../services/ApiCaller";
import {blockSuccess, BlockTrigger, unBlockTrigger} from './userredux';
import {changeScreenSuccess} from './screentrackerredux'
import {
    getAccessToken, 
    getUserId
  } from './utilities'

export function* getRecentProfiles(api, {payload = {}}) {
    yield put(recentProfileTrigger(true))
    try {
      // console.log('Get Recent Profile')
      const token = yield getAccessToken();
      const result = yield call(Api.callServer, api.getRecentProfiles, {token}, true);
      yield put(recentProfileSuccess(result));
       console.log('Profiles: ', result)
    } catch (error) {
      console.log(error);
      // yield put(profileFail(error));
    } finally {
      yield put(recentProfileTrigger(false))
    }
  }

  export function* getProfileById(api, {payload = {}}) {
    yield put(singleProfileTrigger(true))
    try {
      // console.log('Get Other Profile By Id: ', payload.id)
      const token = yield getAccessToken();
      const {id} = payload
      const result = yield call(Api.callServer, api.getProfileById, {id, token}, true);
      yield put(otherProfileSuccess(result));
      // console.log('Other Profile: ', result)
    } catch (error) {
      console.log(error);
      // yield put(profileFail(error));
    } finally {
      yield put(singleProfileTrigger(false))
    }
  }

  export function* getMyFeed(api, {payload = {}}) {
    yield put(myFeedTrigger(true))
    try {
      // console.log('Get My Feed')
      const token = yield getAccessToken();
      const result = yield call(Api.callServer, api.getMyFeed, {token}, true);
      yield put(myFeedSuccess(result));
      // console.log('Feed: ', result)
    } catch (error) {

      // yield put(profileFail(error));
    } finally {
      yield put(myFeedTrigger(false))
    }
  }

  
export function* blockUser(api, {payload = {}}) {
  yield put(BlockTrigger(payload.id));
  try {
    const {id} = payload;
    const token = yield getAccessToken();
    const result = yield call(Api.callServer, api.blockUser, {id, token}, true);
    const result2 = yield call(
      Api.callServer,
      api.getMyBlockListId,
      {id, token},
      true,
    );

    yield put(blockSuccess(result2));
    // console.log('Feed: ', result)
  } catch (error) {
    console.log(error);
    // yield put(profileFail(error));
  } finally {
    yield put(singleProfileTrigger(false));
  }
}

export function* unblockUser(api, {payload = {}}) {
  yield put(unBlockTrigger(payload.id));
  try {
    const {id} = payload;
    console.log('Unblock');
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.unblockUser,
      {id, token},
      true,
    );
    const result2 = yield call(
      Api.callServer,
      api.getMyBlockListId,
      {id, token},
      true,
    );
    console.log('Unblock:', result2);
    yield put(blockSuccess(result2));

    // console.log('Feed: ', result)
  } catch (error) {
    console.log(error);
    // yield put(profileFail(error));
  } finally {
    yield put(singleProfileTrigger(false));
  }
}
export function* getMyBlockListId(api, {payload = {}}) {
  yield put(singleProfileTrigger(true));
  try {
    // console.log('Get My Feed')
    const id = yield getUserId();
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getMyBlockListId,
      {id, token},
      true,
    );
    yield put(blockSuccess(result));
     console.log('Feed: ', result)
  } catch (error) {
    console.log(error);
    // yield put(profileFail(error));
  } finally {
    yield put(singleProfileTrigger(false));
  }
}


export function* searchByUsername(api, {payload = {}}) {
  yield put(searchTrigger(true));
  try {
   
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.searchByUsername,
      {search:payload, token},
      true,
    )
    console.log('search saga',result)
    yield put(searchSuccess(result));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(searchTrigger(false));
  }
}
