import CustomButton from '@components/custom-button/CustomButton';
import React, { useState, useRef, useEffect } from 'react';
import InputField from '@components/input-field/InputField';
import cryptoRandomString from 'crypto-random-string';
import '@features/create-post/CreatePostStyles.css';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import '@themes/CommonApplicationStyles.css';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import StringCrypto from 'string-crypto';
import NavBar from '@navigation/NavBar';
import { connect } from 'react-redux';

//  Integration
import { URL } from '@utilities/Constants';
const axios = require('axios');

const sdk = require('dragonchain-sdk');
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
export default function VerifyOTP({ location }) {
  const [errortxt, seterrortxt] = useState('');
  const { code } = location.state || {};
  const [loader, setLoader] = useState(false);
  const [validated, setValidate] = useState(false);
  const [otp, setOtp] = useState('');

  const history = useHistory();

  const onSubmit = async (event) => {
    seterrortxt('');
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidate(true);
    if (form.checkValidity() === true) {
      setLoader(true);
      event.preventDefault();
      await axios
        .post(`${URL.API}/auth/verify-otp`, {
          mobile: code,
          otp,
        })
        .then(function (response) {
          history.push({ pathname: '/' });
        })
        .catch(function (error) {
          console.log(error);
          seterrortxt(
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
          );
        });
      setLoader(false);
    }
  };

  return (
    <div>
      {/* <NavBar location={useLocation()} /> */}
      <Container style={{ marginTop: '150px' }}>
        <Row className='justify-content-md-center'>
          <Col md={4}>
            <h3 className='mb-4 text-center'>Verify OTP</h3>

            <Form noValidate validated={validated} onSubmit={onSubmit}>
              <InputField
                type='otp'
                placeholder={'OTP'}
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
                error={otp.length == 0 ? 'otp cannot be empty' : 'not valid'}
                required
              />
              {
                <Col style={{ textAlign: 'center' }}>
                  {loader && <Spinner animation='border' variant='primary' />}
                </Col>
              }{' '}
              {errortxt != '' && (
                <h5 style={{ color: 'red', textAlign: 'center' }}>
                  {errortxt == 'OtpNotMatched'
                    ? 'Invalid OTP'
                    : errortxt == 'OtpExpired'
                    ? 'OTP Expired'
                    : errortxt == 'OtpNotGenerated'
                    ? 'OTP Not Generated'
                    : errortxt}
                </h5>
              )}
              <Row className='justify-content-md-center'>
                <Col lg={6} className='mt-2 '>
                  <CustomButton
                    type='Post'
                    filled={true}
                    primary={true}
                    disable={loader}
                    name='Submit'
                    // onClick={() => register()}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
// const mapStateToProps = ({ tracker = {}, post = {} }) => {
//   const createpostLoading = _.get(post, "createpostLoading", false);
//   const editpostloading = _.get(post, "editpostloading", false);
//   return { createpostLoading, editpostloading };
// };

// const mapDispatchToProps = (dispatch) => ({
//   createPost: (data) => dispatch({ type: "CreatePostCalled", payload: data }),
//   editPost: (data) => dispatch({ type: "EditPostCalled", payload: data }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Signup);
